<template>
  <div>
    <div class="top-gap-sm">
      <v-row align="center">
        <v-col>
          <TableActions
            type="correctionDocs"
            :number-of-selected="selected.length"
            :edit-condition="false"
            @delete-click="deleteDialog = true"
            @filter-change="(filter) => jobFilter = filter"
          />
        </v-col>
        <v-col> 
          <v-row class="d-flex justify-center align-center">
            <MaxWidthChip
              v-for="state in jobStates"
              :key="state"
              class="ma-4"
              :color="getColor(state)"
              :text-array="[state]"
              @click="handleStatusFilter(state)"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-card class="elevation-6 pa-0">
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="auto">
              <v-checkbox
                v-model="allSelected"
                style="margin-top: -16px"
                class="inline-middle"
                @change="() => {
                  paginatedJobs.forEach(item => {
                    item.selected = allSelected;
                  });
                }"
              />
            </v-col>
            <v-col cols="1">
              {{ $t('workflows.jobs.id') }}
            </v-col>
            <v-col cols="3">
              {{ $t('workflows.name') }}
            </v-col>
            <v-col>
              {{ $t('workflows.created_at') }}
            </v-col>
            <v-col>
              {{ $t('workflows.steps.name') }}
            </v-col>
            <v-col>
              {{ $t('workflows.jobs.is_test') }}
            </v-col>
            <v-col>
              {{ $t('workflows.steps.state') }}
            </v-col>
          </v-row>
        </v-container> 
        <div v-if="loading">
          <div v-if="paginatedJobs.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in paginatedJobs"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="paginatedJobs.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i> {{ $t('workflows.no_results') }} </i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedJobs"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col 
              class="h-100"
              cols="auto"
            >
              <v-checkbox
                v-model="item.selected"
                style="margin-top: -15px"
              />
            </v-col>
            <v-col cols="1">
              {{ item.id }}
            </v-col>
            <v-col cols="3">
              <ItemName
                :key="item.id"
                style="width: 90%"
                :item="item"
                :editing-allowed="false"
                :clickable="true"
                @name-click="getHistory(item.id)"
              />
            </v-col>
            <v-col>
              {{ formatDate(item.updated_at) }}
            </v-col>
            <v-col>
              {{ item.step_name }}
            </v-col>
            <v-col>
              {{ item.is_test ? 'True' : 'False' }}
            </v-col>
            <v-col>
              <MaxWidthChip
                style="margin-top: -5px"
                :color="getColor(item.state)"
                :text-array="[item.state]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <TableFooter
      v-if="totalJobs > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalJobs / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('workflows.delete')"
      :message="$t('workflows.delete_confirmation')"
      @confirm="deleteJobs"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import format_mixin from '@/mixins/format.js';
import TableActions from '@/components/common/elements/Tables/TableActions';
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableFooter from '@/components/common/elements/Tables/TableFooter';



export default {
  name: 'JobsTable',

  mixins: [format_mixin],

  components: { TableActions, ItemName, MaxWidthChip, DeleteDialog, TableFooter },

  data(){
    return {
      allSelected: false,
      deleteDialog: false,
      itemsPerPage: 20,
      totalJobs: 0,
      currentPage: 1,
      paginatedWorkflows: [],
      paginatedJobs: [],
      loading: false,
      id: -1,
      steps: [],
      transitions: [],
      jobHistory: [],
      jobFilter: '',
      jobStates: ['started', 'error', 'done'],
      statusFilter: ''
    };
  },
  
  computed: {
    selected: {
      get(){
        if (this.paginatedJobs.length > 0){
          return this.paginatedJobs.filter(item => item.selected);
        }
        return [];
      }
    },

    trimmedFilter(){
      return this.jobFilter.trim().toLowerCase();
    },
  },
  
  watch: {
    trimmedFilter: _.debounce(
      function() {
        this.getJobs();
      }, 300
    ),
    itemsPerPage(){
      this.getJobs();
    }
  },

  async mounted() {
    this.loading = true;
    await this.getJobs()
  },

  methods: {
    resetCurrentPage(){
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedJobs.forEach(item => {
        item.selected = false;
      });
    },

    async getJobs(offset = 0, limit = this.itemsPerPage){
        try {
            const options = {
              workflowFilter: this.jobFilter,
              stepFilter: '', // To be added later
              statusFilter: this.statusFilter,
              uuid: '', // To be added later
              limit,
              offset, 
              sortDesc: true
            }
            const response = await WorkflowAPI.getJobs(null, options);
            const { data, headers } = response;
            this.paginatedJobs = data.map(job => {
                return { ...job, name: job.workflow_name };
            });
            
            this.totalJobs = parseInt(headers['x-total-count'], 10);
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    },

    async deleteJobs(){
      await Promise.all(this.selected.map(async job => {
        if (job.state === 'done'){
          try{
            await WorkflowAPI.deleteJob(job.id);
          } catch (error){
            console.log(error);
          }
        }
      }));
      this.finishDeletion();
    },
    
    async finishDeletion(){
      const { currentPage, itemsPerPage} = this;
      await this.getJobs();
      const lastPage = Math.max(1, Math.ceil(this.totalJobs / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('workflows.delete_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    getHistory(jobId){
      this.$router.push(
        {
          name: 'JobHistory',
          params: {
            id: jobId,
        }
      });
    },
    getColor(state){ 
      // To improve this function in the future
      if (state === 'done'){
        return "#502BFF";
      } else if (state === 'error'){
        return "#FF6F91";
      } else {
        return "#FFA07A";
      }
    },
    handleStatusFilter(state){
      this.statusFilter = state;
      this.getJobs();
    }
  },
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  &:hover {
    border: 2px solid #502BFF !important;
  }
}
</style>