<template>
  <div
    v-if="loaded"
    class="corrections-view page-padding py-7"
  >
    <BreadcrumbComponent />
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      :loading="!loaded && totalBundles === 0"
    />
    <div
      v-if="$store.getters.loggedInUser.role !== 'orgadmin' && activeTab === 'doctypes'"
      style="max-width: 740px"
    >
      <h2 class="text-h2 top-gap-lg">
        {{ $t('docTypes.start_corrections') }}
      </h2>
    </div>
    <div v-if="activeTab === 'doctypes'">
      <div class="table-tools d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-text-field
            v-model="typeFilter"
            class="filter-items"
            style="width: 280px"
            variant="outlined"
            color="primary"
            density="compact"
            :placeholder="$t('docTypes.type_name')"
            @keydown.enter="handleEnter"
          />
        </div>
      </div>
      <v-card class="elevation-6 pa-0 top-gap-sm">
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col>
              <SortButton
                v-model="sortDesc"
                style="margin-top: -5px"
              />
              {{ $t('corrections.doc_types') }}
            </v-col>
            <v-col style="text-align: center">
              {{ $t('docTypes.docs_to_correct') }}
            </v-col>
          </v-row>
        </v-container>
        <div v-if="!loaded">
          <div v-if="correctionTypes.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in correctionTypes"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="correctionTypes.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in correctionTypes"
            :key="item.id"
            class="table-row fade-in table-row-height clickable"
            style="color: rgb(var(--v-theme-primary))"
            @click="goToDocType(item.id)"
          >
            <v-col class="left-gap-lg">
              {{ item.name }}
            </v-col>
            <v-col style="text-align: center">
              {{ item.nb_prod_files_to_validate }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <TableFooter
        v-if="totalTypes > 0"
        v-model="itemsPerPage"
        :current-page="currentPage"
        :total-pages="Math.ceil(totalTypes / itemsPerPage)"
        @change-page="page => currentPage = page"
        @reset-current-page="currentPage = 1"
      />
    </div>
    <BundlesTable
      v-else-if="activeTab === 'bundles'"
      :total-bundles="totalBundles"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { http } from '@/plugins/axios';
import { ClassifyBundlesAPI } from '@/API/classify/ClassifyBundlesAPI';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import BundlesTable from '@/components/extract/views/Corrections/BundlesTable';
import SortButton from '@/components/common/elements/Tables/SortButton';
import PageTabs from '@/components/common/elements/General/PageTabs';
import TableFooter from '@/components/common/elements/Tables/TableFooter';

export default {
  name: 'CorrectionsView',

  components: {
    BreadcrumbComponent,
    BundlesTable,
    PageTabs,
    SortButton,
    TableFooter,
  },

  data() {
    return ({
      sortDesc: true,
      typeFilter: '',
      totalTypes: 0,
      totalBundles: 0,
      activeTab: 'doctypes',
      loaded: false,
      classifyRunning: this.$store.getters.serviceStatus.classify.running,
    });
  },

  computed: {
    correctionTypes: {
      get() {
        return this.$store.getters.correctionTypes;
      },
      set(types) {
        this.$store.commit('setCorrectionTypes', types);
      },
    },

    itemsPerPage: {
      get: function() {
        return this.$store.getters.itemsPerPage['corrections'];
      },
      set(itemsPerPage) {
        this.$store.commit('setItemsPerPage', {
          key: 'corrections',
          itemsPerPage,
        });
      }
    },

    currentPage: {
      get: function() {
        return this.$store.getters.currentPage['corrections'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', {
          key: 'corrections',
          currentPage,
        });
      }
    },

    trimmedFilter() {
      return this.typeFilter.trim().toLowerCase();
    },

    tabItems() {
      const tabs = [
        {
          title: this.$t('breadcrumb.docTypes'),
          bigNumber: this.totalTypes,
          name: 'doctypes',
        },
      ];
      if (this.classifyRunning) {
        tabs.push({
          title: this.$t('workflows.split.bundles'),
          bigNumber: this.totalBundles,
          name: 'bundles',
        });
      }
      return tabs;
    },
  },

  watch: {
    sortDesc() {
      this.getCorrectionTypes();
    },

    itemsPerPage: async function() {
      if (this.currentPage == 1) {
        await this.getCorrectionTypes();
      }
    },

    currentPage: async function() {
      await this.getCorrectionTypes();
    },

    trimmedFilter: _.debounce(
      async function() {
        this.currentPage = 1;
        await this.getCorrectionTypes()
      }, 300
    ),
  },

  async created() {
    if (!this.classifyRunning) {
      this.$router.replace({ query: { activeTab: 'doctypes' } });
    }
  },

  async mounted() {
    this.$store.commit('setBreadcrumb',
    [
      { title: this.$t('breadcrumb.home'), href: {name: 'Extract'} },
      { title: this.$t('menu.production') },
      { title: this.$t('menu.corrections.title') },
    ]
    );
    this.currentPage = 1;
    await this.getCorrectionTypes();
    if (this.classifyRunning) {
      this.activeTab = this.$route.query.activeTab || 'doctypes';
      const response = await ClassifyBundlesAPI.get();
      this.totalBundles = parseInt(response.headers['x-total-count'], 10);
    }
    this.loaded = true;
  },

  methods: {
    goToDocType(id) {
      this.$router.push({
        name: 'CorrectionDocs',
        params: { id },
      });
    },

    handleEnter() {
      if (this.correctionTypes.length > 0) {
        this.goToDocType(this.correctionTypes[0].id);
      }
    },

    async getCorrectionTypes() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const response = await http.get(
          'system_2/verify/',
          { params: { 
              limit: this.itemsPerPage,
              name_filter: this.trimmedFilter,
              offset,
              sort_desc: this.sortDesc,
            }
          }
        );
        this.correctionTypes = response.data;
        this.totalTypes = parseInt(response.headers['x-total-count']);
        this.$store.commit('setLoadingScreen', false);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.corrections-view {
  height: fit-content;

  .table-tools {
    height: 50px;
    margin-top: 28px;
  }
}
</style>
