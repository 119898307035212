<template>
  <div class="folder-node">
    <div
      v-if="show"
      class="d-flex"
    >
      <v-checkbox
        v-model="folder.selected"
        class="right-gap-sm"
        style="white-space: nowrap !important;"
        color="primary"
        :style="{ opacity: folder.nb_processed_files === 0 ? 0.5 : 1 }"
        :label="`${folder.name} (${folder.nb_processed_files})`"
        :disabled="folder.nb_processed_files === 0"
        @update:model-value="handleClick"
      />
      <ChevronButton
        v-if="folder.nb_children_folders > 0"
        v-model="folder.open"
        style="margin-top: -3px"
        :size="14"
        @input="handleChevronClick"
      />
    </div>
    <div
      v-if="show && folder.folders.length > 0"
      class="top-gap-sm"
    >
      <FolderNode
        v-for="subfolder in folder.folders"
        :key="subfolder.id"
        :ref="`folderNode-${subfolder.id}`"
        class="bottom-gap-sm left-gap"
        :folder="subfolder"
        :show="folder.open"
        @folder-filter-changed="$emit('folderFilterChanged')"
      />
    </div>
  </div>
</template>

<script>
import { FolderAPI } from '@/API/search/FolderAPI';

import FolderNode from "@/components/search/elements/Home/FolderNode";
import ChevronButton from "@/components/common/elements/General/ChevronButton";

export default {
  name: 'FolderNode',

  components: {
    FolderNode,
    ChevronButton,
  },

  computed: {
    startedSearch() {
      return this.$store.getters.startedSearch;
    }
  },

  methods: {
    async handleChevronClick(open) {
      this.open = open;
      if (this.folder.folders.length === 0) {
        await this.getFolders(this.folder, open);
      }
    },

    handleClick(selected) {
      if (this.startedSearch) {
        this.$emit('folderFilterChanged');
      }
      this.updateFolders(selected);
    },

    updateFolders(selected) {
      this.folder.folders = this.folder.folders.map(f => {
        f.selected = selected;
        return f;
      });
      this.folder.folders.forEach(f => {
        this.$refs[`folderNode-${f.id}`][0].updateFolders(selected);
      });
      this.manageFolderFilter(selected, this.folder.id);
    },

    manageFolderFilter(selected, id) {
      const folderIds = this.$store.getters.searchFilters.folderIds;
      if (selected && !folderIds.includes(id)) {
        folderIds.push(id);
      } else if (!selected && folderIds.includes(id)) {
        folderIds.splice(folderIds.indexOf(id), 1);
      }
    },

    async getFolders(folder, open) {
      if (open) {
        try {
          const folders = await FolderAPI.getFolders(this.folder.id);
          this.folder.folders = folders.filter(f => f.parent_folder_id).map(f => {
            f.open = false;
            f.folders = [];
            f.selected = this.folder.selected;
            return f;
          });
          this.folder.folders.forEach(f => {
            this.manageFolderFilter(f.selected, f.id);
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        folder.folders = [];
      }
    },
  },
  
  props: {
    folder: {
      type: Object,
      required: true,
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['folderFilterChanged'],
}
</script>
