<template>
  <div class="bottom-nav my-8">
    <div style="float: right">
      <v-pagination
        v-model="page"
        class="inline-middle right-gap"
        density="comfortable"
        active-color="primary"
        color="white"
        :length="totalPages"
        :total-visible="7"
      />
      <div class="inline-middle mr-6">
        {{ $t('datatable.footer.perPage') }}
      </div>
      <v-select
        v-model="itemsPerPage"
        style="width: 100px"
        class="inline-middle mt-0"
        item-title="text"
        item-value="value"
        density="compact"
        variant="outlined"
        color="primary"
        :items="itemsPerPageOptions"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableFooter',

  data() {
    return {
      page: 1,
      itemsPerPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '100', value: 100 },
      ],
    };
  },

  computed: {
    itemsPerPage: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  watch: {
    itemsPerPage(_new, old) {
      if (_new !== old) {
        this.$emit('resetCurrentPage');
      }
    },

    page(page) {
      this.$emit('changePage', page);
    },

    currentPage(page) {
      this.page = page;
    },
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue', 'resetCurrentPage', 'changePage'],
}
</script>

<style lang="scss" scoped>
.bottom-nav {
  font-size: 12px;
  width: 100%;
}
</style>
