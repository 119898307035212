<template>
  <div>
    <TableActions
      type="workflows"
      :number-of-selected="selected.length"
      @edit-click="editingWorkflow = selected[0].id"
      @delete-click="deleteDialog = true"
      @create-click="handleCreateButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    />
    <div class="top-gap-sm">
      <v-card class="elevation-6 pa-0">
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="auto">
              <SortButton v-model="sortDesc" />
              <v-checkbox
                v-model="allSelected"
                style="margin-top: -16px"
                class="inline-middle"
                :disabled="readOnly"
                :style="{ opacity: readOnly ? 0.5 : 1 }"
                @change="toggleSelectAll"
              />
            </v-col>
            <v-col cols="3">
              {{ $t('workflows.name') }}
            </v-col>
            <v-col cols="1">
              {{ $t('workflows.status') }}
            </v-col>
            <v-col>
              {{ $t('workflows.created_at') }}
            </v-col>
            <v-col>
              {{ $t('workflows.updated_at') }}
            </v-col>
            <v-col />
          </v-row>
        </v-container>
        <div v-if="loading">
          <div v-if="paginatedWorkflows.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in paginatedWorkflows"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="paginatedWorkflows.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedWorkflows"
            :key="item.uuid"
            class="table-row fade-in table-row-height"
          >
            <v-col
              class="h-100"
              cols="auto"
            >
              <v-checkbox
                v-model="item.selected"
                style="margin-top: -15px"
                class="left-gap"
                :style="{ opacity: isEditable(item) ? 1 : 0.5 }"
                :disabled="['active', 'published'].includes(item.status)"
              />
            </v-col>
            <v-col
              cols="3"
              class="h-100"
            >
              <ItemName
                :ref="`name_${item.id}`"
                :key="item.id"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingWorkflow === item.id"
                @save-file-name="saveName"
                @name-click="configureWorkflow({ id: item.id }) "
              />
            </v-col>
            <v-col
              cols="1"
              class="h-100"
            >
              <MaxWidthChip
                class="workflow-status mb-0"
                style="margin-top: -5px"
                color="#502BFF"
                :text-array="[$t(`workflows.${item.status}`)]"
              />
            </v-col>
            <v-col class="h-100">
              {{ formatDate(item.created_at) }}
            </v-col>
            <v-col class="h-100">
              {{ item.updated_at && formatDate(item.updated_at) || '-' }}
            </v-col>
            <v-col class="h-100 text-center">
              <v-btn
                v-if="item.draft_id || item.status === 'draft'"
                style="margin-top: -4px;"
                color="primary"
                size="small"
                @click="configureWorkflow(item.status === 'draft' && { id: item.id } || { id: item.draft_id })"
                rounded
              >
                <v-icon
                  color="white"
                  size="12"
                  start
                >
                  fas fa-pen
                </v-icon>
                {{ $t('workflows.edit_draft') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>          
    </div>
    <TableFooter
      v-if="totalWorkflows > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalWorkflows / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('workflows.delete')"
      :message="$t('workflows.delete_confirmation')"
      @confirm="deleteWorkflows"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';

import format_mixin from '@/mixins/format';

export default {
  name: 'WorkflowsView',

  mixins: [format_mixin],

  components: {
    TableActions,
    DeleteDialog,
    ItemName,
    TableFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    return ({
      allSelected: false,
      paginatedWorkflows: [],
      deleteDialog: false,
      totalWorkflows: 0,
      itemsPerPage: 20,
      currentPage: 1,
      trimmedFilter: '',
      loading: false,
      editingWorkflow: -1,
      sortDesc: false,
    });
  },

  computed: {
    selected: {
      get() {
        if (this.paginatedWorkflows.length > 0) {
          return this.paginatedWorkflows.filter(wf => wf.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    readOnly() {
      return this.paginatedWorkflows.every(version => ['published', 'active'].includes(version.status));
    },
  },

  watch: {
    sortDesc() {
      this.getWorkflows(true);
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getWorkflows(true);
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedWorkflows.forEach(item => {
        item.selected = this.allSelected;
      });
      this.getWorkflows(true, (page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getWorkflows(true);
      }, 300
    ),
  },

  async mounted() {
    this.loading = true;
    await this.getWorkflows();
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedWorkflows.forEach(item => {
        item.selected = false;
      });
    },

    async updateWorkflow(workflow) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await WorkflowAPI.updateWorkflow(
          workflow.id,
          { active: workflow.active }
        );
        this.$store.commit('setLoadingScreen', false);
        await this.$store.commit(
          'setSuccessMessage', this.$t('workflows.updated')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await WorkflowAPI.updateWorkflow(id, { name: newName });
          const workflow = this.paginatedWorkflows.find(wf => wf.id === id);
          workflow.name = newName;
          workflow.selected = false;
          await this.$store.commit(
            'setSuccessMessage', this.$t('workflows.renamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        } finally {
          this.editingWorkflow = -1;
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    async getWorkflows(force = false, offset = 0, limit = this.itemsPerPage) {
      if (force) {
        this.loading = true;
      }
      try {
        const response = await http.get(
          `workflows/api/v1/workflows/by-uuid`,
          {
            params: {
              limit,
              offset,
              name_filter: this.trimmedFilter || '',
              sort_desc: this.sortDesc,
            },
          }
        );
        this.paginatedWorkflows = response.data;
        this.totalWorkflows = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    toggleSelectAll() {
      this.paginatedWorkflows.forEach(item => {
        if (!['active', 'published'].includes(item.status)) {
          item.selected = this.allSelected;
        }
      });
    },

    handleEnter() {
      if (this.paginatedWorkflows.length > 0) {
        this.configureWorkflow(
          { id: this.paginatedWorkflows[0].id }
        );
      }
    },

    configureWorkflow(params) {
      this.$router.push({
        name: 'ConfigureWorkflow',
        params,
      });
    },

    handleCreateButton() {
      this.$router.push({
        name: 'ConfigureWorkflow',
        params: { id: 'new' },
      });
    },

    async deleteWorkflows() {
      await Promise.all(this.selected.map(async wf => {
        try {
          return await WorkflowAPI.deleteWorkflow(wf.id);
        } catch (error) {
          if (error.response.status === 412) {
            return
          } else {
            this.$store.commit('setSnackbar', true);
            console.log(error);
            return
          }
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getWorkflows(true);
      const lastPage = Math.max(1, Math.ceil(this.totalWorkflows / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('workflows.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    isEditable(wf) {
      return wf && ![ 'active', 'published' ].includes(wf.status);
    }
  },
}
</script>
<style lang="scss" scoped>
  .workflow-status {
    line-height: 1.75 !important;
  }

  .show-more-versions-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
