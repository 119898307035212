<template>
  <div class="create-generative align-center top-gap">
    <div>
      <div class="label">
        {{ $t('forms.name') }}
      </div>
      <v-text-field
        ref="nameField"
        v-model="DPName"
        style="width: 280px; margin-top: 5px;"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('dataPoints.type_extractor_name')"
        @keydown.enter="addNew"
      />
      <div
        v-if="tooLong"
        class="name-error bottom-gap"
      >
        {{ $t('dataPoints.long_name_msg') }}
      </div>
    </div>
    <div style="margin-top: -7px">
      <div class="label">
        {{ $t('models.generative_model') }}
      </div>
      <ModelSelect
        style="margin-top: 5px"
        :models="generativeModels"
        :selected="selectedModel"
        @selected-changed="(id) => {selectedModel = id}"
        @get-more="(nameFilter, reset) => getGenerativeModels(generativeModels.length, 20, nameFilter, reset)"
      />
    </div>
    <div
      v-if="modelInfo"
      class="info-box top-gap-sm"
    >
      <div>
        <strong>{{ $t('models.version') }}:</strong>
        {{ modelInfo.version }}
      </div>
      <strong>{{ $t('models.prompt') }}:</strong>
      {{ modelInfo.prompt }}
    </div>
    <v-btn
      class="top-gap"
      color="primary"
      style="box-shadow: none"
      :disabled="incomplete"
      @click="addNew"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import ModelSelect from "@/components/extract/elements/Models/ModelSelect";

export default {
  name: 'CreateGenerativeExtractor',

  components: {
    ModelSelect,
  },

  data() {
    return ({
      DPName: '',
      selectedModel: 0,
      autoDetectClusters: true,
      nbClusters: 0,
      extractorModel: [],
      generativeModels: [],
    });
  },

  computed: {
    incomplete() {
      return this.DPName.trim().length < 1 || this.tooLong || this.selectedModel === 0;
    },

    tooLong() {
      return this.DPName.trim().length > 64;
    },

    modelInfo() {
      if (this.selectedModel) {
        return this.generativeModels.find(m => m.id === this.selectedModel);
      }
      return null;
    },
  },

  mounted() {
    this.getGenerativeModels();
    setTimeout(() => {
      const nameField = this.$refs[`nameField`];
      if (nameField) {
        nameField.focus();
      }
    }, 10);
  },

  methods: {
    async getGenerativeModels(
      offset = 0,
      limit = 20,
      name_filter = null,
      reset = false,
    ) {
      this.$store.commit('setLoadingScreen', true);
      if (reset) {
        offset = 0;
      }
      try {
        const response = await http.get(
          'model/generative/',
          {
            params: {
              limit,
              offset,
              name_filter,
            },
          }
        );
        if (offset > 0) {
          this.generativeModels = [...this.generativeModels, ...response.data];
        } else {
          this.generativeModels = response.data;
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async addNew() {
      if (!this.incomplete) {
        try {
          this.$store.commit('setLoadingScreen', true);
          const newExtractor = {
            name: this.DPName.trim(),
            doctype_id: this.$route.params.id,
            generative_model_id: this.selectedModel,
          };
          const response = await http.post(
            `system_2/generative_extractor/`,
            newExtractor,
          );
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.generative_created'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
          newExtractor.id = response.data;
          this.$emit('create', newExtractor);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },
  },

  emits: ['create'],
}
</script>

<style scoped lang="scss">
.create-generative {
  .form-label {
    font-size: 0.8rem;
  }

  .name-error {
    color: rgb(var(--v-theme-error));
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 13px 24px;
    width: calc(100% - 5px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;
    position: relative;
    top: -14px !important;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

}
</style>
