<template>
  <div
    class="custom-node"
    :class="{
      selected,
      'state-node': type === 'state',
      'initial-node': startNode,
      'final-node': endNode,
    }"
  >
    <Handle
      v-if="['left', 'both'].includes(handles)"
      type="target"
      class="node-handle"
      :position="direction === 'LR' ? left : top"
    />
    <div class="text-center">
      <strong>{{ label }}</strong>
    </div>
    <div
      v-if="name !== ''"
      class="text-center"
      style="font-size: 0.8rem"
    >
      {{ name }}
    </div>
    <Handle
      v-if="['right', 'both'].includes(handles)"
      type="source"
      class="node-handle"
      :position="direction === 'LR' ? right : bottom"
    />
  </div>
</template>

<script>
import { Position, Handle } from '@vue-flow/core'

export default {
  name: 'CustomNode',

  components: {
    Handle,
  },

  computed: {
    left() {
      return Position.Left;
    },

    right() {
      return Position.Right;
    },

    top() {
      return Position.Top;
    },

    bottom() {
      return Position.Bottom;
    },

    startNode() {
      return this.type === 'state' && this.isInitial;
    },

    endNode() {
      return this.type === 'state' && this.isFinal;
    },
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    selected: {
      type: Boolean,
      default: false,
    },

    handles: {
      type: String,
      default: 'both',
    },

    direction: {
      type: String,
      default: 'LR',
    },

    isInitial: {
      type: Boolean,
      default: false,
    },

    isFinal: {
      type: Boolean,
      default: false,
    },
    
    type: {
      type: String,
      default: 'action',
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-node {
  border: 2px rgb(var(--v-theme-grey)) solid;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  border-radius: 4px;
  font-size: 0.9rem;

  &.state-node {
    border-radius: 50%;
    padding: 15px;
    padding-bottom: 18px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: double;
    border-width: 4px;

    &.initial-node {
      border-style: solid;
      border-width: 2px;
    }

    &.final-node {
      border-style: solid;
      border-width: 3px;
    }
  }
}

.selected {
  border: 2px rgb(var(--v-theme-primary)) solid;
}

.node-handle {
  background-color: rgb(var(--v-theme-primary));
  width: 8px;
  height: 8px;
}
</style>
