/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/';

export class DatasetAPI {
  static async get(
    offset,
    limit,
    nameFilter = '',
    status = null,
    sortDesc = true,
    noAuth = false,
    external = false,
    token = null
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          name_filter: nameFilter,
          sort_desc: sortDesc,
          status,
          noAuth,
          external,
          token,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
