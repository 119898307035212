<template>
  <v-card class="data-points-table elevation-6 pa-0 top-gap-sm">
    <v-container
      class="pa-0 table-row-height"
      fluid
    >
      <v-row class="table-row table-row__header table-row-height">
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="inline-middle"
            style="margin-top: -16px"
          />
        </v-col>
        <v-col cols="4">
          {{ $t('datatable.header.dataPointName') }}
        </v-col>
        <v-col v-if="user.role === 'orgadmin'">
          {{ $t('datatable.header.value_type') }}
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          style="text-align: center"
          cols="2"
        >
          {{ $t('datatable.header.precision') }}
        </v-col>
        <v-col v-if="user.role === 'orgadmin'">
          {{ $t('dataPoints.model') }}
        </v-col>
        <v-col v-if="user.role === 'sysadmin'">
          {{ $t('dataPoints.label') }}
        </v-col>
      </v-row>
    </v-container>
    <div v-if="loading">
      <div v-if="pages[currentPage] && pages[currentPage].length === 0">
        <div
          v-for="item in 10"
          :key="item"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-for="item in pages[currentPage]"
        :key="item.id"
        class="table-row-height"
      >
        <v-skeleton-loader type="table-row" />
      </div>
    </div>
    <div
      v-else-if="!pages[currentPage] || pages[currentPage].length === 0"
      class="table-row fade-in table-row-height"
      style="text-align: center; padding-top: 15px;"
    >
      <i>{{ $t('docTypes.no_results') }}</i>
    </div>
    <v-container
      v-else
      class="pa-0"
      fluid
    >
      <v-row
        v-for="item in pages[currentPage]"
        :key="item.id"
        class="table-row fade-in table-row-height"
        style="border-bottom: 1px solid #eee !important"
      >
        <v-col cols="auto">
          <v-checkbox
            v-model="item.selected"
            class="left-gap"
            style="margin-top: -15px"
            @change="$emit('setItems', [...allItems])"
          />
        </v-col>
        <v-col cols="4">
          <ItemName
            :key="item.id"
            :ref="`name_${item.id}`"
            style="z-index: 202;"
            :item="item"
            :editing-allowed="!!(item.selected)"
            :clickable="!disabled"
            @save-file-name="(id, newName) => {item.name = newName; saveDPName(id, newName);}"
            @name-click="handleEditButton($route.params.id, item.id)"
          />
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          :class="{
            'clickable': !disabled,
            'primary--text': !disabled,
          }"
          @click="handleEditButton($route.params.id, item.id)"
        >
          {{ $t(`datatable.value_type.${item.value_type}`) }}
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          style="text-align: center"
          cols="2"
        >
          <span v-if="item.nb_values > 0">
            {{ (item.nb_valid_values / item.nb_values * 100).toFixed(0) }}%
            ({{ item.nb_valid_values }}/{{ item.nb_values }})
          </span>
          <span v-else>
            —
          </span>
        </v-col>
        <v-col v-if="user.role === 'orgadmin'">
          {{ item.model_name }}
        </v-col>
        <v-col v-if="user.role === 'sysadmin'">
          {{ getEntityInfo(item) }}
        </v-col>
      </v-row>
    </v-container>
    <TableFooter
      v-if="filteredItems.length > 0"
      v-model="itemsPerPage"
      style="margin-top: 10px !important; margin-right: 20px !important; margin-bottom: 5px !important;"
      :current-page="currentPage"
      :total-pages="Object.keys(pages).length"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
  </v-card>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';


export default {
  name: 'DataPointsTable',

  components: {
    ItemName,
    TableFooter,
    SortButton,
  },

  data() {
    return {
      extractionMethodMap: {
        'custom': this.$t('dataPoints.model'),
        'classic': this.$t('dataPoints.rules'),
      },
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    sortDesc: {
      get() {
        return this.$store.getters.dpSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setDpSortDesc', sortDesc);
      },
    },

    itemsPerPage: {
      get() {
        return this.$store.getters.itemsPerPage['dataPoints'];
      },
      set(itemsPerPage) {
        this.$store.commit('setItemsPerPage', { key: 'dataPoints', itemsPerPage })
      },
    },

    currentPage: {
      get() {
        return this.$store.getters.currentPage['dataPoints'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', { key: 'dataPoints', currentPage });
      },
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.allItems.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.$emit('setItems', [...selected]);
      }
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.$emit('getItems', this.sortDesc);
    }
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    handleEditButton(id, itemId) {
      if (this.user.role === 'orgadmin' && !this.disabled) {
        {
          this.$router.push({
            name: 'DatapointConfig',
            params: {
              id, itemId,
            },
          });
        }
      }
    },

    async saveDPName(id, name) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.put(`system_2/data_point/${id}`, { name });
        this.$store.commit('setLoadingScreen', false);
        this.allSelected = false;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('saveName');
      } catch (error) {
        this.docLoading = false;
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
        return
      }
    },

    getEntityInfo(dp) {
      const eps = dp.extraction_paths;
      if (eps.length === 1) {
        const entity = eps[0].what.custom_entity;
        if (entity) {
          if (this.$te(`entities.${entity[0][1]}`)) {
            return this.$t(`entities.${entity[0][1]}`);
          }
          return entity[0][1];
        }
      }
      return '';
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName'],
}
</script>
