/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/health/';

export class SearchHealthAPI {
  static async get() {
    return http.get(BASE_PATH, { ignoreGlobalCatch: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
