<template>
  <div class="datasets-table">
    <BreadcrumbComponent />
    <TableActions
      type="datasets"
      :number-of-selected="selected.length"
      :delete-condition="selected.every(item => (!item.user_id || item.user_id === user.id))"
      @edit-click="handleEditName"
      @delete-click="handleDeleteButton"
      @create-click="handleCreateButton"
      @download-click="handleDownloadButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
      @import-click="selectDataset"
    />
    <v-card class="elevation-6 pa-0 top-gap-sm">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col cols="6">
            {{ $t('models.dataset') }}
          </v-col>
          <v-col cols="2">
            {{ $t('models.docs') }}
          </v-col>
          <v-col cols="3">
            {{ $t("models.progress") }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="paginatedDatasets.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginatedDatasets"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedDatasets.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in paginatedDatasets"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="handleSelect"
            />
          </v-col>
          <v-col cols="6">
            <v-icon
              v-if="item.status === 'error'"
              class="inline-middle mr-2"
              color="primary"
              size="16"
            >
              fas fa-exclamation-triangle
            </v-icon>
            <ItemName
              :key="item.id"
              class="inline-top"
              :item="item"
              :clickable="isActive(item)"
              :editing-allowed="isActive(item) || !!(item.selected)"
              :editing="editingDataset === item.id"
              @save-file-name="(id, newName) => saveName(id, newName)"
              @name-click="goToDataset(item)"
            />
          </v-col>
          <v-col
            cols="2"
            :class="{
              clickable: isActive(item),
              'text--primary': isActive(item),
            }"
          >
            <div
              v-if="item.nb_files === 0"
              @click="selectFile(item)"
            >
              {{ $t('docTypes.add') }}
            </div>
            <div
              v-else
              @click="goToDataset(item)"
            >
              {{ item.nb_files }}
            </div>
          </v-col>
          <v-col
            cols="3"
            :class="{
              clickable: isActive(item),
              'accent--text': isActive(item),
            }"
          >
            <div
              v-if="item.nb_annotated === 0"
              @click="goToAnnotation(item)"
            >
              {{ $t('models.start_annotating') }}
            </div>
            <div
              v-else
              @click="goToAnnotation(item)"
            >
              {{ Math.round(100 * item.nb_annotated / item.nb_files) }}%
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <TableFooter
      v-if="totalDatasets > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalDatasets / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <FileInput
      ref="uploader"
      @change="uploadFiles"
    />
    <DatasetInput
      ref="datasetUploader"
      v-model="showUpload"
      @change="uploadDataset"
      @close="showUpload = false"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('tables.move_to_bin_title', itemsTranslation)"
      :message="$t('tables.move_to_bin_confirm', itemsTranslation)"
      @confirm="deleteDataset"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { EntryAPI } from '@/API/extract/EntryAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { http } from '@/plugins/axios';
import { callbackError } from '@/utils/classes/Utils'
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import SortButton from '@/components/common/elements/Tables/SortButton';
import FileInput from '@/components/common/elements/Forms/FileInput';
import DatasetInput from '@/components/common/elements/Forms/DatasetInput';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import file_mixin from '@/mixins/file.js';
import ui_mixin from '@/mixins/ui.js';

export default {
  name: 'DatasetsTable',

  mixins: [file_mixin, ui_mixin],

  components: {
    ItemName,
    DeleteDialog,
    TableActions,
    TableFooter,
    FileInput,
    DatasetInput,
    SortButton,
    BreadcrumbComponent,
  },

  data() {
    return ({
      sortDesc: true,
      trimmedFilter: '',
      uploadModel: null,
      selectedFile: null,
      editOn: false,
      editingDataset: -1,
      deleteDialog: false,
      itemsPerPage: 20,
      currentPage: 1,
      totalDatasets: 0,
      paginatedDatasets: [],
      allSelected: false,
      loading: false,
      forceCompute: Math.random(),
      showUpload: false,
      checkUploadedDataset: null,
    })
  },

  computed: {
    pendingUpload() {
      return this.paginatedDatasets.some(ds => ds.status === 'uploading');
    },

    itemsTranslation() {
      return {items: this.selected.length === 1 ? this.$t('tables.dataset') : this.$t('tables.datasets')}
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.paginatedDatasets.length > 0) {
          return this.paginatedDatasets.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  watch: {
    sortDesc() {
      this.getDatasets();
    },

    totalDatasets(total) {
      if (this.trimmedFilter === '' && !total) {
        this.$emit('create');
      } else if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDatasets();
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedDatasets.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getDatasets((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDatasets();
      }, 300
    ),
  },

  created() {
    this.setBreadcrumb();
    this.getDatasets();
  },

  unmounted() {
    clearTimeout(this.checkUploadedDataset);
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Extract'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.datasets') },
        ]
      );
    },

    handleSelect() {
      this.allSelected = this.paginatedDatasets.every(d => d.selected);
      this.forceCompute = Math.random();
    },

    isActive(dataset) {
      return !['uploading', 'error'].includes(dataset.status);
    },

    async checkUploaded() {
      await this.getDatasets();
      if (this.pendingUpload) {
        this.checkUploadedDataset = setTimeout(async () => {
          await this.checkUploaded();
        }, 3000);
      } else {
        clearTimeout(this.checkUploadedDataset);
      }
    },

    toggleSelectAll() {
      this.paginatedDatasets.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },

    async getDatasets(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const response = await DatasetAPI.get(
          offset, limit, this.trimmedFilter || '', null, this.sortDesc,
        );
        this.paginatedDatasets = response.data;
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const datasets = this.paginatedDatasets.map(ds => {
        ds.selected = false;
        return ds;
      });
      this.paginatedDatasets = [...datasets];
    },

    handleEnter() {
      if (this.paginatedDatasets.length > 0) {
        this.goToDataset(this.paginatedDatasets[0])
      }
    },

    async deleteDataset() {
      await Promise.all(this.selected.map(async dataset => {
        try {
          if (this.$store.getters.selectedDatasetId === dataset.id) {
            this.$store.commit('setSelectedDatasetId', -1);
          }
          return await http.delete(`dataset/${dataset.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const deletedMessage = this.$t('models.deleted_dataset_message');
      const { currentPage, itemsPerPage} = this;
      await this.getDatasets();
      const lastPage = Math.max(1, Math.ceil(this.totalDatasets / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      this.editOn = false;
      this.$store.commit('setSuccessMessage', deletedMessage);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await EntryAPI.post(this.uploadModel.id, file);
        this.$store.commit('setLoadingScreen', false);
        this.goToDataset(this.uploadModel);
      } catch (error) {
        console.log(error);
        this.$store.commit('setLoadingScreen', false);
      }
    },

    selectDataset() {
      this.showUpload = true;
    },

    async uploadDataset(payload) {
      const { dataset_name, zipfile } = payload;
      this.$store.commit('setLoadingScreen', true);
      const formData = new FormData();
      formData.append('dataset_name', dataset_name);
      formData.append('zipfile', zipfile);
      try {
        await http.post(
          'dataset/import',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        this.showUpload = false;
        this.checkUploaded();
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    selectFile(item) {
      if (!this.isActive(item)) {
        return
      }
      this.uploadModel = item;
      this.$refs.uploader.click()
    },

    goToDataset(dataset) {
      if (!this.isActive(dataset)) {
        return
      }
      this.$emit('openDataset', dataset);
    },

    goToAnnotation(dataset) {
      if (!this.isActive(dataset)) {
        return
      }
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`dataset/${id}`, { name: newName });
          const dataset = this.paginatedDatasets.find(d => d.id === id);
          dataset.name = newName;
          dataset.selected = false;
          this.$store.commit(
            'setSuccessMessage', this.$t('models.updated_dataset_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.editingDataset = -1;
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    handleCreateButton() {
      this.$emit('create');
    },

    async handleDownloadButton() {
      try {
        const response = await http.get(`dataset/prepare/${this.selected[0].id}`);
        await this.$store.commit(
          'setSuccessMessage', response.data.filename + ' ' + this.$t('models.prepare_dataset_zip')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        callbackError(error);
      }
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleEditName() {
      this.editingDataset = this.selected[0].id;
    },
  },

  emits: ['create', 'changeTotal', 'openDataset'],
}
</script>
