<template>
  <div class="create-generative-model py-7">
    <div
      class="clickable bottom-gap"
      @click="$emit('back')"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('models.all_generative') }}
      </h4>
    </div>
    <div class="label">
      {{ $t('forms.name') }}
    </div>
    <v-text-field
      ref="nameField"
      v-model="model.name"
      style="width: 300px; margin-top: 5px;"
      variant="outlined"
      color="primary"
      density="compact"
      :placeholder="$t('models.type_name')"
      @change="update++"
    />
    <div
      class="label"
      style="margin-top: -10px; margin-bottom: 5px;"
    >
      {{ $t('models.provider') }}
    </div>
    <v-select
      v-model="model.provider"
      style="width: 300px"
      class="inline-middle mt-0"
      variant="outlined"
      color="primary"
      density="compact"
      :items="Object.keys(providers)"
    />
    <div
      class="label top-gap-sm"
      style="margin-bottom: 5px"
    >
      {{ $t('models.version') }}
    </div>
    <v-select
      v-model="version"
      style="width: 300px"
      class="inline-middle mt-0"
      variant="outlined"
      color="primary"
      density="compact"
      :items="providers[model.provider]"
    />
    <div class="label top-gap-sm">
      {{ $t('models.prompt') }}
    </div>
    <v-radio-group
      v-model="promptType"
      style="margin-top: 5px"
      inline
    >
      <div
        v-for="type, i in promptTypes"
        :key="i"
        class="radio-box right-gap-sm"
      >
        <v-radio
          :label="type.text"
          :value="type.value"
        />
      </div>
    </v-radio-group>
    <textarea
      v-if="promptType === 'manual'"
      v-model="prompt"
      class="custom-textarea"
      style="margin-top: 0px; max-width: 700px"
      @change="update++"
    />
    <DocTypeSelect
      v-if="promptType === 'auto'"
      @selected-changed="(id) => {docTypeId = id}"
      has-data-points-filter
    />
    <v-btn
      v-if="promptType === 'manual'"
      style="margin-top: 60px; left: -20px"
      color="primary"
      :disabled="!valid"
      @click="createModel"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
    <v-btn
      v-if="promptType === 'auto'"
      class="top-gap"
      style="box-shadow: none"
      color="primary"
      :disabled="!validAuto"
      @click="createModelAuto"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect';

export default {
  name: 'CreateGenerativeModel',

  components: { DocTypeSelect },

  data() {
    return({
      model: {
        name: '',
        provider: 'OPENAI',
        prompt: '',
      },
      docTypeId: -1,
      promptType: 'manual',
      promptTypes: [
        {
          value: 'manual',
          text: this.$t('models.manual'),
        },
        {
          value: 'auto',
          text: this.$t('models.auto'),
        },
      ],
      providers: {
        OPENAI: ['gpt-3.5-turbo','gpt-4'],
        RECITAL: ["flan-large-invoice"],
      },
      update: 1,
      prompt: '',
    });
  },

  computed: {
    version() {
      if (this.providers[this.model.provider]) {
        return this.providers[this.model.provider][0];
      }
      return "";
    },
    valid() {
      this.update;
      return this.model.name.trim() !== '' && this.model.prompt.trim() !== '';
    },
    validAuto() {
      this.update;
      return this.model.name.trim() !== '' && this.docTypeId !== -1;
    },
  },

  watch: {
    prompt(prompt) {
      this.model.prompt = prompt;
    },

    version(version) {
      if (version === 'flan-large-invoice') {
        this.prompt = "What are the values of NUMERO_FACTURE,  DATE_ECHEANCE,  EMETTEUR_NOM,  DEVISE,  CLIENT_NOM_SOCIETE,  EMETTEUR_ADRESSE,  CLIENT_ADRESSE,  LIGNE_TVA_CODE,  DATE_EMISSION,  EMETTEUR_SIREN_SIRET_RCS,  TOTAL_TTC,  TOTAL_HT,  NET_A_PAYER,  ACOMPTE,  TVA_MONTANT,  TVA_TAUX,  TOTAL_TVA_MONTANT,  TVA_BASE,  LIGNE_CODE_REF,  LIGNE_QUANTITE,  LIGNE_PRIX_UNITAIRE_HT,  LIGNE_PRIX_TTC,  LIGNE_DESIGNATION,  LIGNE_PRIX_HT,  in the following text: ";
      }
    },
  },

  methods: {
    async createModel(){
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.post(`/model/generative/`, {
          ...this.model,
          version: this.version,
        });
        this.$store.commit(
          'setSuccessMessage', this.$t('models.created_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.$emit('back');
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },

    async createModelAuto(){
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.post(
          `/model/generative/${this.docTypeId}/prompt/`,
          { name: this.model.name, version: this.version, provider: this.model.provider }
        );
        this.$store.commit(
          'setSuccessMessage', this.$t('models.created_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.$emit('back');
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },
  },

  emits: ['back'],
}
</script>

<style lang="scss" scoped>
.create-generative-model {
  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
</style>
