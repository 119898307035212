/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class FolderAPI {
  static async getFolder(id) {
    return http.get(`${BASE_PATH}folder/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getFolders(parentFolderId = null) {
    return http
      .get(`${BASE_PATH}folder/${parentFolderId ? `?parent_folder_id=${parentFolderId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async createFolder(payload) {
    return http
      .post(`${BASE_PATH}folder/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
