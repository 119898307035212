<template>
  <div class="authorized-reviewers top-gap-lg">
    <div
      class="d-flex justify-space-between"
      style="margin-top: -30px"
    >
      <h4
        class="text-h4 primary--text"
        style="margin-top: 40px"
      >
        {{ $t('docTypes.reviewers') }}
      </h4>
      <TableActions
        type="reviewers"
        :number-of-selected="0"
        @filter-change="(filter) => trimmedFilter = filter"
      />
    </div>
    <v-card class="elevation-6 pa-0">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="8">
            <SortButton
              v-model="sortDesc"
              style="margin-top: -5px"
            />
            {{ $t('forms.email') }}
          </v-col>
          <v-col class="text-center">
            {{ $t('authorized') }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="paginatedUsers.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginatedUsers"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedUsers.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in paginatedUsers"
          :key="item.id"
          class="table-row fade-in table-row-height"
          style="border-bottom: 1px solid #eee !important"
        >
          <v-col cols="8">
            <ItemName
              :key="item.id"
              class="left-gap-lg"
              :item="{...item, name: `${item.email}`}"
              :editing-allowed="false"
              :clickable="false"
            />
          </v-col>
          <v-col class="text-center">
            <v-switch
              v-if="!disabled"
              v-model="item.authorized_reviewer"
              style="display: inline-block; margin-top: -17px;"
              color="primary"
              @update:model-value="v => updateAuthorized(item.id, v)"
              inset
            />
            <v-switch
              v-else
              style="display: inline-block; margin-top: -17px;"
              color="primary"
              inset
              disabled
            />
          </v-col>
        </v-row>
      </v-container>
      <TableFooter
        v-if="totalUsers > 0"
        v-model="itemsPerPage"
        style="margin-top: 10px !important; margin-right: 20px !important; margin-bottom: 5px !important;"
        :current-page="currentPage"
        :total-pages="Math.ceil(totalUsers / itemsPerPage)"
        @change-page="page => currentPage = page"
        @reset-current-page="resetCurrentPage"
      />
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { UserAPI } from '@/API/authenticator/UserAPI';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableFooter from '@/components/common/elements/Tables/TableFooter';

export default {
  name: 'AuthorizedReviewers',

  components: { ItemName, TableActions, SortButton, TableFooter },

  data() {
    return ({
      trimmedFilter: '',
      sortDesc: true,
      loading: false,
      paginatedUsers: [],
      itemsPerPage: 20,
      currentPage: 1,
      totalUsers: 0,
    });
  },

  watch: {
    sortDesc() {
      this.getUsers();
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getUsers();
    },

    currentPage(page) {
      this.getUsers(this.itemsPerPage * (page - 1), this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getUsers();
      }, 300
    ),
  },

  created() {
    this.getUsers();
  },

  methods: {
    updateAuthorized(id, authorized) {
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(() => {
        this.$emit('updateAuthorized', id, authorized);
      }, waitTime);
    },
  
    async refreshUsers() {
      this.paginatedUsers = [];
      await this.getUsers();
      this.currentPage = 1;
    },

    async getUsers(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const response = await UserAPI.getAllUsers(
          limit,
          offset,
          this.trimmedFilter || '',
          null,
          this.sortDesc,
          'basic',
        );
        this.paginatedUsers = response.data.map(user => {
          user.authorized_reviewer = this.config.allowed_basic_users.includes(user.id);
          return user;
        });
        this.totalUsers = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['updateAuthorized'],
}
</script>
