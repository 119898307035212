<template>
  <div class="extractors-view">
    <TableActions
      :type="tableActionsType"
      :number-of-selected="selected.length || selectedGroups.length || selectedGenerative.length"
      :lock-actions="lockActions"
      :used-models="usedModels"
      @edit-click="handleEditClick"
      @delete-click="handleDeleteClick"
      @filter-change="(filter) => {trimmedFilter = filter; handleFilter()}"
      @filter-enter="handleEnter"
      @refresh-used-models="getUsedModels"
    />
    <DataPointsTable
      ref="DPTable"
      :all-items="dataPoints"
      :filtered-items="filtered"
      :loading="loading"
      :disabled="lockActions"
      @set-items="items => $emit('updateDataPoints', items)"
      @get-items="sortDesc => $emit('getDataPoints', sortDesc)"
      @save-name="$emit('saveDPName')"
    />
    <LabelGroupsTable
      ref="GroupTable"
      :all-items="labelGroups"
      :filtered-items="filteredLabelGroups"
      :loading="loading"
      :disabled="lockActions"
      @set-items="items => $emit('updateLabelGroups', items)"
      @get-items="sortDesc => $emit('getLabelGroups', sortDesc)"
      @save-name="$emit('saveGroupName')"
    />
    <GenerativeExtractorsTable
      ref="GenerativeTable"
      :all-items="generativeExtractors"
      :filtered-items="filteredGenerative"
      :loading="loading"
      :disabled="lockActions"
      @set-items="items => $emit('updateGenerative', items)"
      @get-items="sortDesc => $emit('getGenerativeExtractors', sortDesc)"
      @save-name="$emit('saveGenerativeName')"
    />
    <DeleteDialog
      v-model="groupDeleteDialog"
      :title="$t('datatable.deleteGroup')"
      :message="$t('datatable.deleteConfirmationGroup')"
      @confirm="deleteGroup"
      @close="groupDeleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.deleteDP')"
      :message="$t('datatable.deleteConfirmationDP')"
      @confirm="deleteDP"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="generativeDeleteDialog"
      :title="$t('datatable.deleteGE')"
      :message="$t('datatable.deleteConfirmationGE')"
      @confirm="deleteGenerative"
      @close="generativeDeleteDialog = false"
    />
  </div>
</template>

<script>
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableActions from '@/components/common/elements/Tables/TableActions';
import DataPointsTable from '@/components/extract/elements/DataPoints/DataPointsTable';
import LabelGroupsTable from '@/components/extract/elements/LabelGroups/LabelGroupsTable';
import GenerativeExtractorsTable from '@/components/extract/elements/GenerativeExtractors/GenerativeExtractorsTable';
import model_mixin from '@/mixins/model.js';
import { http } from '@/plugins/axios';

export default {
  name: 'ExtractorsView',

  mixins: [
    model_mixin,
  ],

  components: {
    DeleteDialog,
    DataPointsTable,
    LabelGroupsTable,
    GenerativeExtractorsTable,
    TableActions,
  },

  data() {
    return ({
      trimmedFilter: '',
      itemsPerPage: 20,
      currentPage: 1,
      deleteDialog: false,
      groupDeleteDialog: false,
      generativeDeleteDialog: false,
      forceCompute: Math.random(),
      usedModels: [],
    });
  },

  computed: {
    tableActionsType() {
      if (this.selected.length > 0 && this.selectedGroups.length === 0 && this.selectedGenerative.length === 0) {
        return 'dataPoints';
      } else if (this.selected.length === 0 && this.selectedGroups.length > 0 && this.selectedGenerative.length === 0) {
        return 'labelGroups';
      } else if (this.selected.length === 0 && this.selectedGroups.length === 0 && this.selectedGenerative.length > 0) {
        return 'generativeExtractors';
      }
      return 'DPsAndGroups';
    },

    loading() {
      return this.docTypeLoading;
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    filtered: {
      cache: false,
      get: function() {
        if (this.dataPoints && this.dataPoints.length > 0) {
          return this.dataPoints.filter(dp => dp.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    filteredLabelGroups: {
      cache: false,
      get: function() {
        if (this.labelGroups && this.labelGroups.length > 0) {
          return this.labelGroups.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    filteredGenerative: {
      cache: false,
      get: function() {
        if (this.generativeExtractors && this.generativeExtractors.length > 0) {
          return this.generativeExtractors.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    selected: {
      cache: false,
      get() {
        this.forceCompute;
        if (this.filtered.length > 0) {
          return this.filtered.filter(dp => dp.selected);
        }
        return [];
      },
      set() {
        return this.selected;
      }
    },

    selectedGroups: {
      cache: false,
      get() {
        this.forceCompute;
        if (this.filteredLabelGroups.length > 0) {
          return this.filteredLabelGroups.filter(group => group.selected);
        }
        return [];
      },
      set() {
        return this.selectedGroups;
      }
    },

    selectedGenerative: {
      cache: false,
      get() {
        this.forceCompute;
        if (this.filteredGenerative.length > 0) {
          return this.filteredGenerative.filter(g => g.selected);
        }
        return [];
      },
      set() {
        return this.selectedGenerative;
      }
    },
  },

  async mounted() {
    await this.getUsedModels();
  },

  methods: {
    async deleteDP() {
      const length = this.selected.length;
      for (let i = 0; i < length; i++) {
        try {
          await http.delete(`system_2/data_point/${this.selected[i].id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }
      this.finishDeletion();
    },

    async finishDeletion() {
      const deletedIds = this.selected.map(item => item.id);
      const dpList = this.dataPoints.filter(dp => !deletedIds.includes(dp.id));
      this.$emit('updateDataPoints', dpList);
      this.deleteDialog = false;
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.deleted'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    async deleteGroup() {
      const length = this.selectedGroups.length;
      for (let i = 0; i < length; i++) {
        try {
          await http.delete(`system_2/extraction_groups/${this.selectedGroups[i].id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }
      this.finishGroupDeletion();
    },

    async finishGroupDeletion() {
      const deletedIds = this.selectedGroups.map(item => item.id);
      const groups = this.labelGroups.filter(group => !deletedIds.includes(group.id));
      this.$emit('updateLabelGroups', groups);
      this.groupDeleteDialog = false;
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.deleted'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    async deleteGenerative() {
      const length = this.selectedGenerative.length;
      for (let i = 0; i < length; i++) {
        try {
          await http.delete(`system_2/generative_extractor/${this.selectedGenerative[i].id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }
      this.finishGenDeletion();
    },

    async finishGenDeletion() {
      const deletedIds = this.selectedGenerative.map(item => item.id);
      const gen = this.generativeExtractors.filter(g => !deletedIds.includes(g.id));
      this.$emit('updateGenerative', gen);
      this.generativeDeleteDialog = false;
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.generative_deleted'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleEnter() {
      let item;
      let tableName;
      if (this.filtered.length === 1 && this.filteredLabelGroups.length === 0) {
        item = this.filtered[0];
        tableName = 'DPTable';
      } else if (this.filteredLabelGroups.length === 1 && this.filtered.length === 0) {
        item = this.filteredLabelGroups[0];
        tableName = 'GroupTable';
      }
      if (item) {
        const table = this.$refs[tableName];
        if (table) {
          table.handleEditButton(this.$route.params.id);
        }
      }
    },

    handleEditClick() {
      if (this.tableActionsType === 'dataPoints') {
        this.handleEdit();
      } else if (this.tableActionsType === 'labelGroups') {
        this.handleEditGroups();
      } else if (this.tableActionsType === 'generativeExtractors') {
        this.handleEditGenerative();
      }
    },

    handleEdit() {
      this.edited = this.selected[0];
      this.$refs.DPTable.$refs[`name_${this.edited.id}`][0].renameFile();
    },

    handleEditGenerative() {
      this.edited = this.selectedGenerative[0];
      this.$refs.GenerativeTable.$refs[`gen_${this.edited.id}`][0].renameFile();
    },

    handleEditGroups() {
      this.edited = this.selectedGroups[0];
      this.$refs.GroupTable.$refs[`name_${this.edited.id}`][0].renameFile();
    },

    handleFilter() {
      if (this.trimmedFilter !== '') {
        this.currentPage = 1;
      }
    },

    prevPage() {
      if (!this.isFirstPage) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (!this.isLastPage) {
        this.currentPage++;
      }
    },

    handleDeleteClick() {
      if (this.tableActionsType === 'dataPoints') {
        this.handleDelete();
      } else if (this.tableActionsType === 'labelGroups') {
        this.handleDeleteGroups();
      } else if (this.tableActionsType === 'generativeExtractors') {
        this.handleDeleteGenerative();
      }
    },

    handleDelete() {
      this.deleteDialog = true;
    },

    handleDeleteGroups() {
      this.groupDeleteDialog = true;
    },

    handleDeleteGenerative() {
      this.generativeDeleteDialog = true;
    },
  },

  props: {
    dataPoints: {
      required: true,
      type: Array,
    },

    labelGroups: {
      required: true,
      type: Array,
    },

    generativeExtractors: {
      required: true,
      type: Array,
    },

    docTypeLoading: {
      required: true,
      type: Boolean,
    },

    lockActions: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'updateDataPoints',
    'getDataPoints',
    'saveDPName',
    'updateLabelGroups',
    'getLabelGroups',
    'saveGroupName',
    'updateGenerative',
    'saveGenerativeName',
    'getGenerativeExtractors',
  ],
}
</script>
<style lang="scss">
.bottom-nav {
  font-size: 12px;
  width: 350px;
  float: right;
}

.table-row {
  padding: 1rem 1.5rem;
  margin: 0 !important;
  width: 100%;

  &:not(&:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  &__header {
    font-size: 14px;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }
}
.disabled {
  color: #999 !important;
}
</style>
