<template>
  <div
    v-if="searchTotalFilesSet"
    class="search-home-view page-padding py-7"
  >
    <BreadcrumbComponent />
    <InitialSearchUpload
      v-if="shouldUpload"
      class="top-gap-lg"
      @upload-complete="getDisplayData(searchOrg)"
    />
    <SearchPage v-else />
  </div>
</template>

<script>
import { FolderAPI } from '@/API/search/FolderAPI';
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import InitialSearchUpload from "@/components/search/views/Home/InitialSearchUpload";
import SearchPage from "@/components/search/views/Home/SearchPage";

export default {
  name: 'SearchHomeView',

  components: {
    BreadcrumbComponent,
    SearchPage,
    InitialSearchUpload,
  },

  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    searchOrg() {
      return this.$store.getters.searchOrg;
    },

    shouldUpload() {
      return (
        ['orgadmin', 'sysadmin'].includes(this.loggedInUser.role)
        && this.searchTotalFiles === 0
      );
    },

    searchTotalFiles() {
      return this.$store.getters.searchTotalFiles;
    },

    searchTotalFilesSet() {
      return this.$store.getters.searchTotalFilesSet;
    },
  },

  watch: {
    searchOrg(org) {
      this.getDisplayData(org);
    }
  },

  async created() {
    if (this.searchOrg === -1) {
      this.$store.commit('setSearchOrg', this.loggedInUser.org_id);
    }
    this.setBreadcrumb();
  },

  methods: {
    async getDisplayData(org) {
      try {
        const rootId = await SearchConfigAPI.getRoot(org);
        await this.getFolders(rootId);
        const totalFiles = await this.getFilesCount(rootId);
        this.$store.commit('setSearchTotalFiles', totalFiles);
      } catch (err) {
        console.log(err);
      }
    },

    async getFolders(id = null) {
      try {
        const folders = await FolderAPI.getFolders(id);
        this.$store.commit(
          'setSearchFolders',
          folders.filter(f => f.parent_folder_id).map(f => {
            f.open = false;
            f.folders = [];
            f.selected = false;
            return f;
          })
        );
      } catch (err) {
        console.log(err);
      }
    },

    async getFilesCount(id) {
      try {
        const folder = await FolderAPI.getFolder(id);
        return folder.nb_processed_files;
      } catch (err) {
        console.log(err);
      }
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home') },
          { title: this.$t('search.title') },
        ]
      );
    },
  }
}
</script>
