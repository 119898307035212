/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class SearchFileAPI {
  static async getFiles(folderId) {
    return http
      .get(`${BASE_PATH}file/?folder_id=${folderId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async post(file, folderId) {
    const formData = new FormData();
    formData.append('file_in', file);
    formData.append('folder_id', folderId);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(`${BASE_PATH}file/`, formData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
