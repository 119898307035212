<template>
  <div
    class="bundle-view py-5"
    :class="{ 'bundle-view--open-doc-viewer': selectedPage }"
  >
    <div
      v-if="loaded"
      class="d-flex align-center"
      style="max-width: 700px;"
    >
      <BackLink
        class="mb-2"
        :show-text="false"
        :target="{ name: 'Corrections', query: { activeTab: 'bundles' } }"
      />
      <HeaderName
        :item="{ name: bundle.filename, id: bundle.id }"
        :editable="false"
        @save="updateCollectionName"
      />
    </div>
    <div
      v-if="mergedPages.length > 0 && loaded"
      class="d-flex flex-wrap top-gap fade-in"
      style="overflow: visible !important;"
    >
      <FileContainer
        v-for="merged, i in mergedPages"
        :key="i"
        :classified-pages="merged"
        :last="i === mergedPages.length - 1"
        :categories="model.categories"
        :category-map="categoryMap"
        :selected-page="selectedPage"
        @update:page-category="updatePage"
        @select-page="page => selectedPage = page"
      />
    </div>
    <div class="floating-buttons">
      <v-btn
        v-if="bundle && bundle.status !== 'uploaded'"
        class="clickable mr-3"
        color="primary"
        style="box-shadow: none"
        @click="finishReview"
        rounded
      >
        <v-icon
          v-if="submittingReview"
          left
        >
          fas fa-spinner fa-pulse
        </v-icon>
        {{ $t('workflows.split.submit_review') }}
      </v-btn>
      <!-- <v-btn
        v-if="bundle && bundle.status !== 'uploaded'"
        class="clickable"
        color="primary"
        @click="download"
        rounded
      >
        <v-icon
          v-if="downloading"
          left
        >
          fas fa-spinner fa-pulse
        </v-icon>
        Télécharger le zip
      </v-btn> -->
    </div>
    <div
      class="sidebar"
      :style="{width: `${sidebarWidth}px`}"
    >
      <v-icon
        class="clickable"
        style="position: absolute; left: 10px; top: 10px; color: #07002D"
        @click="selectedPage = null"
      >
        fas fa-chevron-right
      </v-icon>
      <div
        v-if="selectedPageImage"
        class="page background-spread fade-in clickable inline-middle vertical-centered"
        :style="{
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
          'background-image': `url(${selectedPageImage})`,
        }"
      />
    </div>
  </div>
</template>
<script>
  import { http } from '@/plugins/axios';
  import { ClassifyBundlesAPI } from '@/API/classify/ClassifyBundlesAPI';
  import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
  
  import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
  import FileContainer from '@/components/extract/elements/Workflows/PageCorrection/FileContainer';
  import HeaderName from '@/components/extract/elements/Workflows/PageCorrection/HeaderName';
  
  export default {
    name: 'PageCorrectionView',
    
    components: {
      BackLink,
      FileContainer,
      HeaderName
    },
    
    data() {
      return {
        bundle: null,
        pages: [],
        model: {},
        statusCheck: null,
        loaded: false,
        downloading: false,
        updatedPages: [],
        updatingPages: false,
        selectedPage: null,
        pageImageMap: {},
        baseWidth: 500,
        submittingReview: false,
      }
    },

    computed: {
      categoryMap() {
        return this.model.categories.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});
      },

      mergedPages() {
        return this.pages.reduce((acc, page) => {
          const existing = acc.find(merged => merged.category_id === page.category_id);
          if (existing) {
            existing.pages.push(page);
          } else {
            acc.push({
              category_id: page.category_id,
              pages: [page],
            });
          }
          return acc;
        }, []);
      },

      selectedPageImage() {
        return this.selectedPage ? this.pageImageMap[this.selectedPage.id] : '';
      },

      ratio() {
        if (this.selectedPage && this.selectedPage.image_width && this.selectedPage.image_height) {
          return this.selectedPage.image_height / this.selectedPage.image_width;
        }
        return 1;
      },
    
      isPortrait() {
        return this.ratio > 1;
      },
    
      pageHeight() {
        const ratio = this.ratio;
        return this.baseWidth * ratio;
      },
    
      pageWidth() {
        const ratio = this.ratio;
        if (this.isPortrait) {
          return this.pageHeight / ratio;
        }
        return this.baseWidth;
      },
    
      sidebarWidth() {
        return this.selectedPage ? 600 : 0;
      },
    },
    
    watch: {
      selectedPage(page) {
        if (page) {
          if (!this.pageImageMap[page.id]) {
            this.getImage();
          }
        }
      },
    },
    
    async created() {
      this.bundle = this.$route.meta.bundle;
      if (!this.bundle) {
        await this.getBundle();
      }
      await this.getModel();
      await this.getPages();
      this.loaded = true;
    },
    
    umounted() {
      clearTimeout(this.statusCheck);
    },
    
    methods: {
      async getImage() {
        try {
          const data = await ClassifyBundlesAPI.getPageImage(
            this.$route.params.id,
            this.selectedPage.id,
          );
          this.pageImageMap = {
            ...this.pageImageMap,
            [this.selectedPage.id]: URL.createObjectURL(new Blob([data])),
          };
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },
    
      async reset() {
        this.loaded = false;
        await this.getBundle();
        if (this.bundle) {
          await this.getPages();
          this.getModel()
        }
        this.scrollToTop();
        this.loaded = true;
      },
    
      async getBundle() {
        try {
          this.bundle = await ClassifyBundlesAPI.getBundle(this.$route.params.id);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          this.error = true;
        } finally {
          this.loaded = true;
        }
      },

      async getModel() {
        try {
          this.model = await ClassifyModelAPI.getModel(this.bundle.model.name);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },

      async updateCollectionName(name) {
        try {
          this.bundle.name = name;
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },
    
      async getPages() {
        try {
          const data = await ClassifyBundlesAPI.getPages(this.bundle.id);
          this.pages = data.sort((a, b) => a.page_number - b.page_number);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },
    
      exportFile(response, filename) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      },
    
      async download() {
        try {
          this.downloading = true;
          const response = await http.get(`download/files/?pli_id=${this.$route.params.id}`, {
            responseType: "arraybuffer",
          });
          const fileName = this.bundle.name
          .replace(/,/g, "")
          .replace(/\.[^/.]+$/, ".zip");
          this.exportFile(response, fileName);
        } catch (error) {
          this.$store.commit("setSnackbar", true);
          console.log(error);
        } finally {
          this.downloading = false;
        }
      },
    
      addUpdatedPage(page) {
        const index = this.updatedPages.findIndex(p => p.page_id === page.page_id);
        if (index === -1) {
          this.updatedPages.push(page);
        } else {
          this.updatedPages[index] = page;
        }
      },
    
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        });
      },

      async updatePage(page) {
        try {
          await ClassifyBundlesAPI.updatePage(
            this.bundle.id,
            page.page_id,
            page.category_id
          );
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },

      async finishReview() {
        try {
          this.submittingReview = true;
          const data = this.pages.map(page => ({
            page_number: page.page_number,
            category_name: this.categoryMap[page.category_id],
          }));
          await ClassifyBundlesAPI.validate(this.bundle.id, data);
          await this.$store.commit(
            'setSuccessMessage',
            this.$t('workflows.split.review_submitted'),
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.$router.push({ name: 'Corrections', query: { activeTab: 'bundles' } });
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        } finally {
          this.submittingReview = false;
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.bundle-view {
  padding: 40px 40px 350px 60px !important;
  min-height: 100vh;
  
  &--open-doc-viewer.closed-router {
    width: calc(100vw - 810px);
  }

  &--open-doc-viewer.open-router {
    width: calc(100vw - 660px);
  }

  .floating-buttons {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
  }

  .sidebar {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: darkgray;
    transition: width 300ms;
    text-align: center;
    z-index: 1000;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
  