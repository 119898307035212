<template>
  <div class="files-table">
    <TableActions
      ref="tableActions"
      type="documents"
      :number-of-selected="selected.length"
      :edit-condition="selected.every(f => !['uploaded', 'ingested'].includes(f.status))"
      :delete-condition="selected.every(f => (!f.user_id || f.user_id === user.id))"
      @edit-click="handleEdit"
      @delete-click="handleDelete"
      @validate-click="autoValidate"
      @create-click="$emit('selectFiles')"
      @reprocess-click="handleRerun"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    />
    <v-card class="elevation-6 pa-0 top-gap-sm">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col cols="5">
            {{ $t('datatable.header.docName') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.size') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.addDate') }}
          </v-col>
          <v-col cols="auto">
            {{ $t('datatable.header.ProcessDate') }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading || filesLoading">
        <div v-if="paginatedFiles.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginatedFiles"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedFiles.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="(item, index) in paginatedFiles"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="handleSelect"
            />
          </v-col>
          <v-col
            v-if="!['uploaded', 'ingested', 'error'].includes(item.status)"
            cols="5"
          >
            <ItemName
              :key="item.id"
              style="z-index: 202"
              type="file"
              :item="item"
              :editing-allowed="!!(item.selected)"
              :editing="editingFile === item.id"
              @save-file-name="(id, newName) => saveName(id, newName)"
              @name-click="$emit('validate', { id: item.id, index: trimmedFilter === '' ? (currentPage - 1) * itemsPerPage + index : -1 })"
            />
          </v-col>
          <v-col
            v-else-if="item.status === 'error'"
            cols="5"
          >
            <v-tooltip
              :key="renderKey"
              bottom
            >
              <template #activator="{ props }">
                <div class="d-flex">
                  <v-icon
                    style="margin-right: 5px; top: 3px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                  <ItemName
                    :key="item.id"
                    class="inline-middle"
                    style="width: 95%"
                    type="file"
                    :item="item"
                    :editing-allowed="!!(item.selected)"
                    :clickable="false"
                    @save-file-name="(id, newName) => saveName(id, newName)"
                  />
                </div>
              </template>
              {{ $t('docTypes.doc_processing_failed') }}
            </v-tooltip>
          </v-col>
          <v-col
            v-else
            cols="5"
          >
            <v-tooltip right>
              <template #activator="{ props }">
                <div class="d-flex">
                  <v-icon
                    style="top: 3px; margin-right: 5px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-spinner fa-pulse
                  </v-icon>
                  <ItemName
                    class="inline-middle"
                    style="width: 100%"
                    type="file"
                    :item="item"
                    :editing-allowed="false"
                    :clickable="false"
                  />
                </div>
              </template>
              {{ $t('docTypes.being_processed') }}
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            {{ formatSize(item.size) }}
          </v-col>
          <v-col cols="2">
            <small class="gray--text">
              {{ formatDate(item.uploaded_at) }}
            </small>
          </v-col>
          <v-col cols="auto">
            <small
              v-if="item.dt_processed_at"
              class="gray--text"
            >
              {{ formatDate(item.dt_processed_at) }}
            </small>
            <small v-else>
              —
            </small>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <TableFooter
      v-if="totalFiles > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="totalPages"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.delete_file')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteFiles"
      @close="deleteDialog = false"
    />
    <v-dialog
      v-model="progressDialog"
      max-width="400"
      persistent
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-3">
          {{ $t('datatable.deleting_file') }} {{ currentDelete }} / {{ allDelete }}
        </h2>
        <v-progress-linear :value="Math.floor(currentDelete / allDelete * 100)" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import ItemName from '@/components/common/elements/General/ItemName';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import file_mixin from "@/mixins/file.js";
import format_mixin from '@/mixins/format.js';
import { http } from '@/plugins/axios';
import { FileAPI } from '@/API/extract/FileAPI';

export default {
  name: 'FilesTable',

  mixins: [file_mixin, format_mixin],

  components: {
    DeleteDialog,
    ItemName,
    TableActions,
    TableFooter,
    SortButton,
  },

  data() {
    return ({
      sortDesc: true,
      paginatedFiles: [],
      trimmedFilter: '',
      itemsPerPage: 20,
      currentPage: 1,
      deleteDialog: false,
      progressDialog: false,
      currentDelete: 0,
      allDelete: 0,
      allSelected: false,
      statusCheck: null,
      filesLoading: false,
      forceCompute: Math.random(),
      renderKey: 10,
      editingFile: -1,
    });
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalFiles / this.itemsPerPage);
    },

    selected: {
      get() {
        if (this.paginatedFiles.length > 0) {
          return this.paginatedFiles.filter(f => f.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    type: {
      get() {
        if (this.$store.getters.documentTypes[this.$route.params.id]) {
          return this.$store.getters.documentTypes[this.$route.params.id];
        }
        return { name: "" };
      },
      set(newType) {
        this.$store.commit("setDocumentType", newType);
      },
    },

    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  watch: {
    sortDesc() {
      this.getFiles(true);
    },

    totalFiles(total) {
      if (this.trimmedFilter === '') {
        this.totalFilesDisplay = total;
      }
    },

    deleteDialog(on) {
      if (on) {
        clearTimeout(this.statusCheck);
      } else {
        this.checkStatus();
      }
    },

    async itemsPerPage() {
      this.resetCurrentPage();
      await this.getFiles(true);
      await this.checkStatus();
    },

    async currentPage(page) {
      this.unselect();
      await this.getFiles(true, (page - 1) * this.itemsPerPage, this.itemsPerPage);
      await this.checkStatus();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getFiles(true);
      }, 300
    ),
  },

  mounted() {
    this.filesLoading = true;
    this.paginatedFiles = [];
    this.refresh();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async refresh() {
      await this.getFiles(true, (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
      await this.checkStatus();
    },

    async checkStatus() {
      if (this.paginatedFiles.some(file => ['uploaded', 'ingested'].includes(file.status))) {
        await this.getFiles(false, (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
        try {
          this.statusCheck = setTimeout(async () => {
            await this.checkStatus();
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      }
    },

    getProcessingLength(files) {
      return files.filter((f) => ["uploaded", "ingested", "error"].includes(f.status))
        .length;
    },

    async getFiles(force = false, offset = 0, limit = this.itemsPerPage) {
      if (force) {
        this.filesLoading = true;
      }
      try {
        let oldLength, newLength, processingLength, newProcessingLength;
        if (!force) {
          oldLength = this.paginatedFiles.length;
          processingLength = this.getProcessingLength(this.paginatedFiles);
        }
        const response = await FileAPI.get(
          this.$route.params.id,
          offset,
          limit,
          this.trimmedFilter || '',
          this.sortDesc,
        );
        if (!force) {
          newProcessingLength = this.getProcessingLength(response.data);
          newLength = response.data.length;
        }
        if (force || oldLength !== newLength || processingLength !== newProcessingLength) {
          const files = response.data
            .map(file => {
              const oldFile = this.paginatedFiles.find(f => f.id === file.id);
              if (oldFile && oldFile.selected) {
                file.selected = true;
              } else {
                file.selected = false;
              }
              return file;
            }
          );
          this.paginatedFiles = files;
          this.totalFiles = parseInt(response.headers['x-total-count'], 10);
          if (this.trimmedFilter === '') {
            this.totalFilesDisplay = this.totalFiles;
          }
          this.renderKey++;
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.filesLoading = false;
      }
    },

    handleSelect() {
      this.allSelected = this.paginatedFiles.every(f => f.selected);
      this.renderKey++;
    },

    toggleSelectAll() {
      this.paginatedFiles.forEach(item => {
        item.selected = this.allSelected;
      });
      this.renderKey++;
    },

    handleEnter() {
      if (this.paginatedFiles.length > 0) {
        this.$emit('validate', {id: this.paginatedFiles[0].id, index: this.trimmedFilter === '' ? 0 : -1})
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.unselect();
      this.renderKey++;
    },

    async handleRerun() {
      const total = this.selected.length;
      for (let i = 0; i < total; i++) {
        await http.post(`files/${this.selected[i].id}/rerun/system1/`);
      }
      this.$store.commit('setSuccessMessage', this.$t('docTypes.reprocessing_started'));
      this.$store.commit('setSuccessSnackbar', true);
      this.unselect();
      await this.refresh();
    },

    handleEdit() {
      this.editingFile = this.selected[0].id;
    },

    handleFilter() {
      if (this.trimmedFilter !== '') {
        this.currentPage = 1;
      }
    },

    handleDelete() {
      this.deleteDialog = true;
    },

    async deleteFile(id) {
      try {
          await http.delete(`files/${id}`);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return
      }
    },

    async deleteFiles() {
      const total = this.selected.length;
      this.deleteDialog = false;
      this.allDelete = total;
      this.progressDialog = true;
      for (let i = 0; i < total; i++) {
        this.currentDelete = i + 1;
        await this.deleteFile(this.selected[i].id);
      }
      const deletedIds = this.selected.map(s => s.id);
      const verifiedDeleted = this.selected.filter(s => s.status === 'verified').length;
      this.setFiles(
        [...this.paginatedFiles.filter(f => !deletedIds.includes(f.id))],
        deletedIds.length,
        verifiedDeleted,
      );
      this.progressDialog = false;
      this.allSelected = false;
      this.currentPage = Math.max(1, Math.min(this.currentPage, this.totalPages));
      this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
      this.$store.commit('setSuccessSnackbar', true);
    },

    setFiles(files, deleted, verifiedDeleted) {
      this.paginatedFiles = files;
      this.$refs.tableActions.filter = '';
      this.trimmedFilter = '';
      this.getFiles(true, (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
      this.totalFiles = this.totalFiles - deleted;
      this.totalFilesDisplay = this.totalFiles;
      this.type.nb_verified_files -= verifiedDeleted;
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(
            `files/${id}/rename`,
            { name: newName },
          );
          const file = this.paginatedFiles.find(f => f.id === id);
          file.name = newName;
          file.selected = false;
          this.$store.commit('setLoadingScreen', false);
          await this.$store.commit(
            'setSuccessMessage', this.$t('datatable.docRenamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
          console.log(error);
        }
      }
    },
    async autoValidate() {
      await Promise.all(this.selected.map(async type => {
        try {
         await http.post(`system_2/verify/auto/${type.id}/`, {});
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.unselect();
      await this.$store.commit(
            'setSuccessMessage', this.$t('docTypes.validated')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },
    unselect(){
      this.allSelected = false;
      this.paginatedFiles.forEach(item => {
        item.selected = false;
      });
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectFiles', 'validate'],
}
</script>
