<template>
  <div class="job-history-view my-4 d-flex">
    <div class="history-table-container">
      <BackLink
        class="inline-middle mt-2"
        :target="{ name: 'Workflows', params: { activeTab: 'jobs' } }"
        :show-text="false"
      /> 
      <h2 class="inline-middle text-h2 text--primary">
        Job #{{ $route.params.id }}
        <v-chip
          v-if="job && job.is_test"
          color="primary"
          size="small"
        >
          Test
        </v-chip>
        <v-chip
          v-if="job"
          size="small"
          :color="statusColorMap[job.state]"
        >
          {{ job.state }}
        </v-chip>
      </h2>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <h4
            v-if="currentStep"
            v-bind="props"
            class="primary--text clickable mb-2 mt-0"
            @click="$router.push({ name: 'ConfigureWorkflow', params: { id: currentStep.workflow_id } })"
          >
            {{ currentStep.workflow_name }}
            <v-icon
              color="primary"
              size="12"
            >
              fas fa-cog
            </v-icon>
          </h4>
        </template>
        {{ $t('workflows.configure_workflow') }}
      </v-tooltip>
      <div v-if="job && currentStep">
        <v-card class="elevation-6 pa-0 mb-4">
          <v-card-title class="text-h3 primary--text ">
            Current step: {{ currentStep.name }}
          </v-card-title>
          <v-card-subtitle>
            {{ `${$t('workflows.updated_at')} ${formatDate(currentStep.created_at)}` }}
            <v-chip
              size="small"
              :color="statusColorMap[currentStep.status]"
            >
              {{ currentStep.status }}
            </v-chip>
          </v-card-subtitle>
          <v-card-text>
            <h4 class="mb-2">
              Data
            </h4>
            <pre
              ref="currentData"
              class="current-data formatted-text code-area language-json"
            >{{ JSON.stringify(currentStep.data, null, 2) || $t('workflows.jobs.no_data') }}</pre>
          </v-card-text>
        </v-card>
      </div>
      <div>
        <h3 class="text-h3">
          {{ $t('workflows.jobs.history') }}
        </h3>
      </div>
      <v-card class="elevation-6 pa-0">
        <v-container
          class="job-history-table pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="4">
              {{ $t('workflows.updated_at') }}
            </v-col>
            <v-col cols="2">
              {{ $t('workflows.jobs.type') }}
            </v-col> 
            <v-col>
              {{ $t('workflows.steps.name') }}
            </v-col>
            <v-col cols="2">
              {{ $t('workflows.steps.state') }}
            </v-col>
          </v-row>
        </v-container>
        <div v-if="loading">
          <div v-if="logs.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in logs"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="logs.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i> {{ $t('workflows.no_results') }}  </i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="log in logs"
            :key="log.id"
            class="table-row table-row-height clickable hover-highlight"
            :style="selected === log ? 'background-color: rgb(var(--v-theme-primary-lighten2))' : 'inherit'"
            @click="selected = log"
          >
            <v-col
              cols="4"
              class="h-100"
            >
              {{ formatDate(log.created_at) }}
            </v-col>
            <v-col cols="2">
              {{ log.type }}
            </v-col>
            <v-col>
              {{ log.type === 'Step' ? log.step_name : log.transition_name }}  

              <span v-if="log.index !== null">
                [ {{ log.index }} ]
              </span>
            </v-col>
            <v-col cols="2">
              {{ log.status }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div class="log-panel-container d-flex flex-column">
      <code class="formatted-text log-panel mb-4">
        <pre v-if="selected">{{ selected.logs || $t('workflows.jobs.no_logs') }}</pre>
        <div
          v-else
          class="select-message"
        >
          {{ $t('workflows.jobs.select_log_row') }}
        </div>
      </code>
      <code class="formatted-text log-panel">
        <pre
          v-if="selected"
          ref="expandedCode"
          class="code-area language-json"
        >{{ JSON.stringify(selected.data, null, 2) || $t('workflows.jobs.no_data') }}</pre>
        <div
          v-else
          class="select-message"
        >
          {{ $t('workflows.jobs.select_data_row') }}
        </div>
      </code>
    </div>
  </div>
</template>

<script>
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import format_mixin from '@/mixins/format.js';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import { nextTick } from 'vue';
import hljs from 'highlight.js';
import '@/assets/atom-one-dark.css';


export default {
  name: 'JobHistory',
  mixins: [format_mixin],

  components: {
    BackLink,
  },
  
  data() {
    return ({
      data: '',
      loading: false,
      selected: null,
      logs:[],
      job: null,
      currentStep: null,
      statusColorMap: {
        started: 'primary',
        error: 'error',
        done: 'success',
      },
    })
  },

  watch: {
    selected() {
      if (this.selected){
        nextTick(() => {
          hljs.highlightElement(this.$refs.expandedCode, {language: 'python'});
        });
      }
    }
  },

  async mounted(){
    this.loading = true;
    await this.getHistory(this.$route.params.id);
    await this.getJobInfo(this.$route.params.id);
    if (this.job) {
      await this.getCurrentStep(this.job.workflow_id, this.job.step_id);
    }
    nextTick(() => {
      hljs.highlightElement(this.$refs.currentData, {language: 'json'});
    });
    this.loading = false;
  },

  methods: {
    async getJobInfo(id){
      try {
        this.job = await WorkflowAPI.getJob(id);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.error(error);
      }
    },

    async getCurrentStep(workflowId, stepId){
      try {
        const currentStep = await WorkflowAPI.getCurrentStep(workflowId, stepId);
        const currentLog = this.logs.find(log => log.step_id === currentStep.id) || {};
        this.currentStep = {
          ...currentStep,
          ...currentLog,
        }
        this.selected = currentLog;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.error(error);
      }
    },

    async getHistory(id){
      this.logs = await WorkflowAPI.getJobHistory(id);
      for (let i=0; i<this.logs.length; i++){
        if (this.logs[i].step_id !== null){
          this.logs[i].type = 'Step';
        } else {
          this.logs[i].type = 'Transition';
        }
      }
    }
  },
}

</script>
<style lang="scss" scoped>
.hover-highlight {
  &:hover {
    background-color: rgba(var(--v-theme-primary-lighten2), 0.25)
  }
}


.job-history-view {
  height: calc(100vh - 32px);
  overflow-y: auto;
  
  .history-table-container {
    margin: 0 32px;
    width: 50%;
  }

  .log-panel-container {
    width: 50%;
    margin-right: 32px;
    height: 100%;
  }

  .formatted-text {
    padding: 10px 16px;
    background-color: #EAEBEE;
    color: var(--v-theme-grey-darken2);
    border-radius: 5px;
    border: 1px solid #DADCE0;
    overflow-y: auto;
    font-size: smaller;
  }

  .current-data {
    height: 200px;
    overflow-y: auto;
  }

  .log-panel {
    height: calc((100% - 16px) / 2);
    position: relative;

    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    .select-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
