import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';

export default {
  data() {
    return ({
      statusMapping: {
        'active': 'Active',
        'pending': 'Training',
      },
      selectedModelVersion: 0,
      selectedNewModel: 0,
      selectedNewModelVersion: 0,
      modelVersions: [],
      compatibleModels: [],
    });
  },

  computed: {
    numberOfCustomModels() {
      return this.customModels.length;
    },

    customModels: {
      get() {
        return this.$store.getters.customModels;
      },
      set(models) {
        this.$store.commit('setCustomModels', models);
      }
    },

    totalActiveModels: {
      get() {
        return this.$store.getters.totalActiveModels;
      },
      set(count) {
        this.$store.commit('setTotalActiveModels', count);
      }
    },

    totalActiveModelsDisplay: {
      get() {
        return this.$store.getters.totalActiveModelsDisplay;
      },
      set(count) {
        this.$store.commit('setTotalActiveModelsDisplay', count);
      }
    },

    totalTrainingModelsDisplay: {
      get() {
        return this.$store.getters.totalTrainingModelsDisplay;
      },
      set(count) {
        this.$store.commit('setTotalTrainingModelsDisplay', count);
      }
    },
  },

  methods: {
    async getMoreModels(offset, nameFilter, reset = false) {
      nameFilter = nameFilter || null;
      if (reset) {
        offset = 0;
      }
      const prevTotal = this.totalActiveModels;
      await this.getCustomModels(offset, 20, 'active', false, nameFilter);
      if (!nameFilter && prevTotal != this.totalActiveModels) {
        this.customModels = [];
        await this.getCustomModelsUntil(offset, nameFilter);
      }
    },

    async getCustomModelsUntil(until, nameFilter) {
      const prevTotal = this.totalActiveModels;
      await this.getCustomModels(this.numberOfCustomModels, 20, 'active', false, nameFilter)
      if (prevTotal != this.totalActiveModels) {
        this.customModels = [];
        await this.getCustomModelsUntil(until, nameFilter);
      } else if (this.numberOfCustomModels < until && this.totalActiveModels >= until) {
        await this.getCustomModelsUntil(until, nameFilter);
      }
    },

    async getCustomModels(
      offset = 0,
      limit = 20,
      status_filter = 'active',
      include_error = false,
      name_filter = null,
    ) {
      if (offset && offset >= this.totalActiveModels) {
        return;
      }
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await ModelAPI.get(
          offset,
          limit,
          name_filter,
          status_filter,
          this.sortDesc,
          true,
          include_error,
        );
        const sorted = response.data.map((model) => {
          model.versions = model.versions.sort((a, b) => b.version - a.version);
          return model;
        });
        if (offset > 0) {
          this.customModels = [...this.customModels, ...sorted];
        } else {
          this.customModels = sorted;
        }
        this[`total${this.statusMapping[status_filter]}Models`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getUsedModels() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await http.get(`model/${this.$route.params.id}`);
        this.usedModels = response.data;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async getCompatibleModels(model_id, version, labels) {
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await http.post(
          `model/${model_id}/version/${version}/compatible`, labels
        );
        this.compatibleModels = response.data;
        if (this.compatibleModels.length) {
          this.selectedNewModel = this.compatibleModels[0].id
          this.selectedNewModelVersion = this.compatibleModels[0].versions[0].version
        }
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getModelInfo(id) {
      try {
        const response = await http.get(
          `model/info/${id}/`,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getGenerativeModelInfo(id) {
      try {
        const response = await http.get(`model/generative/${id}`);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getModelName(id) {
      const info = await this.getModelInfo(id);
      return info ? info.name : '';
    },

    async getGenerativeModelName(id) {
      const info = await this.getGenerativeModelInfo(id);
      return info ? info.name : '';
    },
  }
};