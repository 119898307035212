/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/bundles/';

export class ClassifyBundlesAPI {
  static async get(offset = 0, limit = 20, sortDesc = true, name_filter = '') {
    return http.get(BASE_PATH, {
        params: {
          sort_desc: sortDesc,
          offset,
          limit,
          name_filter,
          status_filter: 'to_verify',
        },
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getBundle(id) {
    return http.get(`${BASE_PATH}${id}/`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async delete(id) {
    return http.delete(`${BASE_PATH}${id}/`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getPages(id) {
    return http.get(`${BASE_PATH}${id}/pages/`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getPageImage(bundle_id, page_id) {
    return http.get(`${BASE_PATH}image/${bundle_id}/pages/${page_id}`, {
        params: {
          thumbnail: false,
        },
        ignoreGlobalCatch: true,
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getPageThumbnail(bundle_id, page_id) {
    return http.get(`${BASE_PATH}image/${bundle_id}/pages/${page_id}`, {
        params: {
          thumbnail: true,
        },
        ignoreGlobalCatch: true,
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async updatePage(bundle_id, page_id, category_id) {
    return http.patch(`${BASE_PATH}${bundle_id}/pages/${page_id}`, {
        category_id,
      }, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async validate(bundle_id, data) {
    return http.post(`${BASE_PATH}${bundle_id}/validate/`, data, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
