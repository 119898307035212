<template>
  <div class="workflows-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
    />
    <template v-if="activeTab === 'input'">
      <v-radio-group
        id="inputRadio"
        v-model="activeInput"
        class="inline-middle mt-6"
        inline
      >
        <v-radio
          class="radio-box mb-0"
          value="mailboxes"
          :label="$t('mailboxes.title')"
        />
        <v-radio
          class="radio-box mb-0 ml-3"
          value="S3"
          :label="$t('workflows.s3.title')"
          disabled
        />
        <v-radio
          class="radio-box mb-0 ml-3"
          value="ftp"
          :label="$t('workflows.ftp.title')"
          disabled
        />
      </v-radio-group>
      <MailboxesTable
        v-if="activeInput === 'mailboxes' && classifyRunning"
        style="margin-top: -20px;"
      />
      <div
        v-else-if="!classifyRunning"
        class="d-flex flex-column align-center primary--text font-italic mt-5"
      >
        <v-icon
          class="mb-3"
          size="50"
        >
          fas fa-exclamation-triangle
        </v-icon>
        {{ $t('workflows.classify_not_running') }}
      </div>
    </template>
    <template v-if="activeTab === 'workflows'">
      <WorkflowsTable />
    </template>
    <template v-if="activeTab === 'jobs'">
      <JobsTable />
    </template>
  </div>
</template>

<script>
import MailboxesTable from '@/components/extract/views/Workflows/Mailboxes/MailboxesTable';
import PageTabs from '@/components/common/elements/General/PageTabs';
import WorkflowsTable from '@/components/extract/views/Workflows/WorkflowsTable';
import JobsTable from '@/components/extract/views/Workflows/JobsTable';

export default {
  name: 'WorkflowsView',

  components: {
    MailboxesTable,
    PageTabs,
    WorkflowsTable,
    JobsTable,
  },

  data() {
    return ({
      activeInput: 'mailboxes',
      activeTab: 'workflows',
      tabItems: [
        { name: 'workflows', title: this.$t('workflows.title')},
        { name: 'jobs', title: this.$t('workflows.jobs.title') },
        { name: 'input', title: this.$t('workflows.input') },
      ],
      classifyRunning: this.$store.getters.serviceStatus.classify.running,
    });
  },
};
</script>

<style lang="scss" scoped>
.workflows-view {
  height: fit-content;
}
</style>
