<template>
  <div class="settings-view page-padding py-7">
    <BreadcrumbComponent />
    <ProfileSettings />
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <EsSettings />
      </div>
    </div>

    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.extract_api') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.extract_api_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <ApiTokenSettings app="search" />
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import ProfileSettings from '@/components/common/elements/Settings/ProfileSettings';
import ApiTokenSettings from '@/components/common/elements/Settings/ApiTokenSettings';
import EsSettings from '@/components/search/elements/Settings/EsSettings';

export default {
  name: 'SearchSettingsView',

  components: {
    BreadcrumbComponent,
    ProfileSettings,
    ApiTokenSettings,
    EsSettings,
  },

  created() {
    this.setBreadcrumb();
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'SearchHome'} },
          { title: this.$t('menu.settings.title')},
        ]
      );
    },

  }
}
</script>
<style lang="scss">
.settings-view {
  .settings-body {
    width: 1024px;

    .settings-left {
      width: 400px;
      padding-right: 30px;
    }

    .settings-right {
      width: 600px;

      .label {
        font-weight: bold;
        font-size: 0.9rem;
      }

      .text-field-label {
        margin-bottom: 3px;
      }

      .radio-group-label {
        margin-bottom: -12px;
      }
    }
  }
}
</style>
