<template>
  <v-row
    class="fill-height value-row ma-0"
    align="center"
    justify="center"
    :class="{
      'value-row__active': !corrected && (editingCurrent),
      'value-row--invalid__active': corrected && (editingCurrent),
    }"
  >
    <v-col
      cols="3"
      class="px-5 text-uppercase data-point-name"
    >
      <div
        class="right-gap-sm"
        style="margin-left: -10px"
      >
        {{ dataPoint.name }}
      </div>
    </v-col>
    <v-col
      cols="6"
      class="pa-0"
    >
      <div
        v-if="!editingCurrent"
        class="value-div pa-1"
      >
        <div v-if="dataPoint && value && (dataPoint.status === 'valid' || dataPoint.status === 'pending')">
          {{ value || 'N/A' }}
        </div>
        <div v-else-if="dataPoint && dataPoint.status === 'invalid'">
          {{ corrected || 'N/A' }}
          <div
            v-if="dataPoint"
            class="corrected-value"
          >
            {{ value || 'N/A' }}
          </div>
        </div>
        <div v-else>
          N/A
        </div>
      </div>
      <v-textarea
        v-else
        :ref="`input-${dataPoint.generative_extractor_id}`"
        v-model="newValue"
        @keyup.esc="$emit('cancelEdit')"
        @keydown.enter="verifyValue(newValue, true)"
      />
    </v-col>
    <v-col
      cols="3"
      class="px-0"
    >
      <div
        v-if="!editingCurrent"
        class="d-flex justify-center"
      >
        <v-icon
          color="white"
          class="verify-button mr-2"
          size="17"
          :class="{
            'verify-button__enabled': dataPoint && dataPoint.status === 'pending',
            'verify-button__disabled': dataPoint && dataPoint.status === 'valid',
            'verify-button__incorrect': dataPoint && dataPoint.status === 'invalid',
          }"
          @click="handleCorrection(dataPoint.generative_extractor_id)"
        >
          fas fa-times
        </v-icon>
        <v-icon
          color="white"
          class="verify-button ml-2"
          size="17"
          :class="{
            'verify-button__enabled': dataPoint && dataPoint.status === 'pending' || !dataPoint,
            'verify-button__disabled': dataPoint && dataPoint.status === 'invalid',
            'verify-button__correct': dataPoint && dataPoint.status === 'valid',
          }"
          @click="verifyValue((dataPoint && value) || 'N/A' )"
        >
          fas fa-check
        </v-icon>
      </div>
      <div
        v-else
        class="d-flex justify-center"
      >
        <v-btn
          color="primary"
          style="width: 88px"
          @click="verifyValue(newValue, true)"
          rounded
        >
          OK
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import format_mixin from "@/mixins/format";

export default {
  name: 'GenerativeValidator',

  mixins: [format_mixin],

  data() {
    return ({
      newValue: '',
      renderKey: 1,
    });
  },

  computed: {
    value: {
      get() {
        return this.dataPoint.value;
      },
    },
    corrected() {
      this.renderKey;
      if (this.dataPoint && this.dataPoint.status === 'invalid') {
        return this.dataPoint.valid_value;
      }
      return null;
    },
    editingCurrent: {
      get() {
        return this.dataPoint.name === this.editing;
      },
      set() {
        return this.editingCurrent;
      }
    },
  },

  methods: {
    handleCorrection(id) {
      if (this.dataPoint.status === 'invalid') {
        this.$emit('cancelGenerativeVerification', this.dataPoint);
        return;
      }
      this.newValue = (this.value && (this.corrected || this.value)) || 'N/A';
      this.$emit('editValue', this.dataPoint);
      setTimeout(() => {
          this.$refs[`input-${id}`].focus();
        },
        100
      );
    },

    verifyValue(newValue, skipStatusVerification = false) {
      if (!skipStatusVerification && this.dataPoint.status === 'valid') {
        this.$emit('cancelGenerativeVerification', this.dataPoint);
        return;
      }
      newValue = ['N/A', ''].includes(newValue) ? null : newValue;
      let toVerify = { ...this.dataPoint };
      if (newValue) {
        toVerify.value = {
          value: newValue,
        };
      } else {
        toVerify.value = null;
      }
      this.$emit('verifyValue', toVerify);
      setTimeout(() => {
        this.renderKey++;
      }, 200);
    },
  },

  props: {
    dataPoint: {
      type: Object,
      required: true,
    },
    editing: {
      type: String,
      required: true,
    },
  },

  emits: [
    'cancelEdit',
    'cancelGenerativeVerification',
    'editValue',
    'verifyValue',
  ],
}
</script>
<style lang="scss" scoped>
.value-row {
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid transparent;
  &:not(.value-row:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
  }
  &__active {
    padding-bottom: 8px !important;
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }
  &--invalid__active {
    box-sizing: border-box;
    padding: 8px;
    padding-bottom: 8px !important;
    border: 2px solid rgb(var(--v-theme-error)) !important;
  }
}
.data-point-name {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}

.value-div {
  font-size: 14px;
  line-height: 1.5rem;
}

.value-input {
  resize: none;
  width: 100%;
  border-radius: 5px;
  &:focus {
    outline: none;
    background-color: #eee;
  }
}

.verify-button {
  cursor: pointer;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;

  &__correct {
    background-color: rgb(var(--v-theme-success)) !important;
    cursor: default;
  }

  &__incorrect {
    background-color: rgb(var(--v-theme-error)) !important;
    cursor: default;
  }

  &__enabled {
    background-color: #555 !important;
  }

  &__disabled {
    background-color: #ddd !important;
  }
}

.corrected-value {
  font-size: 12px;
  color: #aaa;
  text-decoration: line-through;
}

</style>
