<template>
  <div class="files-table">
    <TableActions
      type="correctionDocs"
      :number-of-selected="selected.length"
      :edit-condition="false"
      :delete-condition="true"
      @delete-click="deleteDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <v-card class="elevation-6 pa-0">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col cols="6">
            {{ $t('datatable.header.docName') }}
          </v-col>
          <v-col cols="2">
            {{ $t('status') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.addDate') }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="bundles.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in bundles"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="bundles.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>
          {{ $t('docTypes.no_results') }}
        </i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in bundles"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="handleSelect"
            />
          </v-col>
          <v-col
            cols="6"
            @click="goToBundle(item)"
          >
            <ItemName
              :key="item.id"
              style="z-index: 202"
              type="file"
              name-field="filename"
              :item="item"
              :editing-allowed="false"
            />
          </v-col>
          <v-col cols="2">
            {{ $t(`workflows.split.bundle_status.${item.status}`) }}
          </v-col>
          <v-col cols="2">
            <small class="gray--text">
              {{ formatDate(item.created_on) }}
            </small>
          </v-col>
        </v-row>
      </v-container>
    </v-card>          
    <TableFooter
      v-if="totalBundles > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalBundles / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.delete_file')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteBundles"
      @close="deleteDialog = false"
    />
    <ProgressDialog
      v-model="progressDialog"
      :all-delete="allDelete"
      :current-delete="currentDelete"
    />
  </div>
</template>
<script>
  import _ from 'lodash';
  import { ClassifyBundlesAPI } from '@/API/classify/ClassifyBundlesAPI';
  import ItemName from '@/components/common/elements/General/ItemName';
  import DeleteDialog from '@/components/common/elements/Tables/DeleteDialog';
  import ProgressDialog from '@/components/common/elements/Tables/ProgressDialog';
  import SortButton from '@/components/common/elements/Tables/SortButton';
  import TableActions from '@/components/common/elements/Tables/TableActions';
  import TableFooter from '@/components/common/elements/Tables/TableFooter';

  import format_mixin from '@/mixins/format.js';

  export default {
    name: 'BundlesTable',

    mixins: [format_mixin],

    components: {
      DeleteDialog,
      ItemName,
      ProgressDialog,
      SortButton,
      TableActions,
      TableFooter,
    },

    data() {
      return {
        allDelete: 0,
        currentDelete: 0,
        allSelected: false,
        bundles: [],
        currentPage: 1,
        itemsPerPage: 20,
        loading: false,
        deleteDialog: false,
        progressDialog: false,
        sortDesc: true,
        trimmedFilter: '',
      };
    },

    computed: {
      selected() {
        if (this.bundles.length > 0) {
          return this.bundles.filter(item => item.selected);
        }
        return [];
      },
    },

    watch: {
      trimmedFilter: _.debounce(
        async function() {
          this.currentPage = 1;
          await this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
        }, 300
      ),

      currentPage() {
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },

      itemsPerPage() {
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },

      sortDesc() {
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },
    },

    created() {
      this.getBundles();
    },

    methods: {
      async getBundles(offset = 0, limit = 20, sortDesc = true, filter = '') {
        try {
          this.loading = true;
          const response = await ClassifyBundlesAPI.get(
            offset,
            limit,
            sortDesc,
            filter,
          );
          this.bundles = response.data;
          this.$emit('update:totalBundles', parseInt(response.headers['x-total-count'], 10));
        } catch (error) {
          console.error(error);
          this.$store.commit('setSnackbar', true);
        } finally {
          this.loading = false;
        }
      },

      goToBundle(bundle) {
        this.$router.push({
          name: 'PageCorrection',
          params: {
            id: bundle.id,
          },
          meta: {
            bundle,
          }
        });
      },

      handleSelect() {
        this.allSelected = this.bundles.every(b => b.selected);
      },

      toggleSelectAll() {
        this.bundles = this.bundles.map(b => {
          b.selected = this.allSelected;
          return b;
        });
      },

      async deleteBundles() {
        const total = this.selected.length;
        this.deleteDialog = false;
        this.allDelete = total;
        this.progressDialog = true;
        for (let i = 0; i < total; i++) {
          this.currentDelete = i + 1;
          await ClassifyBundlesAPI.delete(this.selected[i].id);
        }
        this.$emit('update:totalBundles' ,this.totalBundles - total);
        this.progressDialog = false;
        await this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
        const lastPage = Math.max(1, Math.ceil(this.totalFiles / this.itemsPerPage));
        this.currentPage = Math.min(this.currentPage, lastPage);
        this.allSelected = false;
        this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
        this.$store.commit('setSuccessSnackbar', true);
      },

      resetCurrentPage() {
        this.currentPage = 1;
        this.allSelected = false;
        this.bundles = this.bundles.map(b => {
          b.selected = false;
          return b;
        });
      },
    },

    props: {
      totalBundles: {
        type: Number,
        default: 0,
      },
    },

    emits: ['update:totalBundles'],
  };
</script>
<style lang="scss" scoped>

</style>