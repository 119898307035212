<template>
  <div class="deleted-datasets top-gap-lg">
    <TableActions
      type="deletedDatasets"
      :number-of-selected="selected.length"
      @delete-click="deleteDialog = true"
      @delete-all-click="deleteAllDialog = true"
      @reinstate-click="handleReinstateButton"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <v-card class="elevation-6 pa-0 top-gap-sm">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              style="margin-top: -16px"
              class="inline-middle"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col cols="6">
            {{ $t('models.dataset') }}
          </v-col>
          <v-col cols="5">
            {{ $t("recycleBin.removed_on") }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="paginated.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginated"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginated.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in paginated"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="handleSelect"
            />
          </v-col>
          <v-col cols="6">
            <ItemName
              :key="item.id"
              :item="item"
              :clickable="false"
              :editing-allowed="false"
            />
          </v-col>
          <v-col
            v-if="config"
            cols="5"
          >
            {{ getRemovedInDays(item.deleted_on) }}
            {{ getRemovedInDays(item.deleted_on) > 1 ? $t('days') : $t('day') }}
          </v-col>
          <v-col
            v-else
            cols="5"
          />
        </v-row>
      </v-container>
    </v-card>
    <TableFooter
      v-if="total > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(total / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('recycleBin.delete_dataset_title')"
      :message="$t('recycleBin.delete_dataset_confirm')"
      @confirm="finalDeleteDataset"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteAllDialog"
      :title="$t('recycleBin.delete_dataset_all_title')"
      :message="$t('recycleBin.delete_dataset_all_confirm')"
      @confirm="finalDeleteAllDataset"
      @close="deleteAllDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import table_mixin from '@/mixins/table.js';
import format_mixin from '@/mixins/format.js';
import ItemName from '@/components/common/elements/General/ItemName';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import SortButton from '@/components/common/elements/Tables/SortButton';

export default {
  name: 'DeletedDatasets',

  mixins: [table_mixin, format_mixin],

  components: {
    TableActions,
    TableFooter,
    ItemName,
    DeleteDialog,
    SortButton,
  },

  data() {
    return ({
      sortDesc: true,
      deleteAllDialog: false,
    })
  },

  watch: {
    sortDesc() {
      this.getDeletedDatasets();
    },

    total(total) {
      if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDeletedDatasets();
    },

    currentPage(page) {
      this.handleCurrentPageChange(page);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDeletedDatasets();
      }, 300
    ),
  },

  async created() {
    await this.getDeletedDatasets();
  },

  methods: {
    async handleReinstateButton() {
      await Promise.all(this.selected.map(async ds => {
        try {
          return await http.put(`dataset/restore/${ds.id}/`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.datasets_restored_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
      this.finishDeletion();
    },

    handleCurrentPageChange(page) {
      this.allSelected = false;
      this.paginated.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getDeletedDatasets((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    async getDeletedDatasets(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const response = await DatasetAPI.get(
          offset,
          limit,
          this.trimmedFilter || '',
          'deleted',
          this.sortDesc,
          this.external,
          this.external,
          this.external ? this.$route.params.token : null
        );
        this.paginated = response.data;
        this.total = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async finalDeleteAllDataset() {
      try {
        await http.delete(`dataset/empty_bin/`);
        this.paginated = [];
        this.currentPage = 1;
        this.allSelected = false;
        this.deleteAllDialog = false;
        await this.$store.commit(
          'setSuccessMessage', this.$t('models.deleted_dataset_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('changeTotal', 0);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    async finalDeleteDataset() {
      await Promise.all(this.selected.map(async item => {
        try {
          return await http.delete(`dataset/${item.id}/?soft=false`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_dataset_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getDeletedDatasets();
      const lastPage = Math.max(1, Math.ceil(this.total / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.handleCurrentPageChange(this.currentPage);
      this.deleteDialog = false;
    },

    getRemovedInDays(removal_date) {
      removal_date = new Date(removal_date);
      if (this.config) {
        const today = new Date();
        const difference = this.datediff(
          today,
          this.addDays(removal_date, this.config.delete_retention_days),
        );
        if (difference < 0) {
          return 0;
        }
        return difference;
      }
      return 0;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  emits: ['changeTotal'],
}
</script>
