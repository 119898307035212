<template>
  <v-slider
    v-model="internalValue"
    class="align-center font-weight-bold"
    :max="max"
    :min="min"
    :label="label"
    @update:model-value="updateValue"
  >
    <template #append>
      <v-text-field
        v-model="internalValue"
        class="mt-0 pt-0 ml-3"
        type="number"
        style="width: 60px"
        variant="outlined"
        color="primary"
        density="compact"
        @change="updateValueFromTextField"
      />
    </template>
  </v-slider>
</template>

<script>
export default {
  name: 'NumberStepper',

  data() {
    return {
      internalValue: this.modelValue
    };
  },

  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    }
  },  

  methods: {
    updateValue(newValue) {
      this.internalValue = newValue;
      this.$emit('update:modelValue', newValue);
    },
    updateValueFromTextField() {
      const parsedValue = parseInt(this.internalValue, 10);
      if (!isNaN(parsedValue)) {
        this.internalValue = parsedValue;
        this.$emit('update:modelValue', parsedValue);
      }
    }
  },

  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 255
    },
    min: {
      type: Number,
      default: 255
    },
  },

  emits: ['update:modelValue'],
}
</script>
