<template>
  <strong
    class="notificationLink"
    @click="download(notification)"
  >
    {{ notification.args.filename }}
  </strong>
</template>

<script>
import { DatasetsUtils } from '@/utils/classes/Datasets/DatasetsUtils';

export default {
  name: 'NotificationLink',

  methods: {
    download(notification) {
      DatasetsUtils.download(notification.args.dataset_id);
    }
  },

  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notificationLink {
  text-decoration: underline;
  cursor: pointer;
}
</style>
