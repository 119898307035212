<template>
  <v-card class="data-points-review-table elevation-6 pa-0">
    <v-container
      class="pa-0 table-row-height"
      fluid
    >
      <v-row class="table-row table-row__header table-row-height">
        <v-col cols="8">
          <SortButton
            v-model="sortDesc"
            style="margin-top: -5px"
          />
          {{ $t('datatable.header.dataPointName') }}
        </v-col>
        <v-col style="text-align: center">
          {{ $t('display') }}
        </v-col>
      </v-row>
    </v-container>
    <div v-if="loading">
      <div v-if="pages[currentPage] && pages[currentPage].length === 0">
        <div
          v-for="item in 10"
          :key="item"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-for="item in pages[currentPage]"
        :key="item.id"
        class="table-row-height"
      >
        <v-skeleton-loader type="table-row" />
      </div>
    </div>
    <div
      v-else-if="!pages[currentPage] || pages[currentPage].length === 0"
      class="table-row fade-in table-row-height"
      style="text-align: center; padding-top: 15px;"
    >
      <i>{{ $t('docTypes.no_results') }}</i>
    </div>
    <v-container
      v-else
      class="pa-0"
      fluid
    >
      <v-row
        v-for="item in pages[currentPage]"
        :key="item.id"
        class="table-row fade-in table-row-height"
        style="border-bottom: 1px solid #eee !important"
      >
        <v-col cols="8">
          <ItemName
            :key="item.id"
            :ref="`name_${item.id}`"
            class="left-gap-lg"
            style="z-index: 202;"
            :item="item"
            :editing-allowed="false"
            :clickable="false"
          />
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          style="text-align: center"
        >
          <v-switch
            v-if="!disabled"
            v-model="item.display_in_review"
            style="display: inline-block; margin-top: -17px;"
            color="primary"
            @change="updateIntermediate(item)"
            inset
          />
          <v-switch
            v-else
            v-model="item.display_in_review"
            style="display: inline-block; margin-top: -17px;"
            color="primary"
            disabled
            inset
          />
        </v-col>
      </v-row>
    </v-container>
    <TableFooter
      v-if="filteredItems.length > 0"
      v-model="itemsPerPage"
      style="margin-top: 10px !important; margin-right: 20px !important; margin-bottom: 5px !important;"
      :current-page="currentPage"
      :total-pages="Object.keys(pages).length"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
  </v-card>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';


export default {
  name: 'DataPointsTable',

  components: {
    ItemName,
    TableFooter,
    SortButton,
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    sortDesc: {
      get() {
        return this.$store.getters.dpSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setDpSortDesc', sortDesc);
      },
    },

    itemsPerPage: {
      get() {
        return this.$store.getters.itemsPerPage['dataPoints'];
      },
      set(itemsPerPage) {
        this.$store.commit('setItemsPerPage', { key: 'dataPoints', itemsPerPage })
      },
    },

    currentPage: {
      get() {
        return this.$store.getters.currentPage['dataPoints'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', { key: 'dataPoints', currentPage });
      },
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.$emit('getItems', this.sortDesc);
    }
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    async updateIntermediate(dp) {
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/data_point/${dp.id}/`, { intermediate: !dp.display_in_review });
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      }, waitTime);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName'],
}
</script>
