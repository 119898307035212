<template>
  <div class="training-models-table">
    <v-card class="pa-0">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="6">
            {{ $t('models.in_training') }}
          </v-col>
          <v-col>
            {{ $t('models.version') }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div
          v-for="item in paginatedVersions"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedVersions.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('models.no_training') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in paginatedVersions"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col>
            <div class="ellipsis">
              {{ item.name }}
            </div>
          </v-col>
          <v-col cols="3">
            {{ item.version }}
          </v-col>
          <v-col cols="3">
            <v-btn
              color="primary"
              class="small-button"
              style="margin-top: -6px"
              @click="handleDeleteButton(item.id, item.version)"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-times
              </v-icon>
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <TableFooter
      v-if="totalTrainingModelsDisplay > itemsPerPage"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="totalPages"
      @change-page="page => currentPage = page"
      @reset-current-page="currentPage = 1"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title_training')"
      :message="$t('models.confirm_delete_training')"
      @confirm="deleteVersion"
      @close="deleteDialog = false; modelToDelete = -1; versionToDelete = -1"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';
import model_mixin from '@/mixins/model.js';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableFooter from '@/components/common/elements/Tables/TableFooter';

export default {
  name: 'TrainingModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableFooter,
    DeleteDialog,
  },

  data() {
    return ({
      itemsPerPage: 20,
      currentPage: 1,
      deleteDialog: false,
      modelToDelete: -1,
      versionToDelete: -1,
      loading: false,
      statusCheck: null,
      paginatedVersions: [],
    });
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalTrainingModelsDisplay / this.itemsPerPage);
    },
  },

  watch: {
    deleteDialog(on) {
      if (on) {
        clearTimeout(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    async itemsPerPage() {
      await this.getPendingModels();
      await this.getModelsCount('active');
      await this.modelsCountCheck();
    },

    async currentPage(page) {
      await this.getPendingModels((page - 1) * this.itemsPerPage, this.itemsPerPage);
      await this.getModelsCount('active');
      await this.modelsCountCheck();
    },
  },

  async mounted() {
    await this.getPendingModels();
    await this.modelsCountCheck();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async refreshModels() {
      // Changing this setup might result in models not loading after training
      setTimeout(async () => {
        await this.getPendingModels();
        await this.modelsCountCheck();
      }, 10);
      await this.modelsCountCheck();
    },

    async modelsCountCheck() {
      if (this.totalTrainingModelsDisplay > 0) {
        const prevCount = this.totalActiveModelsDisplay;
        await this.getModelsCount('active');
        if (this.totalActiveModelsDisplay != prevCount) {
          await this.getPendingModels((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage, false);
        }
        try {
          this.statusCheck = setTimeout(async () => {
            await this.modelsCountCheck();
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async getModelsCount(status) {
      try {
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );        
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getPendingModels(
      offset = 0,
      limit = this.itemsPerPage,
      loading = true,
    ) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ModelAPI.get(
          offset, limit, this.trimmedFilter || '', 'pending', true, false,
        );
        const modelVersions = [];
        response.data.forEach((model) => {
          model.versions.forEach((version) => {
            modelVersions.push({
              id: model.id,
              name: model.name,
              version: version.version,
            });
          });
        });
        this.paginatedVersions = modelVersions;
        this.totalTrainingModelsDisplay = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async deleteVersion() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.delete(`model/${this.modelToDelete}/version/${this.versionToDelete}`);
        this.finishDeletion();
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async finishDeletion() {
      this.deleteDialog = false;
      this.totalTrainingModelsDisplay--;
      await this.getPendingModels();
      this.currentPage = 1;
      this.modelToDelete = -1;
      this.versionToDelete = -1;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton(id, version) {
      this.deleteDialog = true;
      this.modelToDelete = id;
      this.versionToDelete = version;
    },
  }
}
</script>
