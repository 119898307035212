<template>
  <div class="edit-generative-model py-7">
    <div
      class="clickable bottom-gap"
      @click="$emit('back')"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('models.all_generative') }}
      </h4>
    </div>
    <HeaderName
      style="max-width: 700px"
      :item="item"
      @save="updateName"
    />
    <ItemDescription
      style="max-width: 700px"
      :item="item"
      @save="updateDescription"
    />
    <div class="label top-gap-sm">
      {{ $t('models.provider') }}
    </div>
    <v-select
      v-model="item.provider"
      style="margin-top: 16px; width: 300px !important;"
      class="inline-middle mt-0"
      variant="outlined"
      color="primary"
      density="compact"
      :items="Object.keys(providers)"
    />
    <div class="label top-gap-sm">
      {{ $t('models.version') }}
    </div>
    <v-select
      v-model="version"
      style="margin-top: 16px; width: 300px !important;"
      class="inline-middle mt-0"
      variant="outlined"
      color="primary"
      density="compact"
      :items="providers[item.provider]"
    />
    <div class="label top-gap-sm">
      {{ $t('models.prompt') }}
    </div>
    <v-textarea
      v-model="item.prompt"
      style="margin-top: 16px; max-width: 700px"
      @change="update++"
    />
    <v-btn
      class="top-gap"
      style="box-shadow: none"
      color="primary"
      :disabled="!valid"
      @click="updatePrompt"
      rounded
    >
      {{ $t('update') }}
    </v-btn>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ItemDescription from '@/components/common/elements/General/ItemDescription';

export default {
  name: 'EditGenerativeModel',

  components: {
    HeaderName,
    ItemDescription,
  },

  data() {
    return({
      providers: {
        OPENAI: ['gpt-3.5-turbo','gpt-4'],
        RECITAL: ["flan-large-invoice"],
      },
      update: 1,
    });
  },

  computed: {
    valid() {
      this.update;
      return this.item.prompt.trim() !== '';
    },
    version: {
      get() {
        if (this.providers[this.item.provider]) {
          return this.providers[this.item.provider][0];
        }
        return "";
      },
      set(version) {
        this.item.version = version;
      },
    },
  },

  methods: {
    async updateName(name) {
      await this.updateModel({ name });
      this.item.name = name;
    },

    async updateDescription(description) {
      await this.updateModel({ description });
      this.item.description = description;
    },

    async updatePrompt() {
      await this.updateModel({ prompt: this.item.prompt, version: this.item.version, provider: this.item.provider});
    },

    async updateModel(model){
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.put(`/model/generative/${this.item.id}`, model);
        this.$store.commit(
          'setSuccessMessage', this.$t('models.updated_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },
  },

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  emits: ['back'],
}
</script>

<style scoped lang="scss">
.edit-generative-model {
  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
</style>
