<template>
  <div class="extraction-result">
    <div
      class="bottom-gap"
      style="text-align: right"
    >
      <v-btn
        class="save-button"
        style="box-shadow: none"
        color="primary"
        :disabled="!isValid"
        @click="$emit('save')"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <div v-if="saved">
      <hr class="divider-line">
      <div v-if="value.id !== -1">
        <h4 class="top-gap-sm left-gap">
          {{ $t('dataPoints.extracted_value') }}
        </h4>
        <div
          v-if="value.value"
          id="chips-container"
          class="top-gap-sm results-container"
        >
          <v-card
            class="dp-card pa-0 mt-4"
            tile
          >
            <DPValidator
              :data-point="{value: value, data_point_name: value.data_point_name, data_point_id: value.data_point_id}"
              :editing="editing"
              :active-datapoint="{}"
              :draggable="false"
              @hover="$emit('hover', value)"
              @de-highlight="$emit('hoverout')"
              @verify-value="(value) => {verifyValue(value, fileId); $emit('reloadValue')}"
              @edit-value="handleEditValue"
            />
          </v-card>
        </div>
        <div
          v-else
          class="top-gap"
          style="font-style: italic; padding-left: 20px; padding-right: 20px;"
        >
          {{ failMessage }}
        </div>
        <div
          class="clickable top-gap"
          style="text-align: right"
          @click="goToValidation"
        >
          <h4 class="inline-middle right-gap">
            {{ $t('dataPoints.to_validation') }}
          </h4>
          <v-icon
            class="inline-middle right-gap"
            color="primary"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
      <div v-else>
        <div
          v-if="values.length > 0"
          id="chips-container"
          class="top-gap-sm results-container"
          style="margin-top: 20px !important"
        >
          <div
            v-for="(subgroup, i) in values"
            :key="i"
          >
            <v-chip
              v-for="(subgroupValue, j) in filterValues(subgroup)"
              :key="j"
              class="right-gap-sm annotation-chip clickable"
              variant="outlined"
              :style="{
                'background-color': `${getColor(subgroup.index)}60 !important`,
                'border-color': `${getColor(subgroup.index)} !important`,
              }"
              hover
            >
              {{ subgroupValue.value }}
              <div
                class="stretch"
                @mouseover="$emit('hover', value)"
                @mousemove="$emit('hover', value)"
                @mouseenter="$emit('hover', value)"
                @mouseout="$emit('hoverout')"
              >
                <div class="annotation-chip-value ellipsis">
                  {{ value.value }}
                </div>
              </div>
            </v-chip>
          </div>
          <div
            v-if="allEmpty"
            class="top-gap"
            style="font-style: italic; padding-left: 20px; padding-right: 20px;"
          >
            {{ failMessage }}
          </div>
        </div>
        <div
          v-else
          class="top-gap"
          style="font-style: italic; padding-left: 20px; padding-right: 20px;"
        >
          {{ failMessage }}
        </div>
        <div
          class="clickable top-gap"
          style="text-align: right"
          @click="goToValidation"
        >
          <h4 class="inline-middle right-gap">
            {{ $t('dataPoints.to_validation') }}
          </h4>
          <v-icon
            class="inline-middle right-gap"
            color="primary"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validation_mixin from "@/mixins/validation";
import DPValidator from '@/components/extract/elements/Validation/DPValidator';

export default {
  name: 'ExtractionResult',

  mixins: [validation_mixin],

  components: {
    DPValidator,
  },

  data() {
    return ({
      saved: false,
      type: 'config',
    });
  },

  computed: {
    bottomContainerHeight() {
      if (this.value.id === -1 && this.values.length > 0) {
        return 320;
      }
      return 65;
    },
    allEmpty() {
      return this.values.every(
        (subgroup) => subgroup.values.every(
          (value) => !value.value
        )
      );
    }
  },

  methods: {
    goToValidation() {
      this.$router.push({
        name: "DocType",
        params: {
          id: this.$route.params.id,
          tab: 'validation',
        },
      });
    },

    getColor(i) {
      return this.$store.getters.annotationColors[i % 9];
    },

    filterValues(subgroup) {
      return subgroup.values.filter(value => value.value);
    }
  },

  props: {
    isValid: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      default: () => {return {id: -1}},
    },

    values: {
      type: Array,
      default: () => [],
    },

    failMessage: {
      type: String,
      required: true,
    },

    fileId: {
      type: Number,
      default: -1,
    },
  },

  emits: ['save', 'hover', 'hoverout', 'reloadValue'],
}
</script>

<style lang="scss" scoped>
.extraction-result {
  .save-button {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .annotation-chip-value {
    max-width: 270px;
    min-width: 100px;
    padding-left: 12px;
    padding-top: 3px;
    padding-right: 20px;
    color: black !important;
  }

  .annotation-chip {
    text-transform: none !important;
    color: rgba(0, 0, 0, 0);
    max-width: 270px !important;
    padding-right: 20px;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    border-color: rgb(var(--v-theme-primary)) !important;
    margin-bottom: 10px;

    &__none {
      color: black !important;
      border-color: #999 !important;
    }
    &__none:hover {
      color: black !important;
      border-color: #999 !important;
      background-color: #99999960;
    }
  }

  .results-container {
    padding-left: 20px;
  }

    .value-chip {
      margin-bottom: 10px;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
    }

    .dp-card {
      border: 1px solid #aaa;
      border-radius: 0;
    }

    h4 {
      color: rgb(var(--v-theme-primary));
      font-weight: 500;
    }
  }
</style>
