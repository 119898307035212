<template>
  <div class="orgs-table top-gap-lg">
    <TableActions
      type="organizations"
      :number-of-selected="selected.length"
      :delete-condition="selected.every(s => s.id != user.org_id)"
      @edit-click="editDialog = true"
      @delete-click="deleteDialog = true"
      @create-click="createDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <v-card class="elevation-6 pa-0 top-gap-sm">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col>
            {{ $t('forms.organization') }}
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            {{ $t('users.title') }}
          </v-col>
          <v-col>
            {{ $t('organizations.products') }}
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="paginatedOrgs.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginatedOrgs"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedOrgs.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in paginatedOrgs"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="handleSelect"
            />
          </v-col>
          <v-col>
            <ItemName
              style="z-index: 202"
              :item="item"
              :editing-allowed="!!(item.selected)"
              :clickable="false"
            />
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            {{ item.n_users }}
          </v-col>
          <v-col>
            <MaxWidthChip
              v-for="product in item.product.split('+')"
              :key="product"
              class="mb-0"
              style="text-transform: capitalize !important; margin-top: -5px"
              color="#502BFF"
              :text-array="[product]"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <TableFooter
      v-if="paginatedOrgs.length > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalOrgs / itemsPerPage)"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <CreateOrgDialog
      v-model="createDialog"
      @close="createDialog = false"
      @refresh="refreshOrgs"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('organizations.delete')"
      :message="$t('organizations.delete_confirmation')"
      @confirm="deleteOrgs"
      @close="deleteDialog = false"
    />
    <EditOrgDialog
      v-model="editDialog"
      :edited-org="editedOrg"
      @close="editDialog = false"
      @refresh="editRefresh"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import CreateOrgDialog from '@/components/common/elements/Organizations/CreateOrgDialog';
import EditOrgDialog from '@/components/common/elements/Organizations/EditOrgDialog';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

export default {
  name: 'OrgsTable',

  components: {
    SortButton,
    TableActions,
    TableFooter,
    ItemName,
    CreateOrgDialog,
    EditOrgDialog,
    DeleteDialog,
    MaxWidthChip,
  },

  data() {
    return ({
      sortDesc: true,
      trimmedFilter: '',
      editUser: -1,
      deleteDialog: false,
      editDialog: false,
      createDialog: false,
      itemsPerPage: 20,
      currentPage: 1,
      totalOrgs: 0,
      allSelected: false,
      loading: false,
      paginatedOrgs: [],
      forceCompute: Math.random(),
    })
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    editedOrg() {
      if (this.selected.length > 0) {
        return this.selected[0];
      }
      return {};
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.paginatedOrgs.length > 0) {
          return this.paginatedOrgs.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.getOrgs();
    },

    totalOrgs(total) {
      if (this.trimmedFilter === '') {
        this.$emit('totalChange', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getOrgs();
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedOrgs.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getOrgs((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getOrgs();
      }, 300
    ),
  },

  created() {
    this.getOrgs();
  },

  methods: {
    async deleteOrgs() {
      await Promise.all(this.selected.map(async org => {
        try {
         return await http.delete(`auth/api/v1/organizations/${org.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getOrgs();
      if (Math.ceil(this.totalOrgs / itemsPerPage) > this.currentPage ) {
        this.currentPage = currentPage;
      } else {
        this.currentPage = 1;
      }
      this.allSelected = false;
      this.selected.forEach(org => {
        org.selected = false;
      });
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('organizations.successDelete')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    editRefresh(newValues) {
      this.editedOrg.name = newValues.name;
      this.editedOrg.product = newValues.product;
      this.editedOrg.selected = false;
      this.allSelected = false;
      this.toggleSelectAll();
    },

    async refreshOrgs() {
      this.paginatedOrgs = [];
      await this.getOrgs();
      this.currentPage = 1;
      this.allSelected = false;
      this.toggleSelectAll();
    },

    async getOrgs(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const response = await OrgsAPI.getOrgs(
          limit, offset, this.trimmedFilter || '', 'id', this.sortDesc,
        );
        this.paginatedOrgs = response.data;
        this.totalOrgs = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const paginatedOrgs = this.paginatedOrgs.map(o => {
        o.selected = false;
        return o;
      });
      this.paginatedOrgs = [...paginatedOrgs];
    },

    handleSelect() {
      this.allSelected = this.paginatedOrgs.every(u => u.selected);
      this.forceCompute = Math.random();
    },

    toggleSelectAll() {
      this.paginatedOrgs.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },
  },

  emits: ['totalChange'],
}
</script>
