<template>
  <div
    class="d-flex flex-column justify-space-between menu-container noselect"
    :style="{width: delayedOpen ? 'auto' : '80px'}"
  >
    <div>
      <div
        class="recital-logo top-gap bottom-gap-lg"
        :class="{ 'recital-logo-small': !open }"
        @click="handleLogoClick"
      />
      <div
        v-if="product === 'extract'"
        style="color: white"
      >
        <div v-if="!open">
          <div
            v-for="(section, i) in menuGroups"
            :key="i"
          >
            <div
              v-if="user && section.authorize.includes(user.role)"
              class="menu__content"
              style="margin-bottom: 21px; margin-top: -4px;"
            >
              <v-tooltip :text="$t(section.translatedName)">
                <template #activator="{ props }">
                  <span
                    class="mx-0"
                    v-bind="props"
                  >
                    <v-icon
                      style="width: fit-content"
                      class="left-gap clickable"
                      :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
                      @click="handleSectionOpen(section)"
                    >
                      {{ section.icon }}
                    </v-icon>
                  </span>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div
          v-for="(section, i) in menuGroups"
          :key="i"
        >
          <div
            v-if="open && user && section.authorize.includes(user.role)"
            class="bottom-gap"
          >
            <div
              class="clickable bottom-gap-sm menu menu__content"
              @click="handleSectionOpen(section)"
            >
              <v-icon
                style="width: fit-content; margin-top: 0px;"
                :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
              >
                {{ section.icon }}
              </v-icon>
              <v-list-item-title
                v-if="delayedOpen"
                class="inline-middle"
                style="font-weight: bold !important; text-transform: uppercase;"
                :style="{ color: $route.path.startsWith(section.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
              >
                {{ $t(section.translatedName) }}
              </v-list-item-title>
              <span
                v-if="delayedOpen && section.items && section.items.length > 0"
                style="margin-top: -3px"
              >
                <v-icon
                  v-if="!section.open"
                  class="left-gap-sm"
                  size="11"
                  :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
                >
                  fas fa-chevron-right
                </v-icon>
                <v-icon
                  v-else
                  class="left-gap-sm"
                  size="11"
                  :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
                >
                  fas fa-chevron-down
                </v-icon>
              </span>
            </div>
            <div
              v-if="open && section.open"
              style="margin-top: -2px"
            >
              <div
                v-for="(item, j) in section.items"
                :key="j"
                class="left-gap"
              >
                <div v-if="user && item.authorize.includes(user.role) && (!item.needsService || $store.getters.serviceStatus[item.needsService].running)">
                  <v-list-item
                    v-if="!item.items"
                    class="clickable bottom-gap-sm menu"
                    :to="{ path: item.route }"
                  >
                    <v-list-item-title
                      class="inline-middle"
                      style="font-weight: bold !important"
                      :style="{ color: $route.path.startsWith(item.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                    >
                      {{ $t(item.translatedName) }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    class="clickable bottom-gap-sm menu"
                    @click="item.open = !item.open"
                  >
                    <v-list-item-title
                      class="inline-middle"
                      style="font-weight: bold !important"
                      :style="{ color: $route.path.startsWith(item.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                    >
                      {{ $t(item.translatedName) }}
                    </v-list-item-title>
                    <span
                      v-if="item.items && item.items.length > 0"
                      class="inline-middle"
                      style="margin-top: 1px"
                    >
                      <v-icon
                        v-if="!item.open"
                        class="left-gap-sm"
                        size="10"
                        :color="$route.path.startsWith(item.route) ? 'primary-lighten2' : 'white'"
                      >
                        fas fa-chevron-right
                      </v-icon>
                      <v-icon
                        v-else
                        class="left-gap-sm"
                        size="10"
                        :color="$route.path.startsWith(item.route) ? 'primary-lighten2' : 'white'"
                      >
                        fas fa-chevron-down
                      </v-icon>
                    </span>
                  </v-list-item>
                </div>
                <div
                  v-if="item.open && user && item.authorize.includes(user.role)"
                  class="left-gap"
                >
                  <v-list-item
                    v-for="(subItem, k) in item.items.filter(s => (!s.needsService || $store.getters.serviceStatus[s.needsService].running))"
                    :key="k"
                    class="menu mt-0 bottom-gap-sm"
                    :class="{ clickable: !subItem.disabled }"
                    :style="{ opacity: subItem.disabled ? 0.6 : 1 }"
                  >
                    <v-list-item-title
                      v-if="!subItem.disabled"
                      :style="{ color: $route.path.startsWith(subItem.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                      @click="$router.push(subItem.route)"
                    >
                      {{ $t(subItem.translatedName) }}
                    </v-list-item-title>
                    <div
                      v-else
                      class="menu-item"
                    >
                      {{ $t(subItem.translatedName) }}
                    </div>
                  </v-list-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <template v-for="(item, keyItem) in menuGroups">
          <v-list-item
            v-if="showItem(item, keyItem)"
            :key="keyItem"
            active-class="menu--active"
            class="menu"
            :to="{ path: item.route }"
            :style="{ 'margin-left': open ? '0' : '20px' }"
          >
            <div class="menu__content">
              <v-tooltip
                v-if="!open"
                :text="item.title"
              >
                <template #activator="{ props }">
                  <span
                    class="mx-0"
                    v-bind="props"
                  >
                    <MenuIcon
                      :active="!open"
                      :icon="item.icon"
                    />
                  </span>
                </template>
              </v-tooltip>
              <MenuIcon
                v-else
                :active="open"
                :icon="item.icon"
              />
              <Transition>
                <div
                  v-if="delayedOpen"
                  style="overflow: visible !important; white-space: nowrap;"
                >
                  <div style="position: relative; margin-top: 7px">
                    <v-list-item-title class="d-block">
                      {{ item.title }}
                    </v-list-item-title>
                    <div
                      v-if="item.coming_soon"
                      class="text-body-1 left-gap"
                      style="font-size: 0.7rem !important; margin-bottom: -12px"  
                    >
                      {{ $t('coming_soon') }}
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </v-list-item>
        </template>
      </div>
      <hr
        v-if="user && logoutButton && product !== 'extract'"
        class="mt-8"
        style="width: 30px"
        :style="{'margin-left': open ? '0' : '20px' }"
      >
      <v-list-item
        v-if="fullName"
        active-class="menu--active"
        class="menu mt-8"
        :to="`/${product}/settings/general`"
      >
        <div class="white--text menu__content">
          <div>
            <div class="d-flex flex-column">
              <v-list-item-title class="ml-0 ellipsis sidebar-user-info">
                <div
                  class="name-tag"
                  :class="{ 'name-tag-open': open }"
                >
                  <div v-if="open">
                    <div class="full-name ellipsis">
                      {{ fullName }}
                    </div>
                    <div
                      class="text-body-1"
                      style="font-size: 0.7rem !important; margin-top: -3px;"
                    >
                      {{ orgName }}
                    </div>
                  </div>
                  <v-tooltip v-else>
                    <template #activator="{ props }">
                      <div
                        class="initials"
                        v-bind="props"
                      >
                        {{ initials }}
                      </div>
                    </template>
                    <div>{{ fullName }}</div>
                    <small>{{ orgName }}</small>
                  </v-tooltip>
                </div>
              </v-list-item-title>
            </div>
          </div>
        </div>
      </v-list-item>

      <v-list-item
        v-if="user && notificationsButton && notificationsButton.authorize.includes(user.role)"
        class="menu mt-4"
        active-class="menu--active"
        :style="{'margin-left': open ? '4px' : '22px' }"
        :to="`/${product}/notifications`"
      >
        <div
          class="menu__content"
          style="margin-top: 9px"
        >
          <v-tooltip v-if="!open">
            <template #activator="{ props }">
              <span
                class="mx-0 d-flex justify-center"
                v-bind="props"
              >
                <v-badge
                  v-if="notifications.length"
                  color="red"
                  :content="notifications.length"
                  overlap
                >
                  <MenuIcon
                    :active="open"
                    :icon="notificationsButton.icon"
                  />
                </v-badge>
                <MenuIcon
                  v-else
                  :active="open"
                  :icon="notificationsButton.icon"
                />
              </span>
            </template>
            {{ notificationsButton.title }}
          </v-tooltip>
          <template v-else>
            <v-badge
              v-if="notifications.length"
              color="red"
              :content="notifications.length"
              overlap
            >
              <MenuIcon
                :active="open"
                :icon="notificationsButton.icon"
              />
            </v-badge>
            <MenuIcon
              v-else
              :active="open"
              :icon="notificationsButton.icon"
            />
          </template>
          <Transition>
            <div v-if="delayedOpen">
              <div class="d-flex flex-column">
                <v-list-item-title>{{ notificationsButton.title }}</v-list-item-title>
              </div>
            </div>
          </Transition>
        </div>
      </v-list-item>

      <v-list-item
        v-if="user && logoutButton"
        class="menu mt-3"
        active-class="menu--active"
        :style="{'margin-left': open ? '4px' : '22px' }"
        @click.prevent="$router.push({ name: 'logout' }); $emit('openMenu')"
      >
        <div class="menu__content">
          <v-tooltip v-if="!open">
            <template #activator="{ props }">
              <span
                class="mx-0 d-flex justify-center"
                style="margin-top: 4px;"
                v-bind="props"
              >
                <v-icon color="white">
                  {{ logoutButton.icon }}
                </v-icon>
              </span>
            </template>
            {{ logoutButton.title }}
          </v-tooltip>
          <MenuIcon
            v-else
            :active="open"
            :icon="logoutButton.icon"
          />
          <Transition>
            <div v-if="delayedOpen">
              <div class="d-flex flex-column">
                <v-list-item-title>{{ logoutButton.title }}</v-list-item-title>
              </div>
            </div>
          </Transition>
        </div>
      </v-list-item>
    </div>
    <div
      class="d-flex flex-column"
      style="white-space: nowrap;"
    >
      <v-list-item
        v-if="helpButton"
        class="menu mt-3 mb-4"
        target="_blank"
        :href="helpButton.route"
      >
        <div class="menu__content">
          <v-tooltip v-if="!open">
            <template #activator="{ props }">
              <span
                style="margin-left: 22px"
                v-bind="props"
              >
                <v-icon color="white">
                  {{ helpButton.icon }}
                </v-icon>
              </span>
            </template>
            {{ helpButton.title }}
          </v-tooltip>
          <MenuIcon
            v-else
            style="margin-left: 2px !important"
            :active="open"
            :icon="helpButton.icon"
          />
          <Transition>
            <div v-if="delayedOpen">
              <div class="d-flex flex-column">
                <v-list-item-title>{{ helpButton.title }}</v-list-item-title>
              </div>
            </div>
          </Transition>
        </div>
      </v-list-item>
      <div
        class="d-flex flex-column footer-section"
        :style="{
          opacity: open ? 1 : 0,
          'margin-left': open ? '0' : '20px',
        }"
      >
        <small>{{ $t('footer.copyright', { year: new Date().getFullYear() }) }}</small>
        <small>{{ $t('footer.version', { version: '1.0.3' }) }}</small>
        <a
          class="menu-link"
          href="#"
        >
          <small>{{ $t('footer.terms') }}</small>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { MenuClass } from '@/utils/MenuClass';
import { startCase } from 'lodash';

import MenuIcon from '@/components/common/elements/Navigation/MenuIcon';

export default {
  name: 'SideBar',

  components: {
    MenuIcon,
  },

  data() {
    return ({
      delayedOpen: true,
      menuTimeout: null,
    })
  },

  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },

    menuGroups: {
      get() {
        return this.$store.getters.menuGroups;
      },
      set(menu) {
        this.$store.commit('setMenuGroups', menu);
      }
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    fullName() {
      if (this.user && this.user.first_name) {
        return startCase(`${this.user.first_name} ${this.user.last_name}`);
      }
      return '';
    },

    orgName() {
      if (this.user && this.user.org_name) {
        return startCase(this.user.org_name);
      }
      return '';
    },

    initials() {
      if (this.user && this.user.first_name) {
        return startCase(this.user.first_name[0]);
      }
      return '';
    },

    notificationsButton() {
      this.user;
      if (['extract'].includes(this.product)) {
        return {
          icon: 'fas fa-bell',
          title: this.$t('menu.account.notifications.title'),
          route: '/extract/notifications',
          authorize: ['sysadmin', 'orgadmin'],
        };
      }
      return null;
    },

    logoutButton() {
      this.user;
      return {
        icon: 'fas fa-sign-out-alt',
        title: this.$t('menu.account.logout.title'),
        route: '/logout',
        authorize: ['sysadmin', 'orgadmin', 'basic'],
      };
    },

    helpButton() {
      this.user;
      if (['extract', 'classify'].includes(this.product)) {
        return {
          icon: 'fas fa-life-ring',
          title: this.$t('menu.help.title'),
          route: 'https://docs.recital.ai/products/',
          authorize: ['sysadmin', 'orgadmin', 'basic'],
        };
      }
      return null;
    },
  },

  watch: {
    open(open) {
      if (open) {
        this.menuTimeout = null;
        this.delayedOpen = true;
      } else {
        this.menuTimeout = setTimeout(() => {
          this.delayedOpen = false;
        }, 300);
      }
    },
  },

  created() {
    this.delayedOpen = this.open;
  },

  mounted() {
    this.menuGroups = MenuClass.buildMenuSidebarItems();
  },

  methods: {
    handleSectionOpen(section) {
      if (!section.items) {
        this.$router.push(section.route);
        return;
      }
      if (!this.open) {
        this.menuGroups.forEach(s => s.open = false);
        section.open = true;
        this.$emit('openMenu');
      } else {
        section.open = !section.open;
      }
    },

    showItem(item, keyItem) {
      const excludedFromMenu = [];
      if (!this.$store.getters.serviceStatus.workflows.running) {
        excludedFromMenu.push('workflows');
      }
      if (excludedFromMenu.includes(keyItem)) {
        return false;
      }
      if (this.user && item.authorize.includes(this.user.role)) {
        return true;
      }
      return false;
    },

    handleLogoClick() {
      if (this.product === 'extract') {
        this.$router.push('/extract/home');
      } else if (this.product === 'search') {
        this.$router.push('/search/home');
      } else if (this.product === 'classify') {
        this.$router.push('/classify/home');
      }
    }
  },

  props: {
    open: {
      type: Boolean,
      default: true,
    },

    product: {
      type: String,
      default: '',
    },
  },

  emits: ['openMenu'],
};
</script>

<style lang="scss" scoped>
.menu-container {
  height: 100%;
  padding-bottom: 2rem;
  padding-right: 15px;
  overflow: hidden;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.menu-link {
  color: white;
  text-decoration: none !important;
  line-height: 1rem;
  &::after {
    color: white;
  }
}

hr {
  background-color: white;
}

.recital-logo {
  margin-left: -8px;
  height: 33px;
  background-size: contain;
  background-image: url('../../../../assets/logo.svg');
  cursor: pointer;

  &-small {
    background-size: cover;
    width: 35px;
    margin-left: 12px;
  }
}

.initials-container {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 35px;
  padding-top: 30px;
  margin-bottom: 2px;
  margin-left: 18px;
  background-color: rgb(var(--v-theme-primary-lighten2)) !important;
}

.initials {
  color: rgb(var(--v-theme-primary-darken3)) !important;
  font-weight: bold;
  line-height: 35px;
  font-size: 1.2rem;
}

.name-tag {
  transition: width 0.3s;
  background-color: rgb(var(--v-theme-primary-lighten2)) !important;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  margin-left: 15px;
  color: rgb(var(--v-theme-primary-darken3)) !important;
}

.name-tag-open {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 3px;
  width: 170px;
  margin-left: 0px;
}

.footer-section {
  color: #ffffff88;
  font-size: 14px;
  padding-right: 10px;
  transition: opacity 0.2s;

  & a {
    color: #ffffff88;
  }
}

.menu {
  position: relative;
  z-index: 2;
  text-decoration: none !important;

  ::v-deep &:hover {
    .v-list-item-title,
    .v-icon {
      color: rgb(var(--v-theme-primary-lighten2)) !important;
    }

    .bin-icon {
      color: rgb(var(--v-theme-primary-darken3)) !important;
    }
  }

  &__content {
    display: flex;
    color: white;
  }

  ::v-deep .v-list-item {
    &__content,
    &__icon {
      padding: 0;
      margin: 0;
    }

    &__icon {
      color: rgb(var(--v-theme-primary));
    }
  }

  .v-list-item-title, .menu-item {
    font-family: 'Telegraf';
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(16);
    color: white;
    margin-left: 17px;
    margin-top: 3px;
  }

  &:not(:first-child) {
    margin-top: 21px;
  }

  &--active {
    .menu__content {
      position: relative;
      padding: 0 !important;
      background: none !important;

      ::v-deep {
        .v-icon.active {
          color: rgb(var(--v-theme-primary-lighten2)) !important;
        }
      }
    }
  }
}

.sidebar-user-info {
  max-width: 170px;
  text-align: center;
}
</style>
