/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = `auth/api/v1/notifications/`;

export class NotificationsAPI {
  static async get(app, codes, unseen = true, aggregate_per_day = false, limit = 100, offset = 0) {
    var  url = `${BASE_PATH}`;
    if (codes.length !== 0){
      const serializedCodes = codes.map(code => `codes=${encodeURIComponent(code)}`).join('&');
      url += `?${serializedCodes}`;
    }
    return http
      .get(BASE_PATH, {
        url,
        params: { unseen, app, aggregate_per_day, limit, offset },
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async markAllNotificationSeen() {
    return http
      .put(`${BASE_PATH}mark-all-seen/?app=extract`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
