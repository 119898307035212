<template>
  <input
    ref="uploader"
    class="d-none"
    type="file"
    :accept="$store.getters.acceptedFiles"
    @change="handleUpload"
    multiple
  >
</template>

<script>
import file_mixin from '@/mixins/file.js';

export default {
  name: 'FileInput',

  mixins: [file_mixin],

  methods: {
    click() {
      this.$refs.uploader.click();
    },

    handleUpload({ target: { files }}) {
      if (typeof files === 'object') {
        files = Object.values(files).map(item => item);
      }
      files = this.getValidFiles(files);
      this.$emit('change', files);
    }
  },

  emits: ['change'],
}
</script>