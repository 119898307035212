<template>
  <v-menu
    class="add-extractor-button"
    offset-y
  >
    <template #activator="{ props }">
      <v-btn
        class="primary--text"
        style="top: -1px; box-shadow: none"
        variant="outlined"
        :disabled="numberOfValidFiles === 0"
        v-bind="props"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-highlighter
        </v-icon>
        {{ $t('models.annotate') }}
        <v-icon
          size="16"
          end
        >
          fas fa-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in createItems"
        :key="index"
        class="dropdown-button"
        :style="{
          opacity: item.disabled ? 0.5 : 1,
          cursor: item.disabled ? 'not-allowed' : 'pointer',
        }"
        @click="item.action(item.disabled)"
      >
        <v-icon
          :size="item.iconSize"
          start
        >
          {{ item.icon }}
        </v-icon>
        {{ $t(`models.${item.name}`) }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AddExtractorButton',

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    createItems() {
      return [
        {
          name: 'manual_annotation',
          icon: 'fas fa-highlighter',
          iconSize: 17,
          action: this.annotate,
          disabled: false,
        },
        {
          name: 'preannotate',
          icon: 'fas fa-magic',
          iconSize: 17,
          action: this.preAnnotate,
          disabled: false,
        },
        {
          name: 'remove_preannotation',
          icon: 'fa fa-eraser',
          iconSize: 17,
          action: this.removePreAnnotation,
          disabled: !this.isPreAnnotated,
        },
      ];
    },
  },

  methods: {
    createFromModel(id) {
      this.$router.push({
        name: 'FromModel',
        params: { id },
      });
    },

    handleEditButton(id) {
      if (this.user.role === 'orgadmin') {
        this.$router.push({
          name: 'datapoints',
          params: { id },
        });
      }
    },

    preAnnotate(disabled) {
      if (!disabled) {
        this.$emit('preAnnotateClick');
      }
    },

    annotate(disabled) {
      if (!disabled) {
        this.$emit('annotateClick');
      }
    },

    removePreAnnotation(disabled) {
      if (!disabled) {
        this.$emit('removePreAnnotationClick');
      }
    },

    createGenerative() {
      this.$router.push({
        name: 'generative',
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },

  props: {
    isPreAnnotated: {
      type: Boolean,
      default: false,
    },

    numberOfValidFiles: {
      type: Number,
      default: 0,
    },
  },

  emits: ['preAnnotateClick', 'annotateClick', 'removePreAnnotationClick'],
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  color: rgb(var(--v-theme-primary)) !important;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.8rem !important;
  font-weight: 600;
}

.dropdown-button:hover {
  background-color: #f4f5f9;
}
</style>
