import { store } from '@/plugins/store';
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';

class DatasetsUtilsClass {

  static download_zip(dataset_id) {
    const token = AuthenticationUtils.getToken();

    let backend = store.getters.config.backends['extract'];

    if (backend == '/') {
      backend = window.location.href;
    }

    const url = new URL(backend);

    url.pathname = '/extract/api/v1/dataset/download/' + dataset_id;
    url.search = new URLSearchParams({token});

    return url;
  }

  static download(dataset_id) {
    window.location = this.download_zip(dataset_id);
  }

}

export const DatasetsUtils = DatasetsUtilsClass;
