/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'system_2/document_type/';

export class DocTypeAPI {
  static async get(
    limit,
    offset,
    nameFilter = null,
    status = null,
    hasDataPointsFilter = null,
    hasExtractionGroupsFilter = null,
    onlyGlobal = null,
    sortDesc = true,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          limit,
          offset,
          name_filter: nameFilter,
          has_data_points_filter: hasDataPointsFilter,
          has_extraction_groups_filter: hasExtractionGroupsFilter,
          only_global_document_types: onlyGlobal,
          status,
          sort_desc: sortDesc,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getDocType(id) {
    return http
      .get(`${BASE_PATH}${id}`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getDocTypeByName(name) {
    return http
      .get(BASE_PATH, {
        params: {
          name_filter: name.toLowerCase(),
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
    }
}
