/* jshint esversion: 6 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { store } from '@/plugins/store';
import { i18n } from '@/plugins/i18n/i18n';
import _ from 'lodash';

const locale = i18n.global;

const setErrorsMessages = (detail, errorsMessage) => {
  const nbLevel = 2;
  _.each(detail, row => {
    if (
      _.has(row, 'loc') &&
      row['loc'][0] === 'body' &&
      row['loc'].length > 1 &&
      _.has(row, 'msg')
    ) {
      const message = _.upperFirst(
        _.replace(locale.t(`form_error.${row['msg']}`), 'form_error.', '')
      );
      if (
        row['loc'].length > nbLevel &&
        _.has(errorsMessage, row['loc'][1]) &&
        _.has(errorsMessage[row['loc'][1]], row['loc'][nbLevel])
      ) {
        errorsMessage[row['loc'][1]][row['loc'][nbLevel]].push(message);
      } else if (_.has(errorsMessage, row['loc'][1])) {
        errorsMessage[row['loc'][1]].push(message);
      } else {
        // no message
      }
    }
  });
};

const declareCallbackError = ({ response }, errorsMessage = []) => {
  if (response && response.status === 413) {
    store.commit("setSnackbar", { type: "ERROR", message: locale.t("error.entity_too_large"), });
  }
  else if (response && response.data && _.isString(response.data.detail)) {
    store.commit("setSnackbar", {
      type: "ERROR",
      message: response.data.detail,
    });
  } else if (response && response.data && _.isArray(response.data.detail)) {
    setErrorsMessages(response.data.detail, errorsMessage);
    store.commit("setSnackbar", {
      type: "ERROR",
      message: locale.t("error.validation"),
    });
  } else {
    store.commit("setSnackbar", {
      type: "ERROR",
      message: locale.t("error.internal"),
    });
  }
};

export const callbackError = declareCallbackError;
