<template>
  <div class="models-table top-gap-lg">
    <TableActions
      type="models"
      :number-of-selected="selected.length"
      @edit-click="handleEdit"
      @delete-click="handleDeleteButton"
      @create-click="$emit('createClick')"
      @filter-change="(filter) => modelFilter = filter"
    />
    <v-card class="elevation-6 pa-0 top-gap-sm">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
              @change="toggleSelectAll"
            />
          </v-col>
          <v-col cols="2">
            {{ $t('dataPoints.model') }}
          </v-col>
          <v-col cols="2">
            {{ $t('models.version') }}
            <v-tooltip top>
              <template #activator="{ props }">
                <v-icon
                  class="info-icon pointer"
                  color="primary"
                  size="16"
                  v-bind="props"
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              {{ $t('models.visualize_version') }}
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            {{ $t('models.labels') }}
          </v-col>
          <v-col
            cols="4"
            class="d-flex justify-space-between"
          >
            <div>
              {{ $t('models.recall') }}
            </div>
            <div>
              {{ $t('models.precision') }}
            </div>
            <div style="margin-right: 3px">
              {{ $t('models.f_1') }}
            </div>
            <div style="margin-right: -25px">
              {{ $t('models.detailed_score') }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="loading">
        <div v-if="paginatedModels.length === 0">
          <div
            v-for="item in 10"
            :key="item"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-for="item in paginatedModels"
          :key="item.id"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-else-if="paginatedModels.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px;"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <div
          v-for="item in paginatedModels"
          :key="item.id"
        >
          <v-row
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee;"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap"
                style="margin-top: -17px"
                @change="handleSelect"
              />
            </v-col>
            <v-col cols="2">
              <div v-if="item.selectedVersion.status === 'error'">
                <v-tooltip
                  :key="renderKey"
                  bottom
                >
                  <template #activator="{ props }">
                    <v-icon
                      class="right-gap-sm"
                      style="top: -3px"
                      color="primary"
                      size="16"
                      v-bind="props"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  {{ $t('models.training_failed') }}
                </v-tooltip>
              </div>
              <ItemName
                :key="item.id"
                style="margin-top: -3px"
                :style="{ width: item.selectedVersion.status === 'error' ? '85%' : '100%' }"
                :item="item"
                :editing-allowed="true"
                :editing="editingModel === item.id"
                :clickable="false"
                @save-file-name="(id, newName) => saveModelName(id, newName)"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="item.selectedVersion"
                class="version-select inline-middle"
                variant="outlined"
                color="primary"
                density="compact"
                item-title="version"
                style="width: 80%; margin-top: -10px;"
                :items="item.versions"
                @update:model-value="(version) => handleVersionChange(item, version)"
                hide-details
              />
              <v-tooltip top>
                <template #activator="{ props }">
                  <v-icon
                    class="left-gap-sm clickable"
                    style="margin-top: -10px"
                    color="primary"
                    size="16"
                    v-bind="props"
                    @click="handleDeleteVersion(item)"
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                {{ $t('models.delete_version') }}
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <div
                v-if="item.selectedVersion.labels.length > 0"
                style="overflow: hidden; white-space: nowrap; margin-top: -4px"
              >
                <MaxWidthChip
                  class="mb-0"
                  color="#502BFF"
                  :text-array="[item.selectedVersion.labels[0]]"
                />
                <div
                  v-if="item.selectedVersion.labels.length > 1"
                  class="inline-middle"
                >
                  <MaxWidthChip
                    class="mb-0"
                    color="#502BFF"
                    :text-array="[`+${item.selectedVersion.labels.length - 1}`]"
                  />
                  <v-tooltip
                    v-if="expanded.length === 0 || expanded[0].id !== item.id"
                    color="#423F4F"
                    right
                  >
                    <template #activator="{ props }">
                      <v-icon
                        class="clickable"
                        size="16"
                        v-bind="props"
                        @click="expanded = [item]"
                      >
                        fas fa-chevron-right
                      </v-icon>
                    </template>
                    <span style="color: white">
                      {{ $t('show_all') }}
                    </span>
                  </v-tooltip>
                  <v-icon
                    v-else
                    class="clickable"
                    size="16"
                    @click="expanded = []"
                  >
                    fas fa-chevron-down
                  </v-icon>
                </div>
              </div>
              <div
                v-else
                style="margin-top: -4px"
              >
                <MaxWidthChip
                  color="#999999"
                  :text-array="[$tc('datatable.header.none', 2)]"
                />
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-space-between"
            >
              <div>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.recall
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.recall.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </div>
              <div>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.precision
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.precision.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </div>
              <div>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.f1
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.f1.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </div>
              <v-tooltip
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-btn
                    color="primary"
                    style="height: 30px; margin-top: -4px"
                    :disabled="!(
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.f1 &&
                      item.selectedVersion.training_stats.label_stats)
                    "
                    v-bind="props"
                    @click="handleScores(item)"
                    rounded
                  >
                    <v-icon size="16">
                      fas fa-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color: white">
                  {{ $t('show') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            v-if="expanded.length > 0 && expanded[0].id === item.id"
            class="table-row"
            style="background-color: rgb(var(--v-theme-grey-darken1))"
          >
            <v-col cols="auto" />
            <v-col cols="4" />
            <v-col
              cols="7"
              style="margin-left: 40px"
            >
              <MaxWidthChip
                v-for="(label, i) in item.selectedVersion.labels"
                :key="i"
                color="#502BFF"
                :text-array="[label]"
                show-full
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <TableFooter
      v-if="totalActiveModels > 0"
      v-model="itemsPerPage"
      :current-page="currentPage"
      :total-pages="totalPages"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title')"
      :message="$t('models.confirm_delete')"
      @confirm="deleteModel"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteVersionDialog"
      :title="$t('models.delete_version')"
      :message="versionDeleteMessage"
      @confirm="deleteModelVersion"
      @close="cancelDeleteVersion"
    />
    <v-dialog
      v-model="showScores"
      max-width="600"
    >
      <v-card
        v-if="labelScores.length > 0"
        class="dialog-card"
      >
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="4">
              {{ $t('models.labels') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.recall') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.precision') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.f_1') }}
            </v-col>
          </v-row>
        </v-container>
        <div
          v-if="labelScores.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px; opacity: 0.5"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in labelScores"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="4">
              {{ item.name }}
            </v-col>
            <v-col class="text-center">
              <span v-if="item.recall && item.recall.last >= 0">
                {{ Math.round(item.recall.best.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
            <v-col class="text-center">
              <span v-if="item.precision && item.precision.last >= 0">
                {{ Math.round(item.precision.last.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
            <v-col class="text-center">
              <span v-if="item.f1 && item.f1.last >= 0">
                {{ Math.round(item.f1.last.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex justify-end mt-5">
          <v-btn
            color="primary"
            @click="handleClose"
            rounded
          >
            {{ $t('close') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';
import model_mixin from '@/mixins/model.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import TableActions from '@/components/common/elements/Tables/TableActions';


export default {
  name: 'ModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableActions,
    ItemName,
    DeleteDialog,
    TableFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    return ({
      sortDesc: true,
      modelFilter: '',
      deleteDialog: false,
      deleteVersionDialog: false,
      modelToDelete: -1,
      versionToDelete: -1,
      versionDeleteMessage: '',
      itemsPerPage: 20,
      currentPage: 1,
      modelScores: null,
      showScores: false,
      allSelected: false,
      statusCheck: null,
      loading: false,
      paginatedModels: [],
      expanded: [],
      renderKey: 10,
      editingModel: -1,
    })
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalActiveModels / this.itemsPerPage);
    },

    trimmedFilter() {
      return this.modelFilter.trim().toLowerCase();
    },

    selected: {
      get() {
        if (this.paginatedModels.length > 0) {
          return this.paginatedModels.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    labelScores() {
      if (this.showScores) {
        const labels = Object.keys(this.modelScores.selectedVersion.training_stats.label_stats);
        return labels.reduce((res, label) =>
          [...res, { name: label, ...this.modelScores.selectedVersion.training_stats.label_stats[label] }],
        []);
      }
      return [];
    },
  },

  watch: {
    sortDesc() {
      this.getActiveModels();
    },

    totalActiveModels(total) {
      if (this.trimmedFilter === '') {
        this.totalActiveModelsDisplay = total;
      }
    },

    deleteDialog(on) {
      if (on) {
        clearTimeout(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    async itemsPerPage() {
      this.resetCurrentPage();
      await this.getActiveModels();
      await this.getModelsCount('pending');
      await this.modelsCountCheck();
    },

    async currentPage(page) {
      this.allSelected = false;
      this.customModels.forEach(item => {
        item.selected = false;
      });
      await this.getActiveModels((page - 1) * this.itemsPerPage, this.itemsPerPage);
      await this.getModelsCount('pending');
      await this.modelsCountCheck();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getActiveModels();
      }, 300
    ),
  },

  async mounted() {
    await this.getActiveModels();
    await this.getModelsCount('pending');
    await this.modelsCountCheck();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.customModels.forEach(m => {
        m.selected = false;
      });
    },

    async getActiveModels(offset = 0, limit = this.itemsPerPage, loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ModelAPI.get(
          offset, limit, this.trimmedFilter || '', 'active', this.sortDesc,
        );
        this.paginatedModels = response.data
          .map(item => {
            const latest = Math.max(...item.versions.map(v => v.version));
            return {
              ...item,
              versions: item.versions.sort((a, b) => b.version - a.version),
              selectedVersion: item.versions.find((v) => v.version === latest),
            };
          });
        this.totalActiveModels = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getModelsCount(status) {
      try {
        if (status === 'active') {
          this.loading = true;
        }
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async modelsCountCheck() {
      if (this.totalTrainingModelsDisplay > 0) {
        const prevCount = this.totalTrainingModelsDisplay;
        await this.getModelsCount('pending');
        if (this.totalTrainingModelsDisplay < prevCount) {
          await this.getActiveModels(this.itemsPerPage * (this.currentPage - 1), this.itemsPerPage, false);
        }
        try {
          this.statusCheck = setTimeout(async () => {
            await this.modelsCountCheck();
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      }
    },

    handleSelect() {
      this.allSelected = this.paginatedModels.every(f => f.selected);
      this.renderKey++;
    },

    handleVersionChange(model, newVersion) {
      model.selectedVersion = model.versions.find(v => v.version === newVersion);
    },

    handleDeleteVersion(model) {
      this.modelToDelete = model.id;
      this.versionToDelete = model.selectedVersion.version;
      this.versionDeleteMessage = this.$tc('models.confirm_delete_version', model.versions.length === 1 ? 1 : 2);
      this.deleteVersionDialog = true;
    },

    cancelDeleteVersion() {
      this.modelToDelete = -1;
      this.versionToDelete = -1;
      this.versionDeleteMessage = '';
      this.deleteVersionDialog = false;
    },

    toggleSelectAll() {
      this.paginatedModels.forEach(item => {
        item.selected = this.allSelected;
      });
      this.renderKey++;
    },

    async deleteModel() {
      await Promise.all(this.selected.map(async model => {
        try {
          return await http.delete(`model/${model.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async deleteModelVersion() {
      try {
        if (this.modelToDelete > 0 && this.versionToDelete > 0) {
          await http.delete(`model/${this.modelToDelete}/version/${this.versionToDelete}`);
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
      this.finishVersionDeletion();
    },

    async finishDeletion() {
      this.modelFilter = '';
      await this.getActiveModels();
      this.currentPage = 1;
      this.selected.forEach(model => {
        model.selected = false;
        this.customModels = [...this.customModels.filter(item => item.id !== model.id)];
      });
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    async finishVersionDeletion() {
      await this.getActiveModels();
      const model = this.paginatedModels.find(item => item.id === this.modelToDelete);
      model.versions = [...model.versions.filter(v => v.version !== this.versionToDelete)];
      if (model.versions.length > 0) {
        model.selectedVersion = model.versions[0];
      } else {
        this.customModels = [...this.customModels.filter(item => item.id !== model.id)];
      }
      this.deleteVersionDialog = false;
      this.versionToDelete = -1;
      this.modelToDelete = -1;
      const success_message = model.versions.length > 0 ?
        this.$t('models.version_deleted_message') :
        this.$t('models.deleted_message');
      await this.$store.commit(
        'setSuccessMessage', success_message
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleScores(model) {
      this.modelScores = model;
      this.showScores = true;
    },

    handleClose() {
      this.showScores = false;
      this.modelScores = null;
    },

    handleEdit(){
      this.editingModel = this.selected[0].id;
    },

    async saveModelName(id, newName){
      if (newName !== ''){
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`/model/${id}/`,{ name: newName});
          await this.getActiveModels();
          this.$store.commit(
            'setSuccessMessage', this.$t('models.updated_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
        finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    }
  },

  emits: ['createClick'],
}
</script>
