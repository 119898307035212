<template>
  <div class="search-page d-flex">
    <div class="filters-left right-gap-lg">
      <v-card
        v-if="loggedInUser.role === 'sysadmin'"
        class="filters-left elevation-6 bottom-gap"
      >
        <ChevronButton
          v-model="orgsOpen"
          class="filter-chevron"
        />
        <h4>{{ $t('search.filter_by_org') }}</h4>
        <div
          v-if="orgsOpen"
          class="top-gap"
        >
          <OrgSelect
            :selected="$store.getters.searchOrg"
            @selected-changed="(id) => { $store.commit('setSearchOrg', id) }"
            short
          />
        </div>
      </v-card>

      <v-card class="filters-left elevation-6 bottom-gap">
        <ChevronButton
          v-model="searchFilters.foldersOpen"
          class="filter-chevron"
        />
        <h4>{{ $t('search.filter_by_folders') }}</h4>
        <div
          v-if="searchFilters.foldersOpen"
          class="top-gap"
          style="overflow-x: auto;"
        >
          <div
            v-if="!folders.length"
            style="font-style: italic"
          >
            {{ $t('search.home.no_folders_message') }}
          </div>
          <div
            v-else
            style="width: fit-content"
          >
            <FolderNode
              v-for="folder in folders"
              :key="folder.id"
              class="bottom-gap-sm"
              :folder="folder"
              @folder-filter-changed="startSearch"
            />
          </div>
        </div>
      </v-card>
    </div>
    <div style="width: 50vw">
      <div class="d-flex">
        <v-text-field
          v-model="$store.state.searchString"
          class="mt-0 right-gap"
          style="width: calc(50vw - 100px)"
          clear-icon="fas fa-times"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('search.placeholder')"
          :aria-placeholder="$t('search.placeholder')"
          :disabled="searchLoading"
          @keyup.enter="startSearch"
          clearable
        />
        <v-btn
          style="margin-top: 3px; height: 40px;"
          color="primary"
          @click="startSearch"
          rounded
        >
          <v-icon>fas fa-search</v-icon>
        </v-btn>
      </div>
      <div
        v-if="!startedSearch"
        class="search-files-message-container"
      >
        <div class="search-files-message inline-middle">
          <h1 class="text-h1">
            {{ $t('welcome.hello') }}
          </h1>
          <div class="top-gap">
            <h3>
              {{ $t('search.home.you_currently_have') }}
            </h3>
            <span
              v-if="searchTotalFiles"
            >
              <h1>
                {{ searchTotalFiles }}
              </h1>
              <h3 v-if="searchTotalFiles === 1">
                {{ $t('search.document') }}
              </h3>
              <h3 v-else>
                {{ $t('search.documents') }}
              </h3>
            </span>
            <h3 v-else>
              {{ $t('search.home.no_documents') }}
            </h3>
            <h3>
              .
            </h3>
          </div>
        </div>
      </div>
      <div
        v-else-if="searchLoading"
        class="search-files-message-container"
      >
        <v-icon
          color="primary"
          size="30"
        >
          fas fa-spinner fa-pulse
        </v-icon>
      </div>
      <div
        v-else-if="searchResults.chunks"
        class="top-gap-sm search-results-container"
      >
        {{ $t('search.home.showing') }}
        <div
          class="results-select clickable"
          :class="{ 'results-select-selected': resultSelect === 'chunks' }"
          @click="resultSelect = 'chunks'"
        >
          {{ searchResults.chunks.length }}
          <span v-if="searchResults.chunks.length === 1">
            {{ $t('search.home.result') }}
          </span>
          <span v-else>
            {{ $t('search.home.results') }}
          </span>
        </div>
        <span v-if="searchResults.documents.length > 0">
          {{ $t('search.home.in') }}
          <div
            class="results-select clickable"
            :class="{ 'results-select-selected': resultSelect === 'documents' }"
            @click="resultSelect = 'documents'"
          >
            {{ searchResults.documents.length }}
            <span v-if="searchResults.documents.length === 1">
              {{ $t('search.document') }}
            </span>
            <span v-else>
              {{ $t('search.documents') }}
            </span>
          </div>
        </span>
        <div
          v-if="resultSelect === 'chunks'"
          class="top-gap"
        >
          <v-card
            v-for="(chunk, i) in searchResults.chunks"
            :key="i"
            class="bottom-gap"
          >
            <div class="result-info bottom-gap">
              {{ $t('page') }} {{ chunk.meta.page }} {{ $t('search.home.of') }} 
              {{ chunk.meta.path.split('/').pop() }}
            </div>
            <div class="bottom-gap">
              <MaxWidthChip
                v-if="chunk.meta.Titles"
                class="mb-0"
                color="#502BFF"
                :text-array="[chunk.meta.Titles]"
              />
              <MaxWidthChip
                class="mb-0"
                color="#502BFF"
                :text-array="[chunk.meta.path.split('/').pop().split('.').pop().toUpperCase()]"
              />
            </div>
            {{ chunk.content }}
          </v-card>
        </div>
        <div
          v-if="resultSelect === 'documents'"
          class="top-gap"
        >
          <v-card
            v-for="(document, i) in searchResults.documents"
            :key="i"
            class="bottom-gap"
          >
            <div class="result-info bottom-gap">
              /{{ document.meta.path.split('/').slice(3).join('/') }}
            </div>
            <div>
              <MaxWidthChip
                v-if="document.meta.Titles"
                class="mb-0"
                color="#502BFF"
                :text-array="[document.meta.Titles]"
              />
              <MaxWidthChip
                class="mb-0"
                color="#502BFF"
                :text-array="[document.meta.path.split('/').pop().split('.').pop().toUpperCase()]"
              />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchAPI } from '@/API/search/SearchAPI';

import ChevronButton from "@/components/common/elements/General/ChevronButton";
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";
import FolderNode from "@/components/search/elements/Home/FolderNode";
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

export default {
  name: 'SearchPage',

  components: {
    ChevronButton,
    OrgSelect,
    FolderNode,
    MaxWidthChip,
  },

  data() {
    return ({
      orgsOpen: true,
      searchLoading: false,
      resultSelect: 'chunks',
    });
  },

  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    searchFilters() {
      return this.$store.getters.searchFilters;
    },

    folders() {
      return this.$store.getters.searchFolders;
    },

    startedSearch() {
      return this.$store.getters.startedSearch;
    },

    searchTotalFiles() {
      return this.$store.getters.searchTotalFiles;
    },

    searchResults() {
      return this.$store.getters.searchResults;
    },
  },

  methods: {
    async startSearch() {
      this.$store.commit('setStartedSearch', true);
      this.searchLoading = true;
      try {
        const results = await SearchAPI.search(this.$store.state.searchString);
        this.$store.commit('setSearchResults', results);
      } catch (err) {
        console.error(err);
      } finally {
        this.searchLoading = false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-page {

  h1 {
    display: inline-block;
  }

  h3 {
    display: inline-block;
  }

  .filters-left {
    width: 300px;
  }

  .filter-chevron {
    position: absolute;
    right: 28px;
    top: 28px;
  }

  .search-input-container {
    padding: 30px;
  }

  .search-files-message-container {
    margin-top: 100px;
    text-align: center;

    .search-files-message {
      text-align: left;
      padding-left: auto;
      padding-right: auto;
    }
  }

  .results-select {
    display: inline-block;
    font-weight: bold;
  }

  .results-select-selected {
    border-bottom: 3px solid rgb(var(--v-theme-primary));
  }

  .result-info {
    font-size: 0.9rem;
    color: rgb(var(--v-theme-grey-base));
    text-align: right;
  }
}
</style>
