<template>
  <div class="training-view d-flex top-gap-lg">
    <v-card class="training-stepper">
      <div>
        <NumberBullet
          num="1"
          :incomplete="step <= 1"
        />
        <div class="inline-middle stepper-title">
          <div>
            {{ type === 'extraction' ? $t('models.select_dataset') : $t('models.select_datasets') }}
          </div>
          <small v-if="step > 1 && type === 'extraction'">
            {{ selectedDatasetObject.name }}
          </small>
          <small v-if="step > 1 && type === 'classification'">
            {{ selectedDatasets.length }} {{ $t('models.categories') }}
          </small>
        </div>
      </div>
      <div class="stepper-content">
        <div v-if="step === 1">
          <div
            v-if="type === 'extraction'"
            style="height: 370px"
          >
            <div
              v-if="completeDatasets.length > 0"
              class="info-box bottom-gap"
            >
              <small>
                <v-icon
                  class="info-icon"
                  size="16"
                >
                  fas fa-info-circle
                </v-icon>
                {{ $t('models.select_complete_dataset', {number: $store.getters.minEntries}) }}
              </small>
            </div>
            <div v-else>
              <div style="font-size: 0.9rem; font-style: italic;">
                {{ $t('models.no_complete_dataset') }}
              </div>
              <v-btn
                class="top-gap bottom-gap"
                style="box-shadow: none"
                color="primary"
                @click="$router.push('/extract/studio/datasets')"
                rounded
              >
                {{ $t('models.configure_datasets') }}
              </v-btn>
            </div>
            <DatasetSelect
              v-if="completeDatasets.length > 0"
              :datasets="completeDatasets"
              :selected="selectedDataset"
              @selected-changed="(id) => {selectedDataset = id}"
              @get-more="getCompleteDatasets(numberOfCompleteDatasets)"
            />
            <v-btn
              class="top-gap"
              style="box-shadow: none"
              color="primary"
              :disabled="selectedDataset === -1"
              @click="goToStep(2)"
              rounded
            >
              <v-icon start>
                fas fa-check
              </v-icon>
              {{ $t('continue') }}
            </v-btn>
          </div>
          <div v-else>
            <div v-if="completeDatasets.length < 2">
              <div class="info-box bottom-gap">
                <small>
                  <div
                    class="inline-middle"
                    style="width: 40px"
                  >
                    <v-icon
                      class="info-icon"
                      size="16"
                    >
                      fas fa-info-circle
                    </v-icon>
                  </div>
                  <div
                    class="inline-middle"
                    style="width: calc(100% - 40px)"
                  >
                    {{ $t('models.not_enough_datasets', {number: $store.getters.classifyMinEntries}) }}
                  </div>
                </small>
              </div>
              <v-btn
                class="bottom-gap"
                style="box-shadow: none"
                color="primary"
                @click="$router.push('/extract/studio/datasets')"
                rounded
              >
                {{ $t('models.configure_datasets') }}
              </v-btn>
            </div>
            <div
              v-else
              style="margin-bottom: 200px"
            >
              <div class="info-box bottom-gap">
                <small>
                  <div
                    class="inline-middle"
                    style="width: 40px"
                  >
                    <v-icon
                      class="info-icon"
                      size="16"
                    >
                      fas fa-info-circle
                    </v-icon>
                  </div>
                  <div
                    class="inline-middle"
                    style="width: calc(100% - 40px)"
                  >
                    {{ $t('models.select_min_datasets') }}
                  </div>
                </small>
              </div>
              <div
                v-for="(_, i) in selectedDatasets"
                :key="i"
              >
                <div
                  class="label"
                  style="margin-bottom: 4px"
                >
                  {{ $t('models.category') }} {{ i + 1 }}
                </div>
                <DatasetSelect
                  class="bottom-gap-sm right-gap-sm"
                  style="display: inline-block !important"
                  :datasets="completeDatasets"
                  :selected="selectedDatasets[i]"
                  :disabled-datasets="selectedDatasets"
                  @selected-changed="(id) => {selectedDatasets[i] = id; selectedDatasets = [...selectedDatasets]}"
                  @get-more="getCompleteDatasets(numberOfCompleteDatasets)"
                />
                <v-icon
                  v-if="i > 1"
                  class="clickable"
                  style="margin-top: -12px"
                  size="16"
                  @click="selectedDatasets.splice(i, 1); selectedDatasets = [...selectedDatasets]"
                >
                  fas fa-times
                </v-icon>
              </div>
              <v-btn
                class="top-gap-sm small-button"
                style="box-shadow: none"
                color="primary"
                :disabled="selectedDatasets.length === completeDatasets.length"
                @click="selectedDatasets.push(-1)"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-plus
                </v-icon>
                {{ $t('models.add_category') }}
              </v-btn>
              <div
                class="top-gap-sm bottom-gap"
                style="font-size: 0.9rem"
              >
                {{ $t('models.total_docs_in_selected') }}
                <strong>{{ totalClassifierEntries }}</strong>
                <span v-if="totalClassifierEntries < 50">
                  - {{ $t('models.min_count') }}
                  <strong style="margin-right: 5px">
                    50
                  </strong>
                  <v-icon
                    style="top: -1px"
                    size="16"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </span>
                <v-icon
                  v-else
                  style="top: -1px; margin-left: 5px"
                  color="primary"
                  size="16"
                >
                  fas fa-check
                </v-icon>
              </div>
              <v-btn
                style="box-shadow: none; display: block;"
                color="primary"
                :disabled="selectedDatasets.includes(-1) || totalClassifierEntries < 50"
                @click="goToStep(2)"
                rounded
              >
                <v-icon start>
                  fas fa-check
                </v-icon>
                {{ $t('continue') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div>
        <NumberBullet
          num="2"
          :incomplete="step <= 2"
        />
        <div class="inline-middle stepper-title">
          <div>
            {{ $t('models.name') }}
          </div>
          <small v-if="step > 2">
            {{ modelName }}
          </small>
        </div>
      </div>
      <div class="stepper-content">
        <div v-if="step === 2">
          <v-text-field
            v-model="modelName"
            variant="outlined"
            color="primary"
            density="compact"
            :placeholder="$t('models.type_new_name')"
            :disabled="!writableName"
            @keydown.enter="goToStep(3)"
          />
          <div
            v-if="type === 'extraction'"
            class="separator-container top-gap bottom-gap"
          >
            <div class="or-separator">
              OR
            </div>
          </div>
          <div
            v-if="type === 'extraction'"
            class="model-select-container"
          >
            <ModelSelect
              :key="modelKey"
              :models="orgModels"
              :selected="selectedModel"
              :disabled="disableModels"
              @selected-changed="handleCustomModelClick"
              @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
            />
            <v-tooltip bottom>
              <template #activator="{ props }">
                <v-icon
                  class="clickable primary--text clear-icon"
                  size="16"
                  :disabled="disableModels"
                  v-bind="props"
                  @click="clearCustomModel"
                >
                  fas fa-eraser
                </v-icon>
              </template>
              {{ $t('models.clear_model') }}
            </v-tooltip>
          </div>
          <v-checkbox
            v-if="type === 'classification'"
            v-model="modelFeatures"
            value="content"
            color="primary"
            :label="$t('models.features.content')"
          />
          <v-btn
            class="top-gap"
            style="box-shadow: none"
            color="primary"
            :disabled="modelName.trim() === ''"
            @click="step = 3"
            rounded
          >
            <v-icon start>
              fas fa-check
            </v-icon>
            {{ $t('continue') }}
          </v-btn>
        </div>
      </div>
      <div>
        <NumberBullet
          num="3"
          :incomplete="step <= 3"
        />
        <div class="inline-middle stepper-title">
          {{ $t('models.train') }}
        </div>
      </div>
      <div
        class="stepper-content"
        style="border: none"
      >
        <div v-if="step === 3">
          <v-btn
            style="box-shadow: none"
            color="primary"
            @click="startTraining"
            rounded
          >
            <v-icon start>
              fas fa-flask
            </v-icon>
            {{ $t('models.launch_training') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <div style="width: 45%">
      <TrainingModelsTable
        v-if="type === 'extraction'"
        ref="trainingModels"
      />
      <TrainingClassifiersTable
        v-if="type === 'classification'"
        ref="trainingClassifiers"
      />
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { DatasetAPI } from '@/API/extract/DatasetAPI';

import format_mixin from '@/mixins/format.js';
import model_mixin from '@/mixins/model.js';

import TrainingModelsTable from '@/components/extract/elements/Training/TrainingModelsTable';
import TrainingClassifiersTable from "@/components/extract/elements/Training/TrainingClassifiersTable";
import DatasetSelect from "@/components/extract/elements/Datasets/DatasetSelect";
import ModelSelect from "@/components/extract/elements/Models/ModelSelect";
import NumberBullet from "@/components/common/elements/General/NumberBullet";

export default {
  name: 'TrainingView',

  mixins: [
    format_mixin,
    model_mixin,
  ],

  components: {
    DatasetSelect,
    ModelSelect,
    TrainingModelsTable,
    TrainingClassifiersTable,
    NumberBullet,
  },

  data() {
    return ({
      modelKey: 0,
      step: 1,
      selectedDataset: -1,
      selectedDatasetObject: {name: ''},
      completeDatasets: [],
      selectedDatasets: [-1, -1],
      selectedModel: 0,
      totalCompleteDatasets: 0,
      modelName: '',
      writableName: true,
      modelFeatures: [],
      modelVersions: [],
      newVersion: false,
      exportOn: false,
      modelNamingFailure: null,
    });
  },

  computed: {
    classifyReachable() {
      return this.$store.getters.serviceStatus.classify.running;
    },

    totalClassifierEntries() {
      let nb_files = 0;
      this.selectedDatasets.forEach(id => {
        const dataset = this.completeDatasets.find(ds => ds.id === id);
        if (dataset) {
          nb_files += dataset.nb_files;
        }
      });
      return nb_files;
    },

    disableModels() {
      return this.orgModels.length === 0;
    },

    orgModels() {
      return this.customModels.filter(model => model.org_id === this.user.org_id);
    },

    numberOfCustomModels() {
      return this.orgModels.length;
    },

    hasClassify() {
      return this.user && this.user.product.includes('classify');
    },

    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    numberOfCompleteDatasets() {
      return this.completeDatasets.length;
    }
  },

  watch: {
    async selectedDataset(newId, oldId) {
      if (newId != oldId) {
        this.$store.commit('setLoadingScreen', true);
        this.selectedDatasetObject = await this.getDataset(newId);
        this.$store.commit('setLoadingScreen', false);
      }
    }
  },

  async mounted() {
    await this.getCompleteDatasets();
    if (this.type === 'extraction') {
      this.getCustomModels();
      if (this.numberOfCompleteDatasets > 0) {
        if (this.datasetId) {
          this.selectedDataset = this.datasetId;
          this.step = 2;
        } else {
          this.selectedDataset = this.completeDatasets[0].id;
        }
      }
    }
  },

  methods: {
    async getCompleteDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalCompleteDatasets) {
        return;
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await DatasetAPI.get(
          offset, limit, '', 'complete',
        );
        if (offset > 0) {
          this.completeDatasets = [...this.completeDatasets, ...response.data];
        } else {
          this.completeDatasets = response.data;
        }
        this.totalCompleteDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async getDataset(id) {
      try {
        if (id === -1) {
          return {name: ''};
        }
        const response = await http.get(`dataset/${id}`);
        return response.data;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return {name: ''};
      }
    },

    reset() {
      if (this.type === 'extraction') {
        this.$refs.trainingModels.refreshModels();
      } else {
        this.$refs.trainingClassifiers.refreshModels();
      }
      this.step = 1;
      this.selectedDataset = -1;
      this.selectedDatasets = [-1, -1];
      this.modelName = '';
      this.selectedModel = 0;
      this.disableModels = false;
      this.modelKey++;
    },

    handleCustomModelClick(id) {
      if (id > 0) {
        const model = this.orgModels.find(item => item.id === id);
        this.modelName = model.name;
        this.newVersion = true;
        this.writableName = false;
      } else {
        this.modelName = '';
        this.newVersion = false;
        this.writableName = true;
      }
    },

    clearCustomModel() {
      this.modelName = '';
      this.modelVersions = [];
      this.selectedModel = 0;
      this.newVersion = false;
      this.writableName = true;
      this.modelKey++;
    },

    startTraining() {
      if (this.type === 'extraction') {
        this.exportDataset();
      } else {
        this.trainClassifier();
      }
    },

    async trainClassifier() {
      try {
        const params = new URLSearchParams();
        params.set('model_name', this.modelName.trim());
        if (this.modelFeatures && this.modelFeatures.length) {
          params.set('features', this.modelFeatures);
        }
        await http.post(`dataset/classify/train/?${params}`, this.selectedDatasets);
        this.totalTrainingModelsDisplay++;
        this.reset();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('models.being_trained')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        this.step = 2;
      }
    },

    async exportDataset() {
      try {
        await http.post(
          `dataset/${this.selectedDataset}/export/`, null,
          {
            params: {
              model_name: this.modelName.trim(),
              new_model_version: this.newVersion,
            },
          }
        );
        this.totalTrainingModelsDisplay++;
        this.reset();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('models.being_trained')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        if (this.modelNamingFailure) {
          clearTimeout(this.modelNamingFailure);
        }
        this.$store.commit("setErrorMessage", this.$t('models.already_exists'));
        this.$store.commit("setSnackbar", true);
        this.modelNamingFailure = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')), 5000
        );
        console.log(error);
        this.step = 2;
      }
    },

    async goToStep(step) {
      switch (step) {
        case 3:
          this.step = this.modelName.trim() === '' ? 2 : 3; return;
        default:
          this.step = step; return;
      }
    },
  },

  props: {
    type: {
      type: String,
      default: 'extraction',
    },

    datasetId: {
      type: Number,
      default: -1,
    },
  }
}
</script>

<style lang="scss" scoped>
.training-view {
  .training-stepper {
    width: 55%;
    padding-right: 30px;
    margin-right: 30px;

    .stepper-content {
      border-left: 1px solid rgb(var(--v-theme-grey));
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .stepper-title {
      height: 20px;
    }

    .info-box {
      background-color: rgb(var(--v-theme-primary-lighten2));
      border-radius: 6px;
      padding: 13px 24px;
      width: calc(100% - 5px);

      .info-icon {
        margin-right: 2px;
        top: -1px;
      }
    }

    .model-select-container {
      height: 300px;
      position: relative;
    }

    .clear-icon {
      position: absolute;
      top: 20px;
      left: 315px;
      transform: translateY(-50%);
    }

    .separator-container {
      position: relative;
      border-bottom: 2px solid #cacaca;
      color: #cacaca;
    }

    .or-separator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      font-size: small;
      font-style: italic;
      padding: 0 10px;
    }
  }
}
</style>
