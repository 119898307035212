<template>
  <div class="generative-models-table page-padding py-7">
    <CreateGenerativeModel
      v-if="create"
      @back="create = false; getGenerativeModels();"
    />
    <EditGenerativeModel
      v-else-if="editing"
      :item="editing"
      @back="editing = null; getGenerativeModels();"
    />
    <div v-else>
      <BreadcrumbComponent />
      <TableActions
        type="generative_models"
        :number-of-selected="selected.length"
        @edit-click="handleEdit"
        @delete-click="handleDeleteButton"
        @create-click="goToCreateModel"
        @filter-change="(filter) => modelFilter = filter"
      />
      <v-card class="elevation-6 pa-0 top-gap-sm">
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="auto">
              <SortButton v-model="sortDesc" />
              <v-checkbox
                v-model="allSelected"
                class="inline-middle"
                style="margin-top: -16px"
                @change="toggleSelectAll"
              />
            </v-col>
            <v-col cols="3">
              {{ $t('models.generative_model') }}
            </v-col>
            <v-col
              cols="4"
              class="ellipsis"
              :style="{'max-width': `calc(40vw - ${$store.getters.menuOpen ? '80px' : '0px'})`}"
            >
              {{ $t('models.prompt') }}
            </v-col>
            <v-col
              cols="2"
              class="ellipsis"
            >
              {{ $t('models.version') }}
            </v-col>
            <v-col
              cols="2"
              class="ellipsis"
            >
              {{ $t('models.provider') }}
            </v-col>
          </v-row>
        </v-container>
        <div v-if="loading">
          <div v-if="paginatedModels.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in paginatedModels"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="paginatedModels.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedModels"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap"
                style="margin-top: -15px"
                @change="handleSelect"
              />
            </v-col>
            <v-col cols="3">
              <ItemName
                :key="item.id"
                :item="item"
                :editing-allowed="true"
                :editing="editingModel === item.id"
                @save-file-name="(id, newName) => saveModelName(id, newName)"
                @name-click="editing = item"
              />
            </v-col>
            <v-col
              cols="4"
              class="ellipsis"
              :style="{'max-width': `calc(40vw - ${$store.getters.menuOpen ? '80px' : '0px'})`}"
            >
              {{ item.prompt }}
            </v-col>
            <v-col
              cols="2"
              class="ellipsis"
            >
              {{ item.version }}
            </v-col>
            <v-col
              cols="2"
              class="ellipsis"
            >
              {{ item.provider }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <TableFooter
        v-if="totalModels > 0"
        v-model="itemsPerPage"
        :current-page="currentPage"
        :total-pages="totalPages"
        @change-page="page => currentPage = page"
        @reset-current-page="resetCurrentPage"
      />
      <DeleteDialog
        v-model="deleteDialog"
        :title="$t('models.delete_title')"
        :message="$t('models.confirm_delete')"
        @confirm="deleteModel"
        @close="deleteDialog = false"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import model_mixin from '@/mixins/model.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import CreateGenerativeModel from '@/components/extract/views/Studio/CreateGenerativeModel';
import EditGenerativeModel from '@/components/extract/views/Studio/EditGenerativeModel';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";


export default {
  name: 'GenerativeModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableActions,
    SortButton,
    ItemName,
    CreateGenerativeModel,
    EditGenerativeModel,
    DeleteDialog,
    TableFooter,
    BreadcrumbComponent,
  },

  data() {
    return ({
      sortDesc: true,
      modelFilter: '',
      deleteDialog: false,
      itemsPerPage: 20,
      currentPage: 1,
      allSelected: false,
      loading: false,
      paginatedModels: [],
      create: false,
      editing: null,
      totalModels: 0,
      renderKey: 10,
      editingModel: -1,
    })
  },

  computed: {
    totalGenerativeModelsDisplay: {
      get() {
        return this.$store.getters.totalGenerativeModelsDisplay;
      },
      set(count) {
        this.$store.commit('setTotalGenerativeModelsDisplay', count);
      }
    },

    totalPages() {
      return Math.ceil(this.totalModels / this.itemsPerPage);
    },

    trimmedFilter() {
      return this.modelFilter.trim().toLowerCase();
    },

    selected: {
      get() {
        this.renderKey;
        if (this.paginatedModels.length > 0) {
          return this.paginatedModels.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.getGenerativeModels();
    },

    totalModels(total) {
      if (this.trimmedFilter === '') {
        this.totalGenerativeModelsDisplay = total;
      }
    },

    async itemsPerPage() {
      this.resetCurrentPage();
      await this.getGenerativeModels();
    },

    async currentPage(page) {
      this.allSelected = false;
      this.paginatedModels.forEach(item => {
        item.selected = false;
      });
      await this.getGenerativeModels((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getGenerativeModels();
      }, 300
    ),
  },

  created() {
    if (this.$route.path.endsWith('create')) {
      this.goToCreateModel();
    }
    this.setBreadcrumb();
    this.getGenerativeModels();
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Extract'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.models') },
          { title: this.$t('menu.generative') },
        ]
      );
    },

    goToCreateModel() {
      this.create = true;
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedModels.forEach(m => {
        m.selected = false;
      });
    },

    async getGenerativeModels(offset = 0, limit = this.itemsPerPage, loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await http.get(
          'model/generative/',
          {
            params: {
              limit,
              offset,
              name_filter: this.trimmedFilter || '',
              sort_desc: this.sortDesc,
            },
          }
        );
        this.paginatedModels = response.data;
        this.totalModels = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handleSelect() {
      this.allSelected = this.paginatedModels.every(f => f.selected);
      this.renderKey++;
    },

    toggleSelectAll() {
      this.paginatedModels.forEach(item => {
        item.selected = this.allSelected;
      });
      this.renderKey++;
    },

    async deleteModel() {
      await Promise.all(this.selected.map(async model => {
        try {
          return await http.delete(`model/generative/${model.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      this.modelFilter = '';
      await this.getGenerativeModels();
      this.currentPage = 1;
      this.selected.forEach(model => {
        model.selected = false;
      });
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleScores(model) {
      this.modelScores = model;
      this.showScores = true;
    },

    handleEdit(){
      this.editingModel = this.selected[0].id;
    },

    async saveModelName(id, newName){
      if (newName !== ''){
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`/model/generative/${id}/`,{ name: newName});
          await this.getGenerativeModels();
          this.$store.commit(
            'setSuccessMessage', this.$t('models.updated_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
        finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    }
  }
}
</script>
