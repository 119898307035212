import hljs from 'highlight.js';
import _ from 'lodash';
import { http } from '@/plugins/axios';

export default {
  data() {
    return {
      paginatedRules: [],
      ruleResults: {},
    };
  },

  computed: {
    dataPointBRStrings() {
      if (this.paginatedRules.length === 0) {
        return {};
      }
      const res = this.values.reduce((acc, dp) => {
        if (
          dp.value.prevalidated
          && this.paginatedRules.some(br => br.extractor_codes.includes(`dp_${dp.data_point_id}`))
        ) {
          const businessRulesStrings = this.getValueBusinessRules(dp.data_point_id, dp.data_point_name, 'dp');
          return {
            ...acc,
            [dp.data_point_id]: businessRulesStrings,
          };
        }
        return acc;
      }, {});
      return res;
    },

    groupValueBRStrings() {
      let res = {};
      this.groupValues.forEach((group) => {
        group.subgroups.forEach(subgroup => {
          subgroup.values.forEach(value => {
            const extractionGroupCode = `eg_${group.group_id}_${value.label}`;
            if (
              value.prevalidated
              && this.paginatedRules.some(br => br.extractor_codes.includes(extractionGroupCode))
            ) {
              const businessRulesStrings = this.getValueBusinessRules(group.group_id, value.label, 'eg', subgroup.id);
              res = {
                ...res,
                [`${extractionGroupCode}_${subgroup.id}`]: businessRulesStrings,
              };
            }
          });
        });
      });
      return res;
    },
  },

  methods: {
    async getRules(
      sortDesc = true,
      force = false,
      offset = 0,
      limit = this.itemsPerPage,
      getAll = false,
      docTypeId = null,
    ) {
      if (force) {
        this.loading = true;
      }
      try {
        const response = await http.get(
          'system_2/business_rule/',
          {
            params: {
              limit,
              offset,
              name_filter: this.trimmedFilter || '',
              document_type_id: docTypeId || this.$route.params.id,
              sort_desc: sortDesc,
            },
          }
        );
        this.paginatedRules = response.data;
        this.totalRules = parseInt(response.headers['x-total-count'], 10);
        if (this.$route.params.tab === 'rules') {
          setTimeout(() => {
            hljs.configure({
              languages: ['javascript'],
              ignoreUnescapedHTML: true,
            });
            this.paginatedRules.forEach(rule => {
              const ruleLogicArr = this.$refs[`rule_logic_${rule.id}`];
              if (ruleLogicArr && ruleLogicArr.length > 0) {
                hljs.highlightElement(ruleLogicArr[0]);
              }
            });
          }, 300);
        }
        if (getAll && this.paginatedRules.length < this.totalRules) {
          this.getRules(sortDesc, force, offset + limit, limit, getAll);
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },

    async getRuleResults(fileId) {
      try {
        const response = await http.get(
          `system_2/business_rule/${fileId}/result/`
        );
        this.ruleResults = response.data;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    getDataPointValue(id) {
      const dp = this.values.find(dp => dp.data_point_id === id);
      return dp && dp.value.normalized_value || null;
    },

    getExtractionGroupValue(groupId, label, subgroupId) {
      const group = this.groupValues.find(g => g.group_id === groupId);
      if (!group) {
        return null;
      }
      const subgroup = group.subgroups.find(sg => sg.id === subgroupId)
      if (!subgroup) {
        return null;
      }
      const value = subgroup.values.find(v => v.label === label);
      return value && value.normalized_value || null;
    },

    getExtractionGroupValueSum(groupId, label) {
      const group = this.groupValues.find(g => g.group_id === groupId);
      if (!group) {
        return 0;
      }
      const res = group.subgroups.reduce((acc, sg) => {
        return acc + this.getExtractionGroupValue(parseInt(groupId), label, sg.id) || 0;
      }, 0);
      return res;
    },

    getValueBusinessRules(id, name, extractorType, subgroupId = null) {
      if (_.isEmpty(this.ruleResults)) {
        return [];
      }
      return this.paginatedRules.map(br => {
        const extractorRegex = new RegExp(`${extractorType}_${id}`);
        const relevantCodes = br.extractor_codes.filter((c) => extractorRegex.test(c));
        if (relevantCodes.length > 0) {
          let res = br.string;

          br.extractor_codes.forEach(code => {
            let [extType, extractorId, ...label] = code.split('_');
            label = label.join('_');
            if (extType === 'dp') {
              res = res.replaceAll(`${code}_name`, name);
              const value = this.getDataPointValue(extractorId);
              res = res.replaceAll(code, value || 'N/A');
            } else {
              if (br.type === 'internal') {
                const subgroupValueSum = this.getExtractionGroupValueSum(extractorId, label);
                res = res.replaceAll(code, subgroupValueSum);
                res = res.replaceAll(`${label}`, `SUM(${label})`);
              } else if (br.type === 'subgroup'){
                if (!subgroupId) {
                  throw new Error('Subgroup ID is required for subgroup business rules');
                }
                const value = this.getExtractionGroupValue(parseInt(extractorId), label, subgroupId);
                res = res.replaceAll(code, value || 'N/A');
              }
            }
          });
          return {
            string: res,
            isValid: this.ruleResults[br.name],
          };
        }
        return null;
      });
    },

    parseJsonLogic(jsonLogic, level = 1, type = 'internal') {
      if (typeof jsonLogic !== 'object' || jsonLogic === null) {
        return jsonLogic.toString();
      }

      const operators = Object.keys(jsonLogic);
      let operator = operators[0];
      const conditions = jsonLogic[operator];

      if (!operator) {
        return '';
      }
      if (operator === 'var') {
        if (!conditions) {
          return '_';
        }
        if (conditions.startsWith('dp_')) {
          const dp = this.dataPoints.find(dp => dp.id === parseInt(conditions.split('_')[1]));
          if (dp) {
            return dp.name;
          }
          return '_';
        } else if (conditions.startsWith('eg_')) {
          const group = this.labelGroups.find(g => g.id === parseInt(conditions.split('_')[1]));
          if (group) {
            const label = conditions.split('_').slice(2).join('_');
            if (type === 'subgroup') {
              return `${group.name}.${label}`;
            }
            return `SUM(${group.name}.${label})`;
          }
          return '_';
        }
        return conditions;
      }
      if (operator === 'abs') {
        let arg = this.parseJsonLogic(conditions, level + 1, type);
        if (arg.startsWith('(') && arg.endsWith(')')) {
          arg = arg.substring(1, arg.length - 1);
        }
        return `ABS(${arg})`;
      }
      if (!Array.isArray(conditions)) {
        return '';
      }
      const infixConditions = conditions.map(condition => this.parseJsonLogic(condition, level + 1, type));
      if (operator === '==') {
        operator = '=';
      }
      // eslint-disable-next-line no-irregular-whitespace
      const infixExpression = infixConditions.join(` ${operator} `);

      if (level === 2 || conditions.length === 1) {
        return infixExpression;
      }
      return `(${infixExpression})`;
    },
  }
}
