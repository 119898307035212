/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class SearchAPI {
  static async search(searchString) {
    return http
      .post(`${BASE_PATH}search/?query=${searchString}`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
