/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/config/';

export class SearchConfigAPI {
  static async get(orgId = null) {
    return http
      .get(`${BASE_PATH}${orgId ? `?org_id=${orgId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async update(config) {
    return http
      .put(BASE_PATH, config)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getRoot(orgId = null) {
    return http
      .get(`${BASE_PATH}root/${orgId ? `?org_id=${orgId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
