import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';
import { http } from '@/plugins/axios';
import _ from 'lodash';

export default {

  data() {
    return {
      activeDP: {},
      activeValue: {},
      editingSubgroup: null,
      addingGroup: -1,
      addingLabel: "",
      editing: -1,
      editingGenerative: "",
      editingLabel: "",
      modelsNames: {},
      newSubgroupIndex: -1,
      newSubgroupPage: -1,
      newSubgroupValues: [],
      pageLocked: false,
      nullLocation: {
        "x_min": 0,
        "x_max": 0,
        "y_min": 0,
        "y_max": 0
      },
    };
  },

  computed: {
    currentPage: {
      get() {
        return this.$refs.filesDocViewer.currentPage;
      },
      set(value) {
        if (value !== this.$refs.filesDocViewer.currentPage && !this.pageLocked) {
          this.$refs.filesDocViewer.currentPage = value;
        }
      },
    },

    highlightedValue() {
      if (this.activeDP && this.activeDP.value) {
        return this.activeDP;
      }
      if (this.activeValue && this.activeValue.value) {
        return this.activeValue;
      }
      return {};
    },

    viewerValues: {
      get() {
        this.currentFile;
        this.forceCompute;
        let currentPage;
        if (
          this.currentFile && (
            !this.currentFile.status || ['uploaded', 'ingested', 'error'].includes(this.currentFile.status)
          )
        ) {
          return [];
        }
        const viewer = this.$refs.filesDocViewer;
        if (viewer) {
          currentPage = viewer.currentPage;
        }
        let dpValues = [];
        let groupValues = [];

        if (this.values.length > 0) {
          dpValues = this.values.filter(
            (dp) =>
              (
                (dp.value && dp.value.status !== 'invalid' && dp.value.value ) ||
                (dp.value && dp.value.status === 'invalid' && dp.value.valid_value)
              ) &&
              (
                dp.value.valid_page_nb === currentPage ||
                dp.value.page_nb === currentPage
              )
          );
        }
        if (this.extractedGroups.length > 0) {
          this.extractedGroups.forEach((group) => {
            group.subgroups
              .forEach((subgroup) => {
                groupValues = [...groupValues, ...subgroup.values];
              });
          });
        }
        groupValues = [...groupValues, ...this.newSubgroupValues];
        groupValues = groupValues.map((gv) => {
          const labelMap = this.getLabelMap(gv.group_id);
          const labelIndex = labelMap.labels.findIndex(label => label === gv.label);
          return {
            group: true,
            data_point_id: gv.id,
            data_point_name: labelMap.displayNames[labelIndex] || gv.label,
            value: gv,
            subgroup_index: gv.subgroup_index,
          };
        });
        return [...dpValues, ...groupValues];
      },
      set() {
        // pass
      },
    },
  },

  methods: {
    async getDataPoints(sortDesc = true) {
      try {
        let dataPoints = await DataPointAPI.get(
          this.$route.params.id,
          sortDesc,
          this.external || false,
          this.external || false,
          this.external ? this.$route.params.token : null,
        );
        for (const dp of dataPoints) {
          const eps = dp.extraction_paths;
          if (eps && eps.length === 1) {
            const entity = eps[0].what.custom_entity;
            const modelId = entity && entity[0][0];
            if (modelId) {
              dp.model_name = this.modelsNames[modelId];
              if (dp.model_name === undefined) {
                dp.model_name = '';
                const result = await this.getModelName(modelId);
                if (result) {
                  this.modelsNames[modelId] = result;
                  dp.model_name = result;
                }
              }
            }
          }
          dp.display_in_review = !dp.intermediate;
        }
        this.dataPoints = dataPoints;
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
    },

    async getLabelGroups(sortDesc = true) {
      try {
        const groups = await ExtractionGroupAPI.get(
          this.$route.params.id,
          sortDesc,
          this.external || false,
          this.external || false,
          this.external ? this.$route.params.token : null,
        );
        const length = groups.length;
        const group_names = {};
        for (let i = 0; i < length; i++) {
          const group = groups[i];
          if (group.custom_model_id) {
            if (group_names[group.custom_model_id]) {
              group.custom_model_name = group_names[group.custom_model_id];
            } else {
              group.custom_model_name = await this.getModelName(group.custom_model_id);
              group_names[group.custom_model_id] = group.custom_model_name;
            }
          }
          group.display_in_review = !group.intermediate;
        }
        this.labelGroups = [...groups];
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
    },

    async getGenerativeExtractors(sortDesc = true) {
      try {
        const response = await http.get(
          `system_2/generative_extractor/${this.$route.params.id}/`,
          { params: { sort_desc: sortDesc } },
        );
        const groups = response.data;
        const length = groups.length;
        const group_names = {};
        for (let i = 0; i < length; i++) {
          const group = groups[i];
          if (group.generative_model_id) {
            if (group_names[group.generative_model_id]) {
              group.generative_model_name = group_names[group.generative_model_id];
            } else {
              group.generative_model_name = await this.getGenerativeModelName(group.generative_model_id);
              group_names[group.generative_model_id] = group.generative_model_name;
            }
          }
        }
        this.generativeExtractors = [...groups];
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
    },


    getLabelMap(group_id) {
      const extractionGroup = this.labelGroups.find(
        (item) => item.id === group_id
      );
      if (extractionGroup) {
        return {
          labels: extractionGroup.labels,
          displayNames: extractionGroup.display_names,
        };
      }
      return {
        labels: [],
        displayNames: [],
      };
    },

    getDisplayLabels(group_id) {
      const labelMap = this.getLabelMap(group_id);
      for (let i = 0; i < labelMap.labels.length; i++) {
        if (labelMap.displayNames[i] !== "") {
          labelMap.labels[i] = labelMap.displayNames[i];
        }
      }
      return labelMap.labels;
    },

    handleHover(dp, fromDocViewer = false) {
      if (this.editing === -1) {
        const prevPage = this.currentPage;
        if (dp.value && (dp.value.valid_value || dp.value.value) && !this.pageLocked) {
          switch (dp.value.status) {
            case "valid":
              this.currentPage = dp.value.page_nb;
              break;
            case "pending":
              this.currentPage = dp.value.page_nb;
              break;
            case "invalid":
              this.currentPage = dp.value.valid_page_nb;
              break;
            default:
              this.currentPage = prevPage;
          }
          if (!this.currentPage) {
            this.currentPage = prevPage;
          }
        } else if (!this.pageLocked) {
          this.currentPage = prevPage;
        }
        if (this.viewerValues.length > 0) {
          this.activeDP =
            this.viewerValues.find((value) => dp.data_point_id === value.data_point_id) || dp;
          if (
            this.activeDP.value &&
            this.activeDP.value.value &&
            (this.activeDP.value.status === "pending" ||
              this.activeDP.value.status === "invalid")
          ) {
            this.highlightDP(fromDocViewer);
          }
        }
      }
    },

    handleDocViewerHighlight(value) {
      if (value.group) {
        this.groupHighlight(value.value, true);
      } else {
        this.handleHover(value, true);
      }
    },

    highlightDP(fromDocViewer = false) {
      if (!fromDocViewer && this.activeDP.value) {
        this.handleZoomScroll(this.activeDP.data_point_id);
      }
      let dpValidator = document.getElementById(
        `dpValidator-${this.activeDP.data_point_id}`
      );
      setTimeout(() => {
        if (dpValidator) {
          if (dpValidator.constructor === Array && dpValidator.length > 0) {
            dpValidator = dpValidator[0];
          }
          if (dpValidator.$el) {
            dpValidator.$el.scrollIntoViewIfNeeded();
          } else {
            dpValidator.scrollIntoViewIfNeeded();
          }
        }
      }, 50);
    },

    groupHighlight(value, fromDocViewer = false) {
      if (this.editing === -1) {
        const prevPage = this.currentPage;
        if ((value.valid_value || value.value) && !this.pageLocked) {
          switch (value.status) {
            case "valid":
              this.currentPage = value.page_nb;
              break;
            case "pending":
              this.currentPage = value.page_nb;
              break;
            case "invalid":
              this.currentPage = value.valid_page_nb;
              break;
            default:
              this.currentPage = prevPage;
          }
          if (!this.currentPage && !this.pageLocked) {
            this.currentPage = prevPage;
          }
        } else if (!this.pageLocked) {
          this.currentPage = prevPage;
        }
        this.activeValue = {
          group: true,
          data_point_id: value.id,
          data_point_name: value.label,
          value: value,
        };
        const index = this.groupValues.findIndex(
          (item) => item.group_id === value.group_id
        );
        const panelSet = new Set([
          ...this.groupValues[index].panel,
          value.subgroup_index,
        ]);
        this.groupValues[index].panel = [
          ...panelSet,
        ];

        if (this.activeValue.value.location && !fromDocViewer) {
          this.handleZoomScroll(this.activeValue.data_point_id);
        }
        const groupValidator = document.getElementById(
          `groupValidator-${this.selectedFile}-${value.group_id}-${value.subgroup_index}`
        );
        setTimeout(() => {
          if (groupValidator) {
            groupValidator.scrollIntoViewIfNeeded();
          }
        }, 50);
      }
    },

    handleZoomScroll(id) {
      const docViewer = this.$refs.filesDocViewer;
      if (docViewer) {
        const highlights = docViewer.$refs.valueHighlights;
        if (highlights) {
          setTimeout(() => {
            const highlightsArray = highlights.$refs[`value${id}`];
            if (highlightsArray && highlightsArray.length > 0) {
              const highlight = highlightsArray[0];
              if (highlight) {
                highlight.scrollIntoViewIfNeeded();
              }
            }
          }, 100);
        }
      }
    },

    deHighlight() {
      if (this.editing === -1) {
        this.activeDP = {};
        this.activeValue = {};
      }
    },

    async updateCoordinates(coordinates, production = false) {
      try {
        const pageNb = coordinates.page_nb;
        const response = await http.post(
          `${production ? 'production/' : ''}files/${this.currentFile.id}/text/?page_nb=${pageNb}`,
          coordinates.location,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        if (response.data && response.data.text && response.data.location) {
          const { text, location } = response.data;
          const value = {
            page_nb: pageNb,
            value: text,
            location,
          };
          if (this.activeValue && this.activeValue.group) {
            // Only add value if selected page number is same as subgroup
            if (
              this.activeValue.value &&
              (
                !this.activeValue.value.page_nb ||
                this.activeValue.value.page_nb === pageNb ||
                this.activeValue.value.valid_page_nb === pageNb
              )
            ) {
              value.id = this.activeValue.value.id;
              value.index = this.activeValue.value.index;
              value.subgroup_index = this.activeValue.value.subgroup_index;
              await this.verifyGroupValue(value);
            }
          } else if (this.editingLabel !== "") {
            const subgroupValue = this.newSubgroupValues.find(
              (item) => item.label === this.editingLabel
            );
            // If no values have been added to subgroup, define page number
            if (this.newSubgroupPage === -1) {
              this.newSubgroupPage = coordinates.page_nb || coordinates.valid_page_nb;
              if (this.editingSubgroup) {
                this.newSubgroupPage = this.editingSubgroup.page_nb;
              }
            }
            // Only add value if it has same page_nb as subgroup
            if (!this.newSubgroupPage || this.newSubgroupPage === coordinates.page_nb) {
              subgroupValue.subgroup_index = this.newSubgroupIndex;
              subgroupValue.valid_page_nb = coordinates.page_nb;
              subgroupValue.valid_value = text;
              subgroupValue.valid_location = location;
              this.updateSubgroupValue(text, this.newSubgroupIndex, coordinates.page_nb, location);
            }
          } else {
            await this.verifyValue(
              {
                ...this.activeDP,
                value
              },
              this.currentFile.id,
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.editing = -1;
        this.editingLabel = "";
        this.$refs.filesDocViewer.resetDrawing();
        this.pageLocked = false;
      }
    },

    async verifyGroup(values, inCorrections = false) {
      for (let i = 0; i < values.length; i++) {
        const value = values[i];

        if (value.status === 'valid' && value.prevalidated) {
          // pass prevalidated values
        } else if ((inCorrections && value.status === 'pending') || !inCorrections) {
          if (inCorrections && Object.prototype.hasOwnProperty.call(value, 'normalized_value')) {
            value.value = value.normalized_value;
          }
          await this.verifyGroupValue(value);
        }
      }
    },

    async verifyGroupValue(value) {
      try {
        let newValue = null;
        if (
          value.value &&
          value.value !== 'N/A' &&
          value.value !== ''
        ) {
          newValue = {
            page_nb: value.page_nb || 1,
            value: value.value,
            location: value.location || this.nullLocation,
          };
        }
        await http.post(
          `system_2/verify/${value.id}/groups/`,
          newValue,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        if (!newValue) {
          value.value = null;
        }
        await this.getRuleResults(this.currentFile.id);
        await this.updateGroupValues(value);
        this.$store.commit("setLoadingScreen", false);
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editing = -1;
      }
    },

    async cancelGroup(values) {
      for (let i = 0; i < values.length; i++) {
        await this.cancelGroupValue(values[i]);
      }
    },

    async cancelGroupValue(value) {
      try {
        await http.post(
          `system_2/verify/${value.id}/groups/reinitialize/`, {},
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        const subgroup = this.getSubgroup(value);
        if (subgroup) {
          const currentLabel = subgroup.values[value.index];
          currentLabel.valid_location = null;
          currentLabel.valid_page_nb = null;
          currentLabel.valid_value = null;
          currentLabel.status = 'pending';
          currentLabel.prevalidated = false;
          this.groupValues = [...this.groupValues];
          this.$emit('updateVerifiedFiles', this.selectedFile);
        }
        this.$emit('getLabelGroups');
        this.$store.commit("setLoadingScreen", false);
        await this.updateGroupValues();
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editing = -1;
      }
    },

    async verifyValue(value, fileId) {
      try {
        await http.post(
          `system_2/verify/${fileId}/${value.data_point_id}/`,
          value.value,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        if (this.type !== 'config') {
          await this.getRuleResults(fileId);
          await this.updateValues(value);
        }
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editing = -1;
      }
    },

    async cancelDPVerification(dataPointId, fileId) {
      try {
        await http.post(
          `system_2/verify/${fileId}/${dataPointId}/reinitialize/`, {},
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        const index = this.values.findIndex(v => v.data_point_id === dataPointId);
        const currentDP = this.values[index];
        currentDP.value.valid_value = null;
        currentDP.value.valid_location = null;
        currentDP.value.valid_page_nb = null;
        currentDP.value.status = 'pending';
        currentDP.value.prevalidated = false;
        this.values = [...this.values];
        await this.updateValues();
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editing = -1;
      }
    },

    async verifyGenerativeValue(value) {
      try {
        await http.put(
          `system_2/generative_extractor/${value.id}/verify/`,
          value.value,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        this.updateGenValues(value);
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editingGenerative = -1;
      }
    },

    async cancelGenerativeVerification(value) {
      try {
        await http.post(
          `system_2/generative_extractor/${value.id}/reinitialize/`, {},
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        const index = this.generativeValues.findIndex(v => v.id === value.id);
        const currentGV = this.generativeValues[index];
        currentGV.status = 'pending';
        currentGV.valid_value = null;
        this.$emit('updateVerifiedValues', this.selectedFile, value.data_point_id);
        this.generativeValues = [...this.generativeValues];
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.editingGenerative = -1;
      }
    },

    getDPValueKey(dp) {
      let key;
      if (dp.value && dp.value.valid_value) {
        key = `${dp.value.valid_value}_${dp.data_point_id}`;
      } else {
        key = `na_${dp.data_point_id}`;
      }
      return key;
    },

    getGEValueKey(dp) {
      let key;
      if (dp && dp.valid_value) {
        key = `ge_${dp.valid_value}_${dp.id}`;
      } else {
        key = `ge_na_${dp.id}`;
      }
      return key;
    },

    updateToNewValue(newValue, currentDP) {
      const oldValue = currentDP.value;
      currentDP.value.valid_value = newValue.value.value;
      currentDP.value.valid_location = {...newValue.value.location};
      currentDP.value.valid_page_nb = newValue.value.page_nb;
      if (
        newValue.value.value !== oldValue.value ||
        newValue.value.page_nb !== oldValue.page_nb ||
        !_.isEqual(newValue.value.location, oldValue.location)
      ) {
        currentDP.value.status = 'invalid';
      } else {
        currentDP.value.status = 'valid';
      }
      currentDP.value.prevalidated = false;
      this.values = [...this.values];
    },

    updateLabelToText(currentLabel, newValue) {
      currentLabel.valid_value = newValue.value;
      currentLabel.valid_location = {...newValue.location};
      currentLabel.valid_page_nb = newValue.page_nb;
      if (
        newValue.value !== currentLabel.value ||
        newValue.page_nb !== currentLabel.page_nb ||
        !_.isEqual(newValue.location, currentLabel.location)
      ) {
        currentLabel.status = 'invalid';
      } else {
        currentLabel.status = 'valid';
      }
      currentLabel.prevalidated = false;
      this.groupValues = [...this.groupValues];
    },

    updateToNA(currentDP, status) {
      currentDP.value.valid_value = null;
      currentDP.value.valid_location = null;
      currentDP.value.valid_page_nb = null;
      currentDP.value.status = status;
      currentDP.prevalidated = false;
      this.values = [...this.values];
    },

    updateLabelToNA(currentLabel) {
      currentLabel.valid_value = null;
      currentLabel.valid_location = null;
      currentLabel.valid_page_nb = null;
      if (currentLabel.value) {
        currentLabel.status = 'invalid';
      } else {
        currentLabel.status = 'valid';
      }
      currentLabel.prevalidated = false;
      this.groupValues = [...this.groupValues];
    },

    updateOldValueText(newValue, currentDP) {
      if (newValue.value) {
        this.updateToNewValue(newValue, currentDP);
      } else {
        this.updateToNA(currentDP, 'invalid');
      }
    },

    updateGenToNA(currentDP, status) {
      currentDP.valid_value = null;
      currentDP.status = status;
      this.generativeValues = [...this.generativeValues];
    },

    updateGenToNewValue(newValue, currentDP) {
      const oldValue = currentDP.value;
      currentDP.valid_value = newValue.value.value;
      if (
        newValue.value.value !== oldValue
      ) {
        currentDP.status = 'invalid';
      } else {
        currentDP.status = 'valid';
      }
      this.generativeValues = [...this.generativeValues];
    },

    updateGenOldValueText(newValue, currentDP) {
      if (newValue.value) {
        this.updateGenToNewValue(newValue, currentDP);
      } else {
        this.updateGenToNA(currentDP, 'invalid');
      }
    },

    updateGenNAtoText(newValue, currentDP) {
      currentDP.valid_value = newValue.value.value;
      currentDP.status = 'invalid';
      this.generativeValues = [...this.generativeValues];
    },

    updateNAtoText(newValue, currentDP) {
      if (
        newValue.value.location &&
        !_.isEqual(newValue.value.location, this.nullLocation)
      ) {
        currentDP.value.valid_location = {...newValue.value.location};
        currentDP.value.valid_page_nb = newValue.value.page_nb;
      }
      currentDP.value.valid_value = newValue.value.value;
      currentDP.value.status = 'invalid';
      currentDP.prevalidated = false;
      this.values = [...this.values];
    },

    async updateValues(newValue) {
      if (newValue) {
        const index = this.values.findIndex(v => v.data_point_id === newValue.data_point_id);
        const currentDP = this.values[index];
        const hasOldTextValue = !!currentDP.value.value;
        if (hasOldTextValue) {
          this.updateOldValueText(newValue, currentDP);
        } else {
          if (newValue.value) {
            this.updateNAtoText(newValue, currentDP);
          } else {
            this.updateToNA(currentDP, 'valid');
          }
        }
      }
      await this.getRuleResults(this.currentFile.id);
      await this.getAllValues();
    },

    updateSubgroupValue(inputValue, index, page = 1, location = this.nullLocation) {
      const newValue = this.newSubgroupValues[this.newSubgroupValues.length - 1];
      if (newValue) {
        newValue.valid_value = inputValue;
        newValue.valid_location = location;
        newValue.valid_page_nb = page;
        newValue.subgroup_index = index;
      }
      this.newSubgroupValues = [...this.newSubgroupValues];
    },

    async updateGenValues(newValue) {
      const index = this.generativeValues.findIndex(v => v.id === newValue.id);
      const currentDP = this.generativeValues[index];
      const hasOldTextValue = !!currentDP.value;
      if (hasOldTextValue) {
        this.updateGenOldValueText(newValue, currentDP);
      } else {
        if (newValue.value) {
          this.updateGenNAtoText(newValue, currentDP);
        } else {
          this.updateGenToNA(currentDP, 'valid');
        }
      }
      this.$emit('updateVerifiedValues', this.selectedFile, newValue.data_point_id);
    },

    getGroup(group_id) {
      return this.groupValues.find(g => g.group_id === group_id);
    },

    getSubgroup(newValue) {
      const index = this.groupValues.findIndex(g => g.group_id === newValue.group_id);
      if (index >= 0) {
        const group = this.groupValues[index];
        if (group) {
          return group.subgroups[newValue.subgroup_index];
        }
        return null;
      }
      return null;
    },

    updatePanelIndexes(group, index) {
      const allValuesValid = [];
      const panels = group.subgroups.reduce((res, item, k) => {
        if (item.values.some((value) => value.status === "invalid")) {
          res = [...res, k];
        }
        if (item.values.every(value => value.status === "valid")) {
          allValuesValid.push(k);
        }
        return res;
      }, []);
      const currentGroup = this.groupValues[index];
      let currentPanel = currentGroup && currentGroup.panel || [];
      const panelSet = new Set(panels.length > 0 ? [
        ...currentPanel.filter(p => !allValuesValid.includes(p)),
        ...panels
      ] : [0]);
      group.panel = [...panelSet];
    },

    async updateGroupValues(newValue) {
      if (newValue) {
        const subgroup = this.getSubgroup(newValue);
        if (subgroup && subgroup.values) {
          const group = this.getGroup(newValue.group_id);
          const panelSet = new Set([
            ...group.panel,
            newValue.subgroup_index,
          ]);
          group.panel = [...panelSet];
          const currentLabel = subgroup.values[newValue.index];
          if (currentLabel) {
            if (newValue.value) {
              this.updateLabelToText(currentLabel, newValue);
            } else {
              this.updateLabelToNA(currentLabel);
            }
          } else {
            subgroup.values = [...subgroup.values, newValue];
          }
          this.$emit('updateVerifiedFiles', this.selectedFile);
          this.$emit('getLabelGroups');
        }
      }
      await this.getAllValues();
    },

    async verifyFromViewer(value) {
      if (value.group) {
        const groupValue = value.value;
        await this.verifyGroupValue(groupValue);
      } else {
        await this.verifyValue(
          value,
          this.currentFile.id,
        );
      }
    },

    addMissingLabel(name, index) {
      this.addingLabel = `${name}-${this.currentFile.id}-${index}`;
      this.newSubgroupIndex = index - 1;
    },

    addSubgroup(group_id, index) {
      this.addingGroup = group_id;
      this.newSubgroupIndex = index;
    },

    addSubgroupLabel(group, label) {
      const existingValues = group.subgroups[this.newSubgroupIndex] &&
        group.subgroups[this.newSubgroupIndex].values &&
        group.subgroups[this.newSubgroupIndex].values.length || 0;
      const currentIndex = existingValues + this.newSubgroupValues.length;
      this.newSubgroupValues.push({
        id: Math.random(),
        confidence: 1,
        group_id: group.group_id,
        index: currentIndex,
        label,
        value: '',
        status: 'invalid',
        subgroup_index: this.newSubgroupIndex,
      });
    },

    cancelAddLabel() {
      this.addingLabel = "";
      this.newSubgroupValues = [];
      this.editingLabel= "";
    },

    cancelAddSubgroup() {
      this.addingGroup = -1;
      this.newSubgroupIndex = -1;
      this.newSubgroupPage = -1;
      this.newSubgroupValues = [];
      this.editingLabel = "";
    },

    formatGroupValues(groupValues) {
      return groupValues.map((item) => ({
        ...item,
        status: !item.valid_value ? "valid" : "invalid",
        location: item.valid_location,
        page_nb: item.valid_page_nb,
        value: item.valid_value,
      }));
    },

    async saveLabel(subgroup) {
      const newSubgroupValues = this.newSubgroupValues;
      try {
        const { data: valueIds } = await http.post(
          `system_2/verify/groups/add_missing/${subgroup.id}/`,
          this.formatGroupValues(newSubgroupValues),
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        const updatedValues = newSubgroupValues.map((item, i) => {
          return {
            ...item,
            status: item.value === "" && !item.valid_value ? "valid" : "invalid",
            id: valueIds[i],
          };
        });
        updatedValues.forEach(value => this.updateGroupValues(value));
        this.addingLabel = "";
        this.newSubgroupValues = [];
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
    },

    handleEditValue(dp) {
      this.editing = dp.data_point_id;
      this.activeDP = { ...dp };
    },

    handleEditGenerativeValue(ge) {
      this.editingGenerative = ge.name;
    },

    cancelEdit() {
      this.editing = -1;
      this.activeDP = {};
      this.activeValue = {};
    },

    editGroupValue(value) {
      this.editing = value.id;
      this.activeValue = {
        group: true,
        data_point_id: value.id,
        data_point_name: value.label,
        value: value,
      };
    },

    async saveSubgroup(group) {
      try {
        const { data: newSubgroup } = await http.post(
          `system_2/verify/groups/${group.group_id}/?file_id=${this.currentFile.id}`,
          {
            page_nb: this.newSubgroupPage > 0 ? this.newSubgroupPage : 1,
            values: this.formatGroupValues(this.newSubgroupValues),
          },
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );

        newSubgroup.values = newSubgroup.values.map((item, i) => ({
          ...this.newSubgroupValues[i],
          ...item,
        }))
        this.insertSubgroup(group, newSubgroup);
        this.addingGroup = -1;
        this.newSubgroupIndex = -1;
        this.newSubgroupPage = -1;
        this.newSubgroupValues = [];
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
    },

    insertSubgroup(group, subgroup) {
      const index = this.groupValues.findIndex(
        (item) => item.group_id === group.group_id
      );
      if (index > -1) {
        this.groupValues[index].subgroups = [
          ...this.groupValues[index].subgroups,
          subgroup,
        ];
      }
    },

    async deleteSubgroup(id, groupIndex) {
      try {
        await http.delete(`system_2/verify/groups/${id}`)
        this.groupValues[groupIndex].subgroups =
          this.groupValues[groupIndex].subgroups.filter(item => item.id !== id);
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true)
      }
    },

    deleteSubgroupLabel(id) {
      this.newSubgroupValues = this.newSubgroupValues.filter(
        (item) => item.id !== id
      );
    },
  },

  emits: ['updateVerifiedFiles', 'getLabelGroups', 'updateVerifiedValues'],
};
