<template>
  <div class="configure-dp">
    <div class="top-input">
      <HeaderName
        :item="{ name: newName, id: dataPoint.id }"
        @save="saveDPName"
      />
    </div>
    <ValueTypeConfig
      class="top-gap"
      :data-point="dataPoint"
      :value-type="dataPoint.value_type"
      :extraction-type="extractionType"
      @change="$emit('changeValueType')"
      @validity-change="(valid) => valueTypeValid = valid"
    />
    <div>
      <div class="label">
        {{ $t('dataPoints.extract_method') }}
      </div>
      <div style="margin-top: 5px">
        <v-radio-group
          v-model="extractionType"
          inline
        >
          <div class="inline-middle radio-box right-gap-sm">
            <v-radio
              value="rules"
              :label="$t('dataPoints.rules')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-radio
              value="model"
              :label="$t('dataPoints.model_option')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-radio
              value="group_based"
              :label="$t('dataPoints.group_option')"
              :disabled="dataPoint.value_type !== 'unspecified'"
              :style="{opacity: dataPoint.value_type !== 'unspecified' ? 0.5 : 1}"
            />
          </div>
        </v-radio-group>
      </div>
    </div>
    <div v-if="extractionType === 'rules'">
      <div
        v-for="(path, j) in displayPaths"
        :key="j"
      >
        <ExtractionPath
          v-if="EPTab > -1"
          :path="path"
          @refresh="displayPaths = [...displayPaths]"
          @activate-forms="$emit('activate', 'form')"
          @activate-layout="$emit('activate', 'layout')"
          @activate-tables="$emit('activate', 'tables')"
          @activate-default="$emit('activate', 'default')"
          @validity-change="checkValidity"
        />
      </div>
    </div>
    <DPModels
      v-else-if="extractionType === 'model'"
      :initial-model="selectedModel"
      :initial-model-version="selectedModelVersion"
      :initial-entity="selectedCustomEntity"
      @model-change="model => selectedModel = model"
      @version-change="version => selectedModelVersion = version"
      @entity-change="entity => selectedCustomEntity = entity"
    />
    <div v-else-if="extractionType === 'group_based' && dataPoint.extraction_group">
      <ExtractionGroupConfig
        v-if="dataPoint.extraction_group.custom_model_id !== 0 && dataPoint.extraction_group.custom_model_version"
        type="data_point"
        :group="dataPoint.extraction_group"
        @update-primary="index => $emit('updatePrimary', index)"
      />
      <DPModels
        v-else
        :initial-model="dataPoint.extraction_group.custom_model_id"
        :show-entity="false"
        @model-change="model => dataPoint.extraction_group.custom_model_id = model"
        @version-change="version => dataPoint.extraction_group.custom_model_version = version"
      />
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import DPModels from '@/components/extract/elements/DataPoints/DPModels';
import ExtractionPath from '@/components/extract/elements/DataPoints/ExtractionPath';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ValueTypeConfig from '@/components/extract/elements/DataPoints/ValueTypeConfig';
import ExtractionGroupConfig from '@/components/extract/elements/LabelGroups/ExtractionGroupConfig';

export default {
  name: 'ConfigureDP',

  components: {
    ExtractionPath,
    HeaderName,
    DPModels,
    ValueTypeConfig,
    ExtractionGroupConfig,
  },

  data() {
    return ({
      allValid: false,
      selectedCustomEntity: '',
      selectedModel: 0,
      selectedModelVersion: 0,
      extractionType: 'model',
      EPTab: -1,
      displayPaths: [],
      newName: '',
      addingBlock: false,
      blockToAdd: '',
      valueTypeValid: true,
    })
  },

  computed: {
    selectedPath: {
      get() {
        return this.displayPaths[this.EPTab]
      },
      set(value) {
        const displayPaths = [...this.displayPaths];
        displayPaths[this.EPTab] = value;
        this.displayPaths = [...displayPaths];
      }
    },
  },

  watch: {
    valueTypeValid() {
      this.checkValidity();
    },

    allValid() {
      this.$emit('validityChange', this.allValid);
    },

    selectedCustomEntity() {
      this.$emit('changeCustomEntity', this.selectedCustomEntity);
    },

    extractionType() {
      this.$emit('changeType', this.extractionType);
    },

    name() {
      this.newName = this.name;
    },

    paths: {
      handler() {
        if (this.paths.length > 0) {
          this.displayPaths = [...this.paths];
        }
      },
      deep: true
    },

    displayPaths: {
      handler() {
        this.displayPaths.forEach((path, i) => {
          path.name = `Path ${i + 1}`
          path.ordinal = i;
        });
        if (this.EPTab < 0) {
          this.EPTab = 0
        }
        if (this.EPTab >= this.displayPaths.length) {
          this.EPTab = this.displayPaths.length - 1;
        }
      },
      deep: true
    },
  },

  async mounted() {
    this.newName = this.name;
    if (this.dataPoint.category === 'classic') {
      this.extractionType = 'rules';
    } else if (this.dataPoint.category === 'group_based') {
      this.extractionType = 'group_based';
    } else {
      this.extractionType = 'model';
    }
    if (this.paths.length > 0) {
      const customEntities = this.paths[0].what.custom_entity;
      if (this.paths.length === 1 && customEntities && customEntities.length === 1) {
        this.selectedModel = customEntities[0][0];
        this.selectedCustomEntity = customEntities[0][1];
        this.selectedModelVersion = this.paths[0].what.custom_model_version || 1;
      }
      this.displayPaths = [...this.paths];
    } else {
      this.addNew();
    }
  },

  methods: {
    async saveDPName(name) {
      try {
        await http.put(
          `system_2/data_point/${this.id}`, { name }
        );
        this.newName = name;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('nameSave');
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
      }
    },

    checkValidity() {
      this.allValid = this.valueTypeValid &&
        this.displayPaths.every(path => !path.invalid);
    },

    handleAddButton() {
      this.addingBlock = true;
      setTimeout(() => {
        this.$refs.blockSelect[0].activateMenu();
      }, 10);
    },

    saveName() {
      if (this.newName.length >= 3) {
        this.saveDataPoint();
      }
    },

    saveDataPoint() {
      if (this.isValid) {
        if (this.extractionType === 'model') {
          const extraction_paths = [{
            what: {
              custom_entity: [[this.selectedModel, this.selectedCustomEntity]],
              custom_model_version: this.selectedModelVersion,
              regex: null,
              entity: null,
            },
            where: {
              page_num: [],
            },
          }];
          const pathsLength = this.displayPaths.length;
          if (pathsLength > 0) {
            const id = this.displayPaths[0].id;
            if (id) {
              extraction_paths[0].id = id;
              for (let i = 1; i < pathsLength; i++) {
                this.$emit('removePath', [this.displayPaths[i]]);
              }
            }
          }
          this.$emit(
            'save',
            {
              extraction_paths,
              category: 'custom',
            }
          );
          return;
        } else if (this.extractionType === 'rules') {
          let pathsToSave = [];
          pathsToSave = [...this.displayPaths];
          this.displayPaths.forEach((path, i) => {
            if ((path.where.nearby_text && (path.where.nearby_text.same_line.length === 0 && path.where.nearby_text.same_column.length === 0 && path.where.nearby_text.around.length === 0))
              ) {
              pathsToSave[i].where.nearby_text = null;
            }

            if (path.where.form && path.where.form.keys && path.where.form.keys.length === 0) {
              pathsToSave[i].where.form = null;
            }

            if (!path.where.layout || !path.where.layout.tag || path.where.layout.tag.length === 0) {
              pathsToSave[i].where.layout = null;
            }

            if (path.where.table && path.where.table.col_num && path.where.table.col_num.length === 0) {
              pathsToSave[i].where.table.col_num = null;
            }

            if (path.where.table && path.where.table.row_num && path.where.table.row_num.length === 0) {
              pathsToSave[i].where.table.row_num = null;
            }

            if (path.where.table && path.where.table.row_names && path.where.table.row_names.length === 0) {
              pathsToSave[i].where.table.row_names = null;
            }

            if (path.where.table && path.where.table.col_names && path.where.table.col_names.length === 0) {
              pathsToSave[i].where.table.col_names = null;
            }

            if (path.where.table && !path.where.table.row_names && !path.where.table.col_names && !path.where.table.row_num && !path.where.table.col_num) {
              pathsToSave[i].where.table = null;
            }
          });
          this.$emit(
            'save',
            {
              name: this.newName.length >= 3 ? this.newName : null,
              extraction_paths: pathsToSave,
              category: 'classic',
            }
          );
        } else if (this.extractionType === 'group_based') {
          this.$emit(
            'save',
            {
              extraction_group: this.dataPoint.extraction_group,
              category: 'group_based',
            }
          );
        }
      }
    },

    removePath(j) {
      const removed = this.displayPaths.splice(j, 1);
      this.$emit('removePath', removed);
    },

    addNew() {
      this.displayPaths.push({
        what: {
          regex: [],
          entity: null,
        },
        where: {
          page_num: [],
        }
      })
      this.EPTab = this.displayPaths.length - 1;
      this.displayPaths = [...this.displayPaths];
    },

    duplicatePath(j) {
      const clone = JSON.parse(JSON.stringify(this.displayPaths[j]));
      delete clone.id;
      this.displayPaths.splice(j + 1, 0, clone);
      setTimeout(() => {
        this.EPTab = j + 1;
      }, 10);
      this.displayPaths = [...this.displayPaths];
    },
  },

  props: {
    id: {
      type: Number,
      required: true,
    },

    isValid: {
      type: Boolean,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    paths: {
      type: Array,
      required: true,
    },

    docLoading: {
      type: Boolean,
      required: true,
    },

    dataPoint: {
      type: Object,
      required: true,
    }
  },

  emits: [
    'changeValueType',
    'activate',
    'updatePrimary',
    'validityChange',
    'changeCustomEntity',
    'changeType',
    'nameSave',
    'removePath',
    'save',
  ],
}
</script>

<style lang="scss" scoped>
.configure-dp {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .top-input {
    max-width: 83%;
  }
  
  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;
    max-width: calc(100% - 170px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
