<template>
  <v-card class="label-groups-table elevation-6 pa-0 top-gap">
    <v-container
      class="pa-0 table-row-height"
      fluid
    >
      <v-row class="table-row table-row__header table-row-height">
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="inline-middle"
            style="margin-top: -16px"
          />
        </v-col>
        <v-col cols="4">
          {{ $t('dataPoints.label_group') }}
        </v-col>
        <v-col cols="2">
          {{ $t('dataPoints.model') }}
        </v-col>
        <v-col cols="3">
          {{ $t('models.labels') }}
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          cols="2"
          style="text-align: center"
        >
          {{ $t('datatable.header.precision') }}
        </v-col>
      </v-row>
    </v-container>
    <div v-if="loading">
      <div v-if="pages[currentPage] && pages[currentPage].length === 0">
        <div
          v-for="item in 10"
          :key="item"
          class="table-row-height"
        >
          <v-skeleton-loader type="table-row" />
        </div>
      </div>
      <div
        v-for="item in pages[currentPage]"
        :key="item.id"
        class="table-row-height"
      >
        <v-skeleton-loader type="table-row" />
      </div>
    </div>
    <div
      v-else-if="!pages[currentPage] || pages[currentPage].length === 0"
      class="table-row fade-in table-row-height"
      style="text-align: center; padding-top: 15px;"
    >
      <i>{{ $t('docTypes.no_results') }}</i>
    </div>
    <v-container
      v-else
      class="pa-0"
      fluid
    >
      <div
        v-for="item in pages[currentPage]"
        :key="item.id"
      >
        <v-row
          class="table-row fade-in table-row-height"
          style="border-bottom: 1px solid #eee !important"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap"
              style="margin-top: -15px"
              @change="$emit('setItems', [...allItems])"
            />
          </v-col>
          <v-col cols="4">
            <ItemName
              :key="item.id"
              :ref="`name_${item.id}`"
              style="z-index: 202"
              :item="item"
              :editing-allowed="!!(item.selected)"
              :clickable="!disabled"
              @save-file-name="(id, newName) => {item.name = newName; saveGroupName(id, newName);}"
              @name-click="handleEditButton($route.params.id, item.id)"
            />
          </v-col>
          <v-col
            cols="2"
            class="ellipsis"
          >
            {{ item.custom_model_name }}
          </v-col>
          <v-col cols="3">
            <div
              v-if="item.labels.length > 0"
              style="margin-top: -5px; overflow: hidden; white-space: nowrap;"
            >
              <MaxWidthChip
                color="#502BFF"
                :text-array="[item.labels[0]]"
              />
              <div
                v-if="item.labels.length > 1"
                class="inline-middle"
              >
                <MaxWidthChip
                  color="#502BFF"
                  :text-array="[`+${item.labels.length - 1}`]"
                />
                <v-tooltip
                  v-if="expanded.length === 0 || expanded[0].id !== item.id"
                  color="#423F4F"
                  right
                >
                  <template #activator="{ props }">
                    <v-icon
                      class="clickable"
                      style="margin-top: -9px"
                      size="16"
                      v-bind="props"
                      @click="expanded = [item]"
                    >
                      fas fa-chevron-right
                    </v-icon>
                  </template>
                  <span style="color: white">
                    {{ $t('show_all') }}
                  </span>
                </v-tooltip>
                <v-icon
                  v-else
                  class="clickable"
                  style="margin-top: -9px"
                  size="16"
                  @click="expanded = []"
                >
                  fas fa-chevron-down
                </v-icon>
              </div>
            </div>
            <div 
              v-else
              style="margin-top: -5px"
            >
              <MaxWidthChip
                color="#999999"
                :text-array="[$tc('datatable.header.none', 2)]"
              />
            </div>
          </v-col>
          <v-col
            v-if="user.role === 'orgadmin'"
            cols="2"
          >
            <MaxWidthChip
              v-if="item.nb_valid_values + item.nb_invalid_values > 0"
              style="margin-top: -5px"
              color="#502BFF"
              :text-array="[displayVerificationResult(item.nb_valid_values, item.nb_invalid_values)]"
            />
            <div
              v-if="Object.keys(item.precision_by_label).length > 1"
              class="inline-middle"
            >
              <v-tooltip
                v-if="expanded.length === 0 || expanded[0].id !== item.id"
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-icon
                    class="clickable"
                    style="margin-top: -14px"
                    size="16"
                    v-bind="props"
                    @click="expanded = [item]"
                  >
                    fas fa-chevron-right
                  </v-icon>
                </template>
                <span style="color: white">
                  {{ $t('show_all') }}
                </span>
              </v-tooltip>
              <v-icon
                v-else
                class="clickable"
                style="margin-top: -14px"
                size="16"
                @click="expanded = []"
              >
                fas fa-chevron-down
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="expanded.length > 0 && expanded[0].id === item.id"
          class="table-row"
          style="background-color: rgb(var(--v-theme-grey-darken1))"
        >
          <v-col cols="auto" />
          <v-col cols="6" />
          <v-col
            cols="3"
            style="margin-left: 60px"
          >
            <div
              v-for="labelName in item.labels"
              :key="labelName"
            >
              <MaxWidthChip
                color="#502BFF"
                :text-array="[labelName]"
                show-full
              />
            </div>
          </v-col>
          <v-col cols="2">
            <div
              v-for="labelName in item.labels"
              :key="labelName"
              style="height: 38px"
            >
              <span v-if="labelName in item.precision_by_label">
                {{ displayVerificationResult(item.precision_by_label[labelName].valid, item.precision_by_label[labelName].invalid) }}
              </span>
              <span v-else>
                -
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <TableFooter
      v-if="filteredItems.length > 0"
      v-model="itemsPerPage"
      style="margin-top: 10px !important; margin-right: 20px !important; margin-bottom: 5px !important;"
      :current-page="currentPage"
      :total-pages="Object.keys(pages).length"
      @change-page="page => currentPage = page"
      @reset-current-page="resetCurrentPage"
    />
  </v-card>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import SortButton from '@/components/common/elements/Tables/SortButton';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import { http } from '@/plugins/axios';


export default {
  name: 'LabelGroupsTable',

  components: {
    ItemName,
    TableFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    return ({
      expanded: [],
    });
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    sortDesc: {
      get() {
        return this.$store.getters.lgSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setLgSortDesc', sortDesc);
      },
    },

    itemsPerPage: {
      get() {
        return this.$store.getters.itemsPerPage['labelGroups'];
      },
      set(itemsPerPage) {
        this.$store.commit('setItemsPerPage', { key: 'labelGroups', itemsPerPage });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters.currentPage['labelGroups'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', { key: 'labelGroups', currentPage });
      },
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.allItems.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.$emit('setItems', [...selected]);
      }
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.$emit('getItems', desc);
    }
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    handleEditButton(id, itemId) {
      if (!this.disabled) {
        this.$router.push({
          name: 'LabelgroupConfig',
          params: { id, itemId },
        });
      }
    },

    async saveGroupName(id, name) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.put(`system_2/extraction_groups/${id}`, { name });
        this.$store.commit('setLoadingScreen', false);
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.allSelected = false;
        this.$emit('saveName');
      } catch (error) {
        this.docLoading = false;
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
        return
      }
    },

    displayVerificationResult(validValues, invalidValues) {
      return `${(validValues / (validValues + invalidValues) * 100).toFixed(0)}% \
        (${validValues}/${validValues + invalidValues})`
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['saveName', 'setItems', 'getItems'],
}
</script>
