<template>
  <div>
    <div class="d-flex">
      <v-switch
        v-model="esConfig.detect_titles"
        style="margin-top: 5px"
        color="primary"
        inset
      />
      <div class="left-gap-sm">
        <h4>{{ $t('settings.detect_titles.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.detect_titles.message`) }}
        </div>
      </div>
    </div>
    <div class="d-flex">
      <v-switch
        v-model="esConfig.boost_content_chunk"
        style="margin-top: 5px"
        color="primary"
        inset
      />
      <div class="left-gap-sm">
        <h4>{{ $t('settings.boost_content_chunk.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.boost_content_chunk.message`) }}
        </div>
      </div>
    </div>
    <div class="d-flex">
      <v-switch
        v-model="esConfig.boost_content_doc"
        style="margin-top: 5px"
        color="primary"
        inset
      />
      <div class="left-gap-sm">
        <h4>{{ $t('settings.boost_content_doc.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.boost_content_doc.message`) }}
        </div>
      </div>
    </div>
    <div class="d-flex disabled">
      <v-switch
        v-model="esConfig.use_google_ocr"
        style="margin-top: 5px"
        color="primary"
        disabled
        inset
      />
      <div class="left-gap-sm">
        <h4>{{ $t('settings.search_use_google_ocr.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.search_use_google_ocr.message`) }}
        </div>
      </div>
    </div>

    <NumberStepper 
      v-model="esConfig.length_chunk"
      :label="$t('settings.length_chunk.title')"
      :max="500"
      :min="50"
    />

    <NumberStepper 
      v-model="esConfig.top_chunk"
      :label="$t('settings.top_chunk.title')"
      :max="10"
      :min="1"
    />

    <NumberStepper 
      v-model="esConfig.top_doc"
      :label="$t('settings.top_doc.title')"
      :max="10"
      :min="1"
    />
  </div>
</template>


<script>
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';
import NumberStepper from '@/components/common/elements/Forms/NumberStepper';
import _ from "lodash";


export default {
  name: 'EsSettings',

  components: {
    NumberStepper
  },

  data() {
    return ({
      esConfig: {
        length_chunk: 0,
        detect_titles: false,
        top_chunk: 0,
        use_synonyms: false,
        boost_content_chunk: false,
        top_doc: 0,
        boost_content_doc: false,
        use_google_ocr: false,
      }
    })
  },

  watch: {
    esConfig: {
      handler: _.debounce(function() {
        this.updateConfig();
      }, 5000),
      deep: true
    }
  },

  mounted() {
    this.getEsConfig()
  },

  methods: {
    async getEsConfig() {
      try {
        this.esConfig = await SearchConfigAPI.get();
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async updateConfig() {
      try {
        await SearchConfigAPI.update(this.esConfig);
        this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.v-input.callback.error--text .v-text-field__details{
  margin: 0;
}

.error-message {
  color: rgb(var(--v-theme-error));
  font-size: small;
}

.transparent {
  visibility: hidden;
}

.http-select {
  width: 104px;
  color: rgb(var(--v-theme-grey-darken2)) !important;
}

.info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .api-token-field {
    width: 500px;
  }
</style>
